import React, { useContext, useEffect, useState } from 'react';
import { Grid, styled } from '@mui/material';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { H1TextStepTitle } from '../../../shared/Typography';
import { isValidDate, isValidEmail, isValidName, isValidPhoneNumber } from '../validationUtils';
import InputMask from 'react-input-mask';
import { LoginDataContext } from '../LoginDataProvider';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../utils/events';
import { authService } from '../../../../utils/auth';
import { phoneUtil } from '../../../../utils/phone';
import { userInfoUtil } from '../../../../utils/user';
import DecodedTextField from '../../../../utils/DecodedTextFIeld';
import { useMediaQueries } from '../../../../utils/hooks/useMediaQueries';
import { addUserDataHeap } from '../../../../utils/heap/heapUtils.js';
import ScrollableContainer, {
  LoginDisconnectedPageFooter,
} from '../../../common/ScrollableContainer';
import PageContainer from '../../../common/PageContainer';
import { NextButton } from './NextButton';



const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '15px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '10px 24px',
  },
}));

const StyledInputLabel = styled(InputLabel)({
  transform: 'translate(0, 1.5px) scale(0.75)',
  transformOrigin: 'top left',
  fontSize: '1rem', // Default font size

  '@media (max-width:600px)': {
    fontSize: '1.1rem',
    transform: 'translate(0, 1.5px) scale(0.9)',
  },
});

const ConfirmInput = (props) => {
  const { formatMessage } = useIntl();
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [error4, setError4] = useState(false);
  const [error5, setError5] = useState(false);
  const [hasConsecutiveSpacesError, setHasConsecutiveSpacesError] = useState(false);

  const { loginData, setLoginData } = useContext(LoginDataContext);

  const mediaQuery = useMediaQueries();

  const hasConsecutiveSpaces = (str) => /\s{2,}/.test(str);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginData((prevState) => ({ ...prevState, [name]: value }));

    if (name === 'firstName') {
      setError1(!isValidName(value));
    }
    if (name === 'lastName') {
      setError2(!isValidName(value));
    }
    if (name === 'dateOfBirth') {
      setError3(!isValidDate(value));
    }
    if (name === 'number') {
      setError4(!isValidPhoneNumber(value));
    }
    if (name === 'email') {
      setError5(!isValidEmail(value));
    }

    if (name === 'firstName' || name === 'lastName' || name === 'email') {
      if (hasConsecutiveSpaces(value)) {
        setHasConsecutiveSpacesError(true);
      } else {
        // Only reset error if all fields are free of spaces
        const allFieldsWithoutConsecutiveSpaces =
          !hasConsecutiveSpaces(loginData.firstName) &&
          !hasConsecutiveSpaces(loginData.lastName) &&
          !hasConsecutiveSpaces(loginData.email);
        if (allFieldsWithoutConsecutiveSpaces) {
          setHasConsecutiveSpacesError(false);
        }
      }
    }
  };

  useEffect(() => {
    if (loginData.firstName) {
      setError1(!isValidName(loginData.firstName));
    }
    if (loginData.lastName) {
      setError2(!isValidName(loginData.lastName));
    }
    if (loginData.dateOfBirth) {
      setError3(!isValidDate(loginData.dateOfBirth));
    }
    if (loginData.number) {
      setError4(!isValidPhoneNumber(loginData.number));
    }
    if (loginData.email) {
      setError5(!isValidEmail(loginData.email));
    }
  }, []);

  const handleClick = () => {
    setTimeout(() => {
      if (props.index !== 6) {
        props.setIndex((prevIndex) => (prevIndex + 1) % 7);
      }
    }, 250);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    handleClick();
    addUserDataHeap({
      dateOfBirth: loginData.dateOfBirth,
      firstName: loginData.firstName,
    });

    doSubmit();
  };

  const doSubmit = () => {

    analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_REQUEST);

    authService.loginWithEmail(createAuthRequest()).finally(() => {
      const loginDetails = {
        firstName: loginData.firstName,
        lastName: loginData.lastName,
        dateOfBirth: loginData.dateOfBirth,
        email: loginData.email,
        number: loginData.number,
        gender: loginData.gender === 'Female' ? 'F' : 'M',
      };
      sessionStorage.setItem('loginDetails', JSON.stringify(loginDetails));
    });
  };

  let code = props.code;
  let codeCountry = props.codeCountry;
  const createAuthRequest = () => {
    return {
      name: {
        given: loginData.firstName.trim(),
        family: loginData.lastName.trim(),
      },
      dob: userInfoUtil.formatDate(loginData.dateOfBirth),
      number: loginData.number
        ? phoneUtil.formatPhoneNumberForRegistration(loginData.number, code, codeCountry)
        : null,
      email: loginData.email,
      gender: loginData.gender === 'Female' ? 'F' : 'M',
    };
  };

  return (
    <PageContainer>
      <form onSubmit={onSubmit} style={{ display: 'contents' }}>
        <ScrollableContainer>
          <Grid md={12} xl={8} container sx={{ padding: mediaQuery.isSm ? '20px' : '80px' }}>
            <Grid item sx={{ paddingBottom: mediaQuery.isSm ? '20px' : '40px' }}>
              <FormattedMessage
                id="login.patient.confirm"
                defaultMessage="Check and confirm all patient information"
              >
                {(txt) => (
                  <H1TextStepTitle isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}>
                    {txt}
                  </H1TextStepTitle>
                )}
              </FormattedMessage>
            </Grid>
            <Grid
              container
              gap={2}
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                flexDirection: 'column',
                width: '75%',
                '@media (max-width:600px)': {
                  width: '100%',
                },
              }}
            >
              <Grid item xs={12} sm={8} md={6}>
                <DecodedTextField
                  name="firstName"
                  variant="outlined"
                  label="Patients First Name"
                  value={loginData.firstName}
                  onChange={handleInputChange}
                  error={error1}
                  helperText={
                    error1 &&
                    formatMessage({
                      id: 'registration.field.error.patient.name.family',
                      defaultMessage: 'Invalid name',
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={8} md={6}>
                <DecodedTextField
                  name="lastName"
                  variant="outlined"
                  label="Patients Last Name"
                  value={loginData.lastName}
                  onChange={handleInputChange}
                  error={error2}
                  helperText={
                    error2 &&
                    formatMessage({
                      id: 'registration.field.error.patient.name.family',
                      defaultMessage: 'Invalid name',
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={8} md={6}>
                <StyledInputLabel id="sexAtBirth-label" style={{ marginBottom: '8px' }}>
                  Sex at Birth*
                </StyledInputLabel>
                <StyledFormControl fullWidth>
                  <Select
                    name="gender"
                    variant="outlined"
                    labelId="sexAtBirth-label"
                    value={loginData.gender}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                  </Select>
                </StyledFormControl>
              </Grid>

              <Grid item xs={12} sm={8} md={6}>
                <InputMask
                  mask="99/99/9999"
                  value={loginData.dateOfBirth}
                  onChange={handleInputChange}
                >
                  {(props) => (
                    <DecodedTextField
                      type="tel"
                      name="dateOfBirth"
                      variant="outlined"
                      {...props}
                      label="Date of Birth"
                      error={error3}
                      helperText={
                        error3 &&
                        formatMessage({
                          id: 'registration.field.error.patient.name.family',
                          defaultMessage: 'Invalid date',
                        })
                      }
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={12} sm={8} md={6}>
                <InputMask
                  mask="(999) 999-9999"
                  value={loginData.number}
                  onChange={handleInputChange}
                >
                  {(props) => (
                    <DecodedTextField
                      type="tel"
                      name="number"
                      variant="outlined"
                      {...props}
                      label="Mobile Number"
                      error={error4}
                      helperText={
                        error4 &&
                        formatMessage({
                          id: 'registration.field.error.patient.name.family',
                          defaultMessage: 'Invalid phone number',
                        })
                      }
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12} sm={8} md={6}>
                <DecodedTextField
                  name="email"
                  value={loginData.email}
                  onChange={handleInputChange}
                  variant="outlined"
                  label="Email Address"
                  error={error5}
                  helperText={
                    error5 &&
                    formatMessage({
                      id: 'registration.field.error.patient.name.family',
                      defaultMessage: 'Invalid email address',
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </ScrollableContainer>
        <LoginDisconnectedPageFooter>
            <NextButton
              label='NEXT'
              disabled={
                loginData.number === '' ||
                loginData.dateOfBirth === '' ||
                loginData.gender === '' ||
                loginData.lastName === '' ||
                loginData.firstName === '' ||
                error1 ||
                error2 ||
                error3 ||
                error4 ||
                error5 ||
                hasConsecutiveSpacesError
              }
            />
        </LoginDisconnectedPageFooter>
      </form>
    </PageContainer>
  );
};

export default ConfirmInput;