import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { H1TextStepTitle, TextStepSecondary } from '../../../shared/Typography';
import DecodedTextField from '../../../../utils/DecodedTextFIeld';
import { isValidEmail } from '../validationUtils';
import { LoginDataContext } from '../LoginDataProvider';
import { useMediaQueries } from '../../../../utils/hooks/useMediaQueries';
import { NextButton } from './NextButton';
import ScrollableContainer, {
  LoginDisconnectedPageFooter,
} from '../../../common/ScrollableContainer';
import PageContainer from '../../../common/PageContainer';
import { StyledGrid } from './StyledGrid'


const Email = (props) => {
  const { formatMessage } = useIntl();
  const [error, setError] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const hasConsecutiveSpaces = (str) => /\s{2,}/.test(str);

  const { loginData, setLoginData } = useContext(LoginDataContext);

  const mediaQuery = useMediaQueries();

  useEffect(() => {
    if (loginData.email) {
      setError(!isValidEmail(loginData.email));
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginData((prevState) => ({ ...prevState, [name]: value }));

    if (name === 'email') {
      setError(!isValidEmail(value));
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsFormDisabled(true);

    setTimeout(() => {
      if (props.index !== 6) {
        setIsFormDisabled(false);

        props.setIndex((prevIndex) => (prevIndex + 1) % 7);
      }
    }, 250);
  };

  return (
    <PageContainer>
      <form onSubmit={handleClick} style={{ display: 'contents' }}>
        <ScrollableContainer>
          <StyledGrid md={12} xl={8} container sx={{ padding: mediaQuery.isSm ? '20px' : '80px' }}>
            <Grid item >
              <FormattedMessage id="login.patient.email" defaultMessage="Patient email address">
                {(txt) => (
                  <H1TextStepTitle isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}>
                    {txt}
                  </H1TextStepTitle>
                )}
              </FormattedMessage>
            </Grid>
            <Grid item sx={{ marginY: '30px' }}>
              <FormattedMessage
                id="login.patient.email.disclaimer"
                defaultMessage="THIS EMAIL ADDRESS IS USED TO VERIFY THE PATIENT RECORD AND SEND PATIENT COMMUNICATION. IF YOU DO NOT HAVE AN EMAIL ADDRESS, PLEASE CONTACT US."
              >
                {(txt) => (
                  <TextStepSecondary isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}>
                    {txt}
                  </TextStepSecondary>
                )}
              </FormattedMessage>
            </Grid>
            <Grid
              container
              gap={2}
              sx={{
                width: '75%',
                '@media (max-width:600px)': {
                  width: '100%',
                },
                display: mediaQuery.isMobileLandscape && 'flex',
                flexDirection: mediaQuery.isMobileLandscape && 'row',
                justifyContent: mediaQuery.isMobileLandscape && 'flex-start',
                alignItems: mediaQuery.isMobileLandscape && 'flex-end',
                flexWrap: mediaQuery.isMobileLandscape && 'nowrap',
              }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <DecodedTextField
                  name="email"
                  value={loginData.email}
                  onChange={handleInputChange}
                  variant="outlined"
                  label="Email Address"
                  error={error}
                  helperText={
                    error &&
                    formatMessage({
                      id: 'registration.field.error.patient.name.family',
                      defaultMessage: 'Invalid email address',
                    })
                  }
                />
              </Grid>
             
             </Grid>
          </StyledGrid>
        </ScrollableContainer>
        <LoginDisconnectedPageFooter>
            <NextButton
              label='NEXT'
              disabled={
                loginData.email === '' ||
                error ||
                isFormDisabled ||
                hasConsecutiveSpaces(loginData.email)
              }
            />
        </LoginDisconnectedPageFooter>
      </form>
    </PageContainer>
  );
};

export default Email;