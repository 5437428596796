import { phoneUtil } from './phone';

class ProviderUtil {
  formatOrganisationAddress(organisation) {
    if (organisation === undefined || organisation === '') return 'unknown';

    const address = organisation.contactInformation.address;

    let display = [];
    if (address.line1) display.push(address.line1);
    if (address.line2) display.push(address.line2);
    if (address.line3) display.push(address.line3);
    if (address.city) display.push(address.city);
    if (address.administrativeArea) display.push(address.administrativeArea);

    return display.length > 0 ? `${display.join(', ')}` : 'unknown';
  }

  formatOrganisationContactNumber(organisation) {
    if (
      organisation === undefined ||
      organisation === '' ||
      organisation.contactInformation === undefined ||
      organisation.contactInformation.contactNumbers === undefined
    )
      return 'unknown';

    const number = organisation.contactInformation.contactNumbers.find(
      (contactNumber) => contactNumber.location === 'RECEPTION',
    );

    if (number) {
      return `${number.area}-${number.prefix}-${number.line}`;
    }

    return 'unknown';
  }

  lookupService(serviceProviders, serviceCode) {
    let service = undefined;

    for (let j = 0; j < serviceProviders.length; j = j + 1) {
      const serviceProvider = serviceProviders[j];

      for (let i = 0; i < serviceProvider.serviceCatalogs.length; i = i + 1) {
        const serviceCatalog = serviceProvider.serviceCatalogs[i];
        service = serviceCatalog.services.find((_service) => _service.code === serviceCode);
      }
    }

    return service;
  }
}

export const providerUtil = new ProviderUtil();
