import {Button, Grid, Typography} from '@mui/material';
import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import PageContainer from '../../../common/PageContainer';
import ChatBubble from '../../../shared/ChatBubble';
import withStyles from "@mui/styles/withStyles";
import InsuranceContext from "../context";
import {Component} from "react";
import {
    DefaultDisconnectedPageFooter,
    ScrollableBox,
    ScrollableQuinnContainer,
    ScrollableWrapper
} from "../../../common/ScrollableContainer";
import {withRouter} from "react-router-dom";
import DecodedButton, {DecodedSecondaryButton} from "../../../common/DecodedButton";
import DecodedComponent from "../../../shared/DecodedComponent";


const styles = (theme) => ({
    root: {},
});


class InsuranceChoice extends DecodedComponent {

    constructor(props) {
        super(props);
        this.bloc = props.bloc;
        this.state = {};
    }
    render() {

        let { loading } = this.state;

        return (
            <PageContainer onBack={() => {
                this.props.history.goBack();
            }}>
                <ScrollableQuinnContainer messageId="paymentmethod.choice.bubble"
                                          message="How would you like to pay for this visit?">
                </ScrollableQuinnContainer>
                <DefaultDisconnectedPageFooter justifyContent={"left"}>
                    <Grid item xs={12}>
                        <DecodedButton loading={loading} variant="contained" color="primary" onClick={this.props.addInsurance}>
                            I have insurance
                        </DecodedButton>
                    </Grid>
                    <Grid item xs={12}>
                        <DecodedButton loading={loading} variant="contained" color="primary" onClick={this.props.goToFrontDesk}>
                            Unsure
                        </DecodedButton>
                    </Grid>
                    <Grid item xs={12}>
                        <DecodedButton loading={loading} onClick={this.props.selfPay}>
                            Self pay
                        </DecodedButton>
                    </Grid>
                </DefaultDisconnectedPageFooter>
            </PageContainer>
        );
    }
}

export default withStyles(styles)(withRouter((props) => (
    <InsuranceContext.Consumer>
        {(value) => {
            return <InsuranceChoice context={value} {...props} />;
        }}
    </InsuranceContext.Consumer>
)));
