import React from 'react';
import withStyles from '@mui/styles/withStyles';
import LinearProgress from '@mui/material/LinearProgress';
import {Box, Grid, styled} from '@mui/material';
import BackButton from './BackButton';
import { getLogoBasedOnTenant, getMobileLogoBasedOnTenant } from '../../utils/logo';

import { PortalContainer, useIsMobile } from '../../shared-library';
import { useMediaQueries } from '../../utils/hooks/useMediaQueries'

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    position: 'fixed',
    top: '0px',
    width: '100%',
    zIndex: '1500',
  },
}))(LinearProgress);

const WrapperMobileToolGridItem = styled(Grid)(({ theme }) => ({
    paddingLeft: '1em',
    paddingRight: '1em',
    paddingTop: '1em',
    paddingBottom: '1em',
    backgroundColor: 'rgba(255,255,255,0.7)',
}));

const MobileToolbar = ({ onBack, backButtonIcon }) => {
  const mediaQuery = useMediaQueries();

  return (
    <WrapperMobileToolGridItem item container >
        <Grid xs={12}  sx={{display: "flex"}} item>
      {onBack && (
        <BackButton
          onClick={onBack}
          icon={backButtonIcon}
          sx={{ maxHeight: "42px" }}
        />
      )}
        <Box sx={{ flex: "1", display: "flex" }}></Box>
        <img src={getMobileLogoBasedOnTenant()} alt="Logo" style={{width: mediaQuery.isSm ? '60px' : null}} />
        </Grid>
    </WrapperMobileToolGridItem>
  );
};

const WrapperDesktopToolGridItem = styled(Grid)(({ theme }) => ({
    paddingLeft: '13em',
    paddingRight: '13em',
    paddingTop: '1em',
    paddingBottom: '1em',
    backgroundColor: 'rgba(255,255,255,0.7)',
    [theme.breakpoints.down('md')]: {
        paddingLeft: '3em',
        paddingRight: '3em',
    },
}));

const DesktopToolbar = ({ onBack, backButtonIcon }) => {
    return (
        <WrapperDesktopToolGridItem item container >
            <Grid xs={12} sx={{display: "flex"}} item>
                {onBack && (
                    <BackButton
                        onClick={onBack}
                        icon={backButtonIcon}
                        sx={{ maxHeight: "42px" }}
                    />
                )}
                <Box sx={{flex: "1", display: "flex"}}></Box>
                <img src={getLogoBasedOnTenant()} alt="Logo"/>
            </Grid>
        </WrapperDesktopToolGridItem>);
};

const WrapperGrid = styled(Grid)(({ theme }) => ({
  flex: '1',
  marginRight: 'auto',
  padding: '0em',
  marginLeft: '0em',
  maxWidth: '100vw',
  height: 'inherit',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0em',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0em',
    paddingBottom: '8px',
  },
  [theme.breakpoints.down('xs')]: {
    marginLeft: '0em',
    paddingBottom: '8px',
  },
}));

const WrapperGridItem = styled(Grid)(({ theme }) => ({
  padding: '0 0 0 0',
  width: 'auto',
  [theme.breakpoints.down('md')]: {
     padding: '0 0 0 0',
  },
}));

const PageContainerBackButton = ({onBack, backButtonIcon}) => {

    const isMobile = useIsMobile();

    return (<>
    {isMobile ? (
        <MobileToolbar onBack={onBack} backButtonIcon={backButtonIcon}/>
    ) : (
        <DesktopToolbar onBack={onBack} backButtonIcon={backButtonIcon}/>
    )}
        </>);
}

const PageContainer = ({ children, noPadding, onBack, backButtonIcon, loading, hideBack }) => {

  if(hideBack) {

      return (
          <>
              <PortalContainer/>
              <WrapperGrid container direction="column" justifyContent="flex-start">
                  {loading && <BorderLinearProgress/>}
                  <PageContainerBackButton onBack={onBack} backButtonIcon={backButtonIcon} />
                  <WrapperGridItem item container direction="column" flex="1" sx={(theme) => {
                      if(noPadding) {
                          return { padding: "0 !important" };
                      }
                      return {};
                  }}>
                      {children}
                  </WrapperGridItem>
              </WrapperGrid>
          </>
      );
  }

  return (
    <>
      <PortalContainer />
      <WrapperGrid container direction="column" justifyContent="flex-start">
        {loading && <BorderLinearProgress />}
        <PageContainerBackButton onBack={onBack} backButtonIcon={backButtonIcon} />
        <WrapperGridItem item container direction="column" flex="1" sx={(theme) => {
            if(noPadding) {
                return { padding: "0 !important" };
            }
            return {};
        }}>
          {children}
        </WrapperGridItem>
      </WrapperGrid>
    </>
  );
};

export default PageContainer;
