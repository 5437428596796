import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DecodedTextField from '../../../../utils/DecodedTextFIeld';
import { isValidDate } from '../validationUtils';
import { Grid } from '@mui/material';
import { H1TextStepTitle } from '../../../shared/Typography';
import InputMask from 'react-input-mask';
import { LoginDataContext } from '../LoginDataProvider';
import { useMediaQueries } from '../../../../utils/hooks/useMediaQueries';
import { NextButton } from './NextButton';
import ScrollableContainer, {
  LoginDisconnectedPageFooter,
} from '../../../common/ScrollableContainer';
import PageContainer from '../../../common/PageContainer';
import { StyledGrid } from './StyledGrid'


const DOB = (props) => {
  const [error, setError] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const { formatMessage } = useIntl();

  const { loginData, setLoginData } = useContext(LoginDataContext);

  const mediaQuery = useMediaQueries();

  useEffect(() => {
    if (loginData.dateOfBirth) {
      setError(!isValidDate(loginData.dateOfBirth));
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginData((prevState) => ({ ...prevState, [name]: value }));

    if (name === 'dateOfBirth') {
      setError(!isValidDate(value));
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsFormDisabled(true);

    setTimeout(() => {
      if (props.index !== 6) {
        setIsFormDisabled(false);

        props.setIndex((prevIndex) => (prevIndex + 1) % 7);
      }
    }, 250);
  };

  return (
    <PageContainer>
    <form onSubmit={handleClick} style={{ display: 'contents' }}>
      <ScrollableContainer>
        <StyledGrid md={12} xl={8} container sx={{ padding: mediaQuery.isSm ? '20px' : '80px' }}>
          <Grid item>
            <FormattedMessage id="login.patient.dob" defaultMessage={'Patient date of birth'}>
              {(txt) => (
                <H1TextStepTitle isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}>
                  {txt}
                </H1TextStepTitle>
              )}
            </FormattedMessage>
          </Grid>
          <Grid
            container
            gap={2}
            sx={{
              width: '75%',
              '@media (max-width:600px)': {
                width: '100%',
              },
              display: mediaQuery.isMobileLandscape && 'flex',
              flexDirection: mediaQuery.isMobileLandscape && 'row',
              justifyContent: mediaQuery.isMobileLandscape && 'flex-start',
              alignItems: mediaQuery.isMobileLandscape && 'flex-end',
              flexWrap: mediaQuery.isMobileLandscape && 'nowrap',
            }}
          >
            <Grid item xs={12} sm={6} md={6} my='80px'>
              <InputMask
                mask="99/99/9999"
                value={loginData.dateOfBirth}
                onChange={handleInputChange}
              >
                {(props) => (
                  <DecodedTextField
                    type="tel"
                    name="dateOfBirth"
                    variant="outlined"
                    label="Date of Birth"
                    error={error}
                    helperText={
                      error &&
                      formatMessage({
                        id: 'registration.field.error.patient.name.family',
                        defaultMessage: 'Invalid date',
                      })
                    }
                  />
                )}
              </InputMask>
            </Grid>
            </Grid>
          </StyledGrid>
        </ScrollableContainer>
        <LoginDisconnectedPageFooter >
            <NextButton label='NEXT' disabled={loginData.dateOfBirth === '' || error || isFormDisabled}/>
        </LoginDisconnectedPageFooter>
      </form>
    </PageContainer>
  );
};

export default DOB;