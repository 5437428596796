import React from 'react'
import { Grid, styled } from '@mui/material'

export const StyledGrid = styled(Grid)(({ theme, sx }) => ({
    ...sx,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
}))

export const StyledPinCodeGrid = styled(Grid)(({ theme, sx }) => ({
    ...sx,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}))