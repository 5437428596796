import React from 'react';

import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { withRouter } from 'react-router-dom';
import { Grid, styled, TextField } from '@mui/material';
import DecodedComponent from '../shared/DecodedComponent';
import { PageContent, PageFooter } from '../common/ScrollableContainer';
import DecodedButton from '../common/DecodedButton';
import { H2TextTitleContent } from '../shared/Typography';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({});

const initState = {
  initialising: true,
};

const H1TextTitleSecondary = styled(Typography, { variant: 'h1' })(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '40px !important',
  lineHeight: '32px',
}));

class Verification extends DecodedComponent {
  constructor(props) {
    super(props);
    this.state = { ...initState };
    this.bloc = props.bloc;
  }

  __handleTextChange = (e) => {
    this.bloc.setValue(e.target.name, e.target.value);
    this.bloc.setValue('pin', '');
  };

  render() {
    const { busy, handle, pin } = this.state;

    return (
      <>
        <PageContent>
          <H1TextTitleSecondary sx={{ textAlign: 'center' }}>
            User Verification
          </H1TextTitleSecondary>
          <Grid sx={{ marginTop: '32px' }} xs={6} spacing={3} container>
            <Grid item xs={12}>
              <Typography color="primary" sx={{ marginBottom: '24px' }}>
                <FormattedMessage
                  id={'verification.message'}
                  defaultMessage={
                    'Please enter your email address to receive a PIN that will be used to set up this kiosk in the Clinical Hub.'
                  }
                />
              </Typography>
              <TextField
                fullWidth
                id={'handle'}
                name={'handle'}
                value={handle}
                onChange={this.__handleTextChange}
                placeholder={'Email'}
                label={'Email address'}
                autoComplete={'off'}
              />
            </Grid>
            {pin && (
              <Grid item xs={12}>
                <H2TextTitleContent>PIN:</H2TextTitleContent>
                <H1TextTitleSecondary>{pin}</H1TextTitleSecondary>
              </Grid>
            )}
          </Grid>
        </PageContent>
        <PageFooter>
          <Grid spacing={3} container>
            <Grid item xs={6}>
              <DecodedButton loading={busy} disabled={busy} onClick={this.bloc.requestPin}>
                {pin ? <>REQUEST NEW PIN</> : <>REQUEST PIN</>}
              </DecodedButton>
            </Grid>
          </Grid>
        </PageFooter>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(Verification));
