import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import PageContainer from '../../common/PageContainer';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import { authService } from '../../../utils/auth';
import { CheckSession, uriStorage } from '../../../utils/storage';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { addMinutes, compareAsc, isAfter, parse } from 'date-fns';
import { ROOT_ROUTE, routeUtil, USER_INFORMATION_ROUTE } from '../../../utils/route.name';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { providerStorage } from '../../../utils/provider.qs';
import { appointmentApi } from '../../../utils/services/appointments.api';
import { dateUtil } from '../../../utils/date';
import { notificationService } from '../../../utils/notification';
import { ErrorMessage } from '../../../utils/error.resolver';
import { buttonGroupStyle } from '../../shared/CommonStyle';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import DecodedButton, { DecodedSecondaryButton } from '../../common/DecodedButton';
import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../common/ScrollableContainer';
import { AppointmentCard } from '../../shared/AppointmentCard';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { logger } from '../../../utils/logging';
import { TextAlternate } from '../../shared/Typography';
import { globalBloc } from '../../global.bloc';

const styles = (theme) => ({
  ...buttonGroupStyle(theme),
  root: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  text: {
    marginTop: '1em',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '4.2em',
    lineHeight: '1',
    color: theme.palette.secondary.main,
  },
  confirmation: {
    marginTop: '1em',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '2.2em',
    lineHeight: '1',
  },
  list: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  listItem: {
    paddingTop: '2em',
    paddingBottom: '2em',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  listItemComponent: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      width: '100%',
    },
  },
  listItemButtonComponent: {
    minWidth: '100%',
    width: '100%',
    paddingTop: '1em',
  },
  progressBox: {
    margin: '0 auto 0 auto',
  },
  progress: {
    color: theme.palette.secondary.main,
  },
  buttons: {
    paddingTop: '2em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  button: {
    width: '100%',
    padding: '1em',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  dialogButton: {
    maxWidth: '150px',
    margin: '1em',
  },
  phoneNumberText: {
    color: '#737373',
    fontWeight: '550',
    fontSize: '1.5em',
    textAlign: 'center',
    paddingTop: '1.5em',
    paddingBottom: '1.5em',
  },
  cancelButton: {
    fontSize: '1.3em',
    minHeight: '30px',
  },
  appointmentType: {
    fontSize: '1.6em',
    fontWeight: '700',
    textAlign: 'left',
  },
  appointmentTime: {
    fontSize: '1.3em',
    paddingTop: '0.5em',
    textAlign: 'left',
  },
  dialogTitle: {
    color: theme.palette.primary.main,
  },
  cardRoot: {
    padding: '1em',
    maxWidth: '100%',
    backgroundColor: '#F6F6F6',
    borderRadius: '1em',
  },
  reservationText: {
    color: theme.palette.primary.main,
    // textAlign: "left",
    paddingTop: '2em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'center',
    },
  },
  reservationTextSecondary: {
    color: theme.palette.error.main,
    // textAlign: "left",
    paddingTop: '1em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'center',
    },
  },
});

class BookingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,

      appointments: [],
      dialogOpen: false,

      cancelReasonList: [],
      selectedReasonId: '',
      selectedReasonText: '',
    };

    analyticsEventLogger.log(AnalyticsEvent.BOOKING_LIST, {});
  }

  componentDidMount() {
    logger.debug('BookingList.componentDidMount');

    sessionStorage.removeItem('task');
    sessionStorage.removeItem('appt');

    globalBloc.updateGlobalBloc({ orgSelected: false }); //=> this is to enable going back to org selector page instead of skipping it

    //! @ v=== PREVENT USER FROM GOING BACK TO PREVIOUS PAGE ===v
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };

    //! @ v=== REMOVES APPT IN SESSION SO THEY CAN BOOK A NEW ONE ===v
    // sessionStorage.removeItem('appt');

    uriStorage.setCurrentPath(this.props.match.url);
    authService.getIdTokenResult().then((result) => {
      let isFullLogin = result?.claims?.scope?.some((scope) => scope === '*');
      this.setState({ isFullLogin: isFullLogin });
      this._currentLocations(isFullLogin);
    });
  }

  _currentLocations = (isFullLogin) => {
    appointmentApi.getAvailableProviders().then(
      (response) => {
        let providerLookup = {};
        response.data.items.forEach((_item) => (providerLookup[_item.id] = _item));
        this.setState({ providerLookup });
        this._getCurrentAppointment(isFullLogin);
      },
      (reason) => {
        notificationService.httpError(reason);
      },
    );
  };

  _getCurrentAppointment = async (isFullLogin) => {
    let compareDate = dateUtil.addhours(new Date(), -8);

    await appointmentApi
      .getAllFutureAppointments()
      .then((result) => {
        // console.log('appointment : ', result);
        let appointmentList = result.items
          .filter((appointment) => {
            // console.log('appointment list: ', appointmentList);
            const startTime = dateUtil.parseDate(appointment.slot.start);
            const endTime = addMinutes(startTime, appointment.slot.duration);
            return isAfter(endTime, compareDate);
          })
          .sort((appointment1, appointment2) => {
            const date1 = dateUtil.parseDate(appointment1.slot.start);
            const date2 = dateUtil.parseDate(appointment2.slot.start);
            return compareAsc(date1, date2);
          });

        const currentValue = sessionStorage.getItem('booking-redirect');
        if (
          isFullLogin &&
          appointmentList.length === 0 &&
          currentValue !== 'done' &&
          providerStorage.hasProvider() &&
          CheckSession('checkin', 'true')
        ) {
          this.setState({
            loading: false,
          });
          sessionStorage.setItem('booking-redirect', 'done');
          this.props.history.replace(routeUtil.buildAppointmentCheckinException('unknown'));
          return;
          // } else if (
          //   isFullLogin &&
          //   appointmentList.length === 0 &&
          //   currentValue !== 'done'
          //   // !CheckSession('action', 'manage-appointments')
          // ) {
          //   console.log('redirect to user information');
          //   sessionStorage.setItem('booking-redirect', 'done');
          //   this.props.history.push(USER_INFORMATION_ROUTE);
        } else {
          if (isFullLogin && appointmentList.length === 1 && currentValue !== 'done') {
            sessionStorage.setItem('booking-redirect', 'done');
            const route = routeUtil.buildBookingStatusRouteWithAppointmentID(appointmentList[0].id);
            this.props.history.push(route);
          } else {
            this.setState({
              appointments: appointmentList,
              loading: false,
            });
          }
        }
      })
      .catch((error) => {
        notificationService.error(
          'Unable to find your existing reservations' + ErrorMessage.CALL_SUPPORT,
        );
        console.error(error);

        this.setState({
          appointments: [],
          loading: false,
        });
      });
  };

  _handleLogout = () => {
    authService.logout().then(() => {
      uriStorage.clearPath();
      providerStorage.clearProvider();
      window.location = ROOT_ROUTE;
    });
  };

  // _handleTextChange = (event) => {
  //     let change = {};
  //     change[event.target.name] = event.target.value;

  //     this.setState(change);
  // };

  _renderConfirmationDialog = () => {
    const { classes, history } = this.props;
    const { dialogOpen } = this.state;

    return (
      <Dialog
        maxWidth={'md'}
        open={dialogOpen}
        onClose={() => this.setState({ dialogOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          <Typography variant="h4" component={'div'}>
            <FormattedMessage
              id={'booking.list.reserve.dialog.title'}
              defaultMessage={'Reservation'}
            />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" component={'div'}>
            <FormattedMarkdown
              id={'booking.list.reserve.dialog.content'}
              defaultMessage={'Are you sure you want to make a new reservation?'}
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.dialogButton}
            variant={'contained'}
            color="secondary"
            onClick={() => this.setState({ dialogOpen: false })}
          >
            Cancel
          </Button>
          <Button
            className={classes.dialogButton}
            variant={'contained'}
            color="primary"
            onClick={() => history.push(USER_INFORMATION_ROUTE)}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  getOpenButtonTextBasedOnAppointmentType = (appointment, appointmentType) => {
    if (appointmentType !== 'Virtual') {
      return 'Open';
    }
    const AppointmentDate = new Date(appointment.slot.start);
    const currentDate = new Date();
    if (currentDate < AppointmentDate) {
      return 'Check Device';
    }
    return 'Join Meeting';
  };

  _renderAppointmentItem = (appointment, organisation) => {
    const { history } = this.props;

    let appointmentType = 'Virtual';
    let nextRoute = routeUtil.buildVirtualWaitingRoomRouteWithAppointmentID(appointment.id);

    if (appointment.type === 'IN_PERSON_WALK_IN' || appointment.type === 'IN_PERSON') {
      appointmentType = 'In-person';
      nextRoute = routeUtil.buildBookingStatusRouteWithAppointmentID(appointment.id);
    }

    const parsedTime = parse(appointment.slot.start, "yyyy-MM-dd'T'HH:mm:ssX", new Date());
    const isNotCancellable = appointmentType === 'In-person' && appointment.status !== 'RESERVED';

    logger.debug('appointment', appointment.slot.start);

    return (
      <>
        <AppointmentCard
          appointment={appointment}
          organisation={organisation}
          visitTime={parsedTime}
          controls={
            appointment.status !== 'REQUESTED' ? (
              <>
                <Grid xs={12} item>
                  <DecodedButton
                    sx={{
                      fontSize: '1.3em',
                      minHeight: '30px',
                    }}
                    size="small"
                    onClick={() => history.push(nextRoute, { showHandleBack: true })}
                  >
                    {this.getOpenButtonTextBasedOnAppointmentType(appointment, appointmentType)}
                  </DecodedButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid xs={12} item>
                  <DecodedButton
                    size="small"
                    sx={{
                      fontSize: '1.3em',
                      minHeight: '30px',
                    }}
                    disabled={true}
                  >
                    Scheduling...
                  </DecodedButton>
                </Grid>
              </>
            )
          }
        ></AppointmentCard>
      </>
    );
  };

  render() {
    const { classes } = this.props;
    const { loading, appointments, providerLookup, isFullLogin } = this.state;

    const assistantMessage = loading
      ? {
          id: 'booking.list.bubble.loading',
          text: 'We are looking for your reservations.',
        }
      : {
          id: 'booking.list.bubble.success',
          text: 'Please check your upcoming reservations below.',
        };

    return (
      <PageContainer loading={loading}>
        <ScrollableQuinnContainer
          size={'large'}
          messageId={assistantMessage.id}
          message={assistantMessage.text}
        >
          <div className={classes.root}>
            {loading && (
              <div className={classes.progressBox}>
                <CircularProgress classes={{ circle: classes.progress }} />
              </div>
            )}

            {!loading && appointments.length !== 0 && (
              <Grid container direction="row" alignItems="flex-start" spacing={3}>
                {appointments.map((appointment) => (
                  <Grid key={appointment.id} item xs={12} sm={12} md={6}>
                    {this._renderAppointmentItem(
                      appointment,
                      providerLookup[appointment.organisationId],
                    )}
                  </Grid>
                ))}
              </Grid>
            )}

            {!loading && appointments.length === 0 && (
              <>
                <TextAlternate sm primary>
                  <FormattedMarkdown
                    id={'booking.list.empty.message'}
                    defaultMessage={"You don't have any existing reservations."}
                  />
                </TextAlternate>
                {CheckSession('action', 'manage-appointments') && (
                  <TextAlternate error>
                    <FormattedMarkdown
                      id={'booking.list.empty.message'}
                      defaultMessage={
                        'Please contact the clinic directly for reservations made outside of our automated reservation system.'
                      }
                    />
                  </TextAlternate>
                )}
              </>
            )}
          </div>
          {this._renderConfirmationDialog()}
        </ScrollableQuinnContainer>

        <DefaultDisconnectedPageFooter justifyContent={'center'}>
          {!providerStorage.isKiosk() && (
            <Grid item xs={12} sm={6}>
              <DecodedSecondaryButton
                disabled={loading}
                loading={loading}
                onClick={this._handleLogout}
              >
                Log Out
              </DecodedSecondaryButton>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            {isFullLogin && (
              <DecodedButton
                disabled={loading}
                loading={loading}
                onClick={() => {
                  if (appointments.length === 0) {
                    this.props.history.push(USER_INFORMATION_ROUTE);
                  } else if (
                    appointments.length === 0 &&
                    sessionStorage.getItem('action') !== 'manage-appointments'
                  ) {
                    this.props.history.push(USER_INFORMATION_ROUTE);
                  } else {
                    this.setState({ dialogOpen: true });
                  }
                  sessionStorage.removeItem('task');
                }}
              >
                New Reservation
              </DecodedButton>
            )}
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(BookingList);
