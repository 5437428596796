import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PageContainer from "../common/PageContainer";
import {SelectValidator, TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {Button, CircularProgress, Grid, Typography} from '@mui/material';
import { Select } from './components/Select';
import { FormattedMessage } from 'react-intl';
import { GENDER_MANDATORY_OPTIONS } from '../../utils/user';
import ChatBubble from '../shared/ChatBubble';
import { DateMask, PhoneTextMask, ZipTextMask } from '../shared/InputTextMask';
import { STATES } from './const';
import { authService } from '../../utils/auth';
import {FormattedMarkdown, MapsAutocompleteField} from '@decodedhealth/react-library';
import MenuItem from "@mui/material/MenuItem";
import {useEmergencyCodesets, useGuarantorCodesets} from "../../utils/services/register.api";
import {ScrollableBox, ScrollableQuinnContainer, ScrollableWrapper} from "../common/ScrollableContainer";
import { TextH5Standard } from '../shared/Typography'

const useStyles = makeStyles((theme) => ({
  validation: {
    flex: '1',
    width: '100%',
  },
  title: {
    paddingTop: '1em',
    color: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
  },
  formInput: {
    flex: '1',
  },
  buttonGroup: {
    paddingTop: '2em',
  },
  button: {
    width: '100%',
    padding: '1em',
  },
}));

export const RelationShipForm = ({
  message,
  messageId,
  data,
  formName,
  formDefaultTitle,
  label = '',
  submitText,
  loading,
  handleSubmit,
  handleBack,
}) => {
  const isLoading = () => {
    return loading || authService.isLoading();
  };
  const classes = useStyles();
  const [formState, setFormState] = React.useState({
    relationship: data.relationship ?? '',
    firstName: data.firstName ?? '',
    lastName: data.lastName ?? '',
    gender: data.gender ?? '',
    dateOfBirth: data.dateOfBirth ?? '',
    phoneNumber: data.phoneNumber ?? '',
    address: data.address ?? '',
    city: data.city ?? '',
    postCode: data.postCode ?? '',
    state: data.state ?? '',
  });
  const {
    relationship,
    firstName,
    lastName,
    gender,
    dateOfBirth,
    phoneNumber,
    address,
    city,
    postCode,
    state,
  } = formState;

  const [relationshipList, relationshipsLoaded] = useEmergencyCodesets();

  const handleAutoCompleteChange = React.useCallback((formValues) => {
    setFormState((prevValue) => ({
      ...prevValue,
      address: formValues.address,
      city: formValues.city,
      state: formValues.administrativeArea,
      postCode: formValues.postalCode,
    }));
  }, []);

  const handleTextChange = (e) => {
    setFormState((state) => ({ ...state, [e.target.name]: e.target.value }));
  };
  const onSubmit = (e) => {
    handleSubmit(formState);
  };

  return (
    <PageContainer loading={loading} onBack={handleBack}>
      <ScrollableQuinnContainer  messageId={messageId} message={message}>
      <ValidatorForm autoComplete="off" onSubmit={onSubmit} className={classes.validation}>
        <div className={classes.form}>
          <div className={classes.formInput}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <TextH5Standard secondary>
                  <FormattedMarkdown
                      id={`registration.user.step.${formName}.title`}
                      defaultMessage={`${formDefaultTitle}`}
                  />
                </TextH5Standard>
              </Grid>

              {relationshipsLoaded && (
                  <Grid item xs={12} sm={12}>
                    <SelectValidator
                        required
                        fullWidth
                        select
                        id="relationship"
                        name="relationship"
                        label={`The relationship of the ${label.toLowerCase()} to the patient`}
                        value={relationship}
                        onChange={handleTextChange}
                    >
                      {relationshipList.map((r, i) => (
                          <MenuItem key={i} value={r.code}>
                            {r.code === 'SELF' ?
                                'No emergency contact' :
                                r.value
                            }
                          </MenuItem>
                      ))}
                    </SelectValidator>
                  </Grid>
              )}

              {relationship && !['SELF', ''].includes(relationship) ? (
                <>
                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      required
                      fullWidth
                      autoFocus={true}
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      value={firstName}
                      validators={['isValidName']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.patient.name.given"
                          defaultMessage={'Invalid name.'}
                        />,
                      ]}
                      onChange={handleTextChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      required
                      fullWidth
                      id="lastName"
                      label={
                        <FormattedMessage
                          id="registration.field.label.lastName"
                          defaultMessage={'Last Name'}
                        />
                      }
                      type="text"
                      name="lastName"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      value={lastName}
                      validators={['isValidName']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.patient.name.family"
                          defaultMessage={'Invalid name.'}
                        />,
                      ]}
                      onChange={handleTextChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      fullWidth
                      id="phoneNumber"
                      label="Home Phone"
                      required
                      name="phoneNumber"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                        inputMode: 'tel',
                      }}
                      value={phoneNumber}
                      onChange={handleTextChange}
                      placeholder="e.g. (202) 555-1234"
                      validators={['matchRegexp:^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.mobile"
                          defaultMessage={'Invalid phone number'}
                        />,
                      ]}
                      InputProps={{
                        inputComponent: PhoneTextMask,
                      }}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </div>
          <Grid container spacing={2} justifyContent="flex-start" className={classes.buttonGroup}>
            <Grid item xs={12} sm={6} className={classes.button}>
              <Button type="submit" variant="contained" color="primary" disabled={!relationshipsLoaded || loading}>
                {isLoading() ? (
                  <CircularProgress size="1.5em" />
                ) : (
                  <FormattedMessage
                    id="registration.button.label.submit"
                    defaultMessage={submitText ?? 'SUBMIT'}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </div>
      </ValidatorForm>
      </ScrollableQuinnContainer>
    </PageContainer>
  );
};
