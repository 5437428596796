import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { authService } from '../../../utils/auth';
import { uriStorage } from '../../../utils/storage';
import { Box, Grid } from '@mui/material';
import { appointmentApi } from '../../../utils/services/appointments.api';
import { VIRTUAL_SCHEDULE } from '../../../utils/route.name';
import { dateUtil } from '../../../utils/date';
import { notificationService } from '../../../utils/notification';
import { ErrorMessage } from '../../../utils/error.resolver';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { CheckDevicePage } from './CheckDevice';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import PageContainer from '../../common/PageContainer';
import { ScrollableQuinnContainer } from '../../common/ScrollableContainer';
import { providerStorage } from '../../../utils/provider.qs';
import { AppointmentCard } from './AppointmentCard';
import { H2TextTitleSecondaryContent, TextBody1Standard } from '../../shared/Typography';
import { logger } from '../../../utils/logging';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  wrapper: {
    margin: 'auto',
    marginLeft: '0',
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
    },
  },
  text: {
    marginTop: '1em',
    textAlign: 'left',
    fontWeight: '700',
    fontSize: '4.2em',
    lineHeight: '1',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '3.8em',
      textAlign: 'center',
    },
  },
  thank: {
    marginTop: '1em',
    textAlign: 'left',
    fontSize: '40px',
    lineHeight: '1',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: '3em',
      margin: '0',
      padding: '0',
    },
  },
  confirmation: {
    marginTop: '1em',
    textAlign: 'left',
    fontWeight: '500',
    fontSize: '2.2em',
    lineHeight: '1',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8em',
      textAlign: 'center',
    },
  },
  linkBox: {
    marginTop: '4em',
    marginBottom: '4em',
    textAlign: 'left',
    lineHeight: '1',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  link: {
    textDecoration: 'underline',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
});

class PostBooking extends Component {
  constructor(props) {
    super(props);

    const provider = providerStorage.getCurrentProvider();

    this.state = {
      loading: true,
      visitTime: '',
      atLocation: provider?.length > 0,
    };
  }

  componentDidMount() {
    let { appointmentId } = this.props.match.params;

    Promise.all([
      appointmentApi.getAppointmentStatus(appointmentId),
      appointmentApi.getAvailableProviders(),
    ])
      .then((result) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_SUCCESS);

        const appointment = result[0].data;

        if (appointment.type !== 'VIRTUAL') {
          setTimeout(() => {
            uriStorage.clearPath();
            authService.logout();
          });
        }

        if (appointment.status === 'DRAFT') {
          this.setState({
            appointment: appointment,
          });
        } else {
          const organisation = result[1].data.items.filter(
            (_provider) => _provider.id === appointment.provider,
          )[0];

          const startTime = dateUtil.parseDate(appointment.start);

          this.setState({
            appointment: appointment,
            organisation: organisation,
            visitTime: dateUtil.fullFriendlyOutputExcludeYear(startTime),
          });
        }
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_ERROR, {
          appointmentId: appointmentId,
          reason: error,
        });

        notificationService.error('Error showing your visit time. ' + ErrorMessage.CALL_SUPPORT);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { classes } = this.props;
    const { loading, visitTime, appointment, organisation, atLocation } = this.state;
    let code = 'loading';
    if (appointment) {
      if (appointment.type && typeof appointment.type === 'string') {
        code = `${appointment.type.replace(/_-/g, '').toLowerCase()}`;
      } else {
        code = `general`;
      }

      if (appointment?.status === 'DRAFT') {
        return this.__renderDraft();
      }
    }

    if (appointment?.type === 'VIRTUAL') {
      return this.__renderVirtual();
    }

    if (atLocation) {
      return this.__renderWalkin();
    }

    return (
      <PageContainer loading={loading} hideBack={true}>
        <ScrollableQuinnContainer
          messageId={`postbooking.user.${code}.bubble`}
          message="Thank you for reserving your place in line.  You will receive a reminder from us closer to your reservation and any necessary updates. Please check-in upon arrival at the clinic by scanning the QR CODE or using our KIOSK."
        >
          <Grid item container spacing={0}>
            <Grid item xs={12}>
              {!loading && (
                <AppointmentCard
                  appointment={appointment}
                  organisation={organisation}
                  visitTime={visitTime}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.thank}>
                <FormattedMarkdown
                  id={`postbooking.thankyou.${code}.message`}
                  defaultMessage={`Thank You!`}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <H2TextTitleSecondaryContent sx={{ marginTop: '-2.5rem' }}>
                <FormattedMarkdown
                  id={`postbooking.thankyou.${code}.notice`}
                  defaultMessage={`Please bring your ID and Proof of Insurance`}
                />
              </H2TextTitleSecondaryContent>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }

  __renderDraft() {
    const { classes } = this.props;
    const { loading, visitTime, appointment } = this.state;
    const { appointmentId } = this.props.match.params;

    let code = 'loading';
    if (appointment) {
      if (appointment.service && typeof appointment.service === 'string') {
        code = `${appointment.service.replace(/-/g, '').toLowerCase()}`;
      } else {
        code = `general`;
      }
    }

    return (
      <PageContainer loading={loading} hideBack={true}>
        <ScrollableQuinnContainer
          messageId={`postbooking.user.${code}.bubble`}
          message="Please note, that your reservation has not been made yet. We will contact you shortly to finalize your reservation."
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="bodyText" className={classes.thank}>
                  <FormattedMarkdown
                    id={`postbooking.thankyou.${code}.message`}
                    defaultMessage={`Thank You!`}
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }

  __renderVirtual() {
    const { classes } = this.props;
    const { loading, visitTime, appointment } = this.state;
    const { appointmentId } = this.props.match.params;

    let code = 'loading';
    if (appointment) {
      if (appointment.service && typeof appointment.service === 'string') {
        code = `${appointment.service.replace(/-/g, '').toLowerCase()}`;
      } else {
        code = `general`;
      }
    }

    return (
      <PageContainer loading={loading} hideBack={true}>
        <CheckDevicePage appointmentId={appointmentId} />
      </PageContainer>
    );
  }

  __renderWalkin() {
    const { classes } = this.props;
    const { loading, visitTime, appointment } = this.state;

    let code = 'loading';
    if (appointment) {
      if (appointment.service && typeof appointment.service === 'string') {
        code = `${appointment.service.replace(/-/g, '').toLowerCase()}`;
      } else {
        code = `general`;
      }
    }

    return (
      <PageContainer loading={loading} hideBack={true}>
        <ScrollableQuinnContainer
          messageId={`postbooking.user.${code}.bubble`}
          message="That's it. We're done! You will be getting a reminder from us closer to your appointment."
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="bodyText" className={classes.thank}>
                <FormattedMarkdown
                  id={`postbooking.thankyou.${code}.message`}
                  defaultMessage={`Thank You!`}
                />
              </Typography>

              <TextBody1Standard
                sx={{
                  marginTop: '1em',
                  textAlign: 'left',
                  fontWeight: '500',
                  lineHeight: '1',
                }}
              >
                <FormattedMarkdown
                  id={`postbooking.reservation.${code}.message`}
                  defaultMessage={`Your visit has been reserved`}
                />
              </TextBody1Standard>

              {visitTime && (
                <TextBody1Standard
                  sx={{
                    marginTop: '1em',
                    textAlign: 'left',
                    fontWeight: '500',
                    lineHeight: '1',
                  }}
                >
                  <FormattedMarkdown
                    id="postbooking.time.message"
                    defaultMessage={`at ${visitTime}`}
                  />
                </TextBody1Standard>
              )}

              {this.props.match.params.appointmentType === VIRTUAL_SCHEDULE && (
                <TextBody1Standard
                  sx={{
                    marginTop: '1em',
                    textAlign: 'left',
                    fontWeight: '500',
                    lineHeight: '1',
                  }}
                >
                  <FormattedMarkdown
                    id="postbooking.virtual.message"
                    defaultMessage="You can close the browser. We will send you a reminder via SMS when the provider is ready to receive you."
                  />
                </TextBody1Standard>
              )}
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(PostBooking);
