import * as React from 'react';
import { Wizard, Loading, useWizardContext } from '../../shared-library';
import { useBookingBloc } from './bloc';
import BookingWizard from './BookingWizard/BookingWizard';
import {
  LOGIN_ROUTE,
  PRE_AUTH_QUINN_ROUTE,
  routeUtil,
} from '../../utils/route.name';
import { CheckSession, uriStorage } from '../../utils/storage';
import 'firebase/auth';
import { logger } from '../../utils/logging';
import { authService } from '../../utils/auth';
import { providerStorage } from '../../utils/provider.qs';
import { useState } from 'react';
import { globalBloc } from '../global.bloc'

/**
 * steps : {
 *  id: string,
 *  message: {
 *    id: 'booking.appointment',
 *    text: ''
 *  }
 *  component: <Component />,
 *  onBack: () => {} // first step back
 *  onSubmit: () => {} // last step submit
 * }
 */
export const Booking = ({ match, history }) => {
    const { appointmentType } = match.params;

    const apptID = sessionStorage.getItem('appt');
    const [goBack, setGoBack] = useState(false);

    const bookingState = useBookingBloc(apptID, appointmentType, history);

    const { orgSelected } = globalBloc.subject.value;

    React.useEffect(() => {
      logger.debug('Booking did mount');

      //! @ v=== PREVENT USER FROM GOING BACK TO PREVIOUS PAGE ===v
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function() {
        window.history.go(1);
      };

      uriStorage.setCurrentPath(match.url);

      return () => {
        uriStorage.clearPath(true);
      }

    }, []);


    const handleBack = React.useCallback(() => {
      uriStorage.clearPath(true);
      history.goBack();
    }, [history]);

    //==> FINAL PUSH TO CONFIRMATION <==//

    const handleSubmit = React.useCallback(async () => {
      if (bookingState.bookingBloc.isUserLoggedIn()) {
        logger.debug('Using authenticated user.');
        return handleAuthSubmit();
      } else {
        logger.debug('Using anonymous user.');
        return handleAnonymousSubmit();
      }
    }, [bookingState.bookingBloc, apptID, history]);

    const handleAuthSubmit = React.useCallback(async () => {
      sessionStorage.setItem('appt', apptID);

      const result = await bookingState.bookingBloc.confirmAppointment({});
      if (!(result?.isAxiosError || result?.response?.status > 299)) {
        if (bookingState.bookingBloc.isVirtual()) {
          history.push(routeUtil.buildAppointmentCheckinConfirmation(apptID));
        } else if (bookingState.bookingBloc.isWalkin()) {
          history.push(routeUtil.buildBookingIdentityDocument(apptID));
        } else {
          sessionStorage.setItem('task', 'new-confirm');
          history.push(
            routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(
              sessionStorage.getItem('appt'),
              'IN_PERSON',
            ),
          );
        }
      } else {
        if (authService.getUser() === null) {
          providerStorage.clearProvider();
          history.push('/');
        } else {
          setGoBack(true);
        }
      }
    }, [bookingState.bookingBloc, apptID, history]);


    const handleAnonymousSubmit = React.useCallback(async () => {
      sessionStorage.setItem('appt', apptID);
      sessionStorage.setItem('task', '_anonymous_schedule_');
      sessionStorage.setItem('action', 'anonymous_schedule');

      history.push(LOGIN_ROUTE);
    }, [bookingState.bookingBloc, apptID, history]);


    if (!bookingState?.steps) {
      return <Loading isFullscreen />;
    }

    return (
      <Wizard steps={bookingState.steps}
              startAtStep={CheckSession('task', 'booking_after_login') || CheckSession('task', 'new-confirm') ? 2 : orgSelected ? 1 : 0}>
        {bookingState.steps && bookingState.appointmentType ? (
          <BookingWizard isGoBack={goBack} setGoBack={setGoBack} bookingState={bookingState} onBack={handleBack}
                         onSubmit={handleSubmit} />
        ) : (
          <Loading isFullscreen />
        )}
      </Wizard>
    );
  }
;