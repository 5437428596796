import PageContainer from "../../../common/PageContainer";
import {PageContent, PageFooter, ScrollableQuinnContainer} from "../../../common/ScrollableContainer";
import {Button, Grid} from "@mui/material";
import React from "react";
import withStyles from "@mui/styles/withStyles";
import {WizardContextConsumer} from "../../../../shared-library/components/Wizard/wizard-context";
import { DecodedButton, DecodedSecondaryButton } from '../../../common/DecodedButton';

const EnterScanInsurance = (props) => {
    const {startWithScan, bloc, context} = props
    return (
        <PageContainer
            loading={false}
            onBack={() => {
                bloc.close();
            }}
        >
            <ScrollableQuinnContainer
                messageId="insurance.scanOption.bubble"
                message={`Please start by scanning your insurance card. If you do not have your insurance card, you may skip this step.`}
            >
                <PageContent></PageContent>
                <PageFooter>
                    <Grid container item spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <DecodedButton color="primary" onClick={startWithScan}>
                                Scan insurance card
                            </DecodedButton>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DecodedSecondaryButton onClick={() => bloc.next(context)}>
                                Skip
                            </DecodedSecondaryButton>
                        </Grid>
                    </Grid>
                </PageFooter>
            </ScrollableQuinnContainer>
        </PageContainer>
    )
}

export default withStyles()((props) => (
    <WizardContextConsumer>
        {(value) => {
            return <EnterScanInsurance context={value} {...props} />;
        }}
    </WizardContextConsumer>
));
