import React from 'react';
import PageContainer from '../common/PageContainer';
import { Box, Grid } from '@mui/material';
import { PageContent, PageFooter, ScrollableQuinnContainer } from '../common/ScrollableContainer';
import { DecodedButton, DecodedSecondaryButton } from '../common/DecodedButton';
import Loading from '../shared/Loading';
import { FormattedMessage } from 'react-intl';
import { TextStepSecondary } from '../shared/Typography';

export const IntroStep = ({ isLoading, onScanID, handleBack, userFirstName, onSkip, existing }) => {
  return (
    <PageContainer loading={isLoading} onBack={handleBack} hideBack={handleBack === undefined}>
      {isLoading && <Loading />}
      {!isLoading && (
        <ScrollableQuinnContainer
          messageId={
            existing
              ? 'registration.scan.document.existing.intro'
              : 'registration.scan.document.intro'
          }
          message={
            existing
              ? `Hi ${userFirstName}. We currently have your identification on file. Would you like to update your identification?`
              : `Hi ${userFirstName}. Please scan your current driver's license or state issued identification card.`
          }
        >
          <PageContent>
            {!existing && <Box sx={{ marginY: '30px' }}>
              <FormattedMessage
                id="registration.scan.document.disclaimer"
                defaultMessage="IF THE PATIENT IS A MINOR OR DOES NOT HAVE AN ID PLEASE CLICK THE SKIP BUTTON."
              >
                {(txt) => <TextStepSecondary>{txt}</TextStepSecondary>}
              </FormattedMessage>
            </Box>}
          </PageContent>
          <PageFooter>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <DecodedButton onClick={onScanID}>
                  <FormattedMessage id={existing ? "update.id.existing" : "update.id.new"} defaultMessage={existing ? "UPDATE ID" :  "SCAN ID"} />
                </DecodedButton>
              </Grid>
              <Grid xs={12} item>
                <DecodedSecondaryButton onClick={onSkip}>
                  <FormattedMessage id="update.id.skip" defaultMessage="SKIP" />
                </DecodedSecondaryButton>
              </Grid>
            </Grid>
          </PageFooter>
        </ScrollableQuinnContainer>
      )}
    </PageContainer>
  );
};
