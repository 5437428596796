import axios from 'axios';
import { tenantUtil } from '../tenant';
import { VERSION } from '../version';
import retry from '../retry';

class PaymentApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';
    this.instance = axios.create();
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers['X-DH-source'] = `Consumer Webapp`;
        config.headers['X-DH-version'] = VERSION;
        config.headers['Authorization'] = `Bearer ${tenantUtil.tenantToken()}`;
        config.headers['Content-Type'] = 'multipart/form-data';
        config.headers['X-API-KEY'] = process.env.REACT_APP_DH_API_KEY;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  submitPaymentCommand(request) {
    return retry(() => axios.post(`${this.baseUrl}/api/accounts/me/payment_methods`, request));
  }

  verifyPayment(request) {
    return retry(() => axios.put(`${this.baseUrl}/api/accounts/me/payment_methods`, request));
  }

  getPaymentMethods() {
    if (sessionStorage.getItem('action') === 'registration.only') {
      return Promise.resolve({ data: { page: { size: 0 } } });
    }
    return axios.get(`${this.baseUrl}/api/accounts/me/payment_methods`);
  }

  submitInsuranceCard(formData, action) {
    if (sessionStorage.getItem('action') === 'registration.only') {
      return retry(() =>
        this.instance.post(`${this.baseUrl}/api/insurances/card/analyze_document`, formData),
      );
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    return retry(() =>
      axios.put(`${this.baseUrl}/api/accounts/me/payment_methods/insurance_card`, formData, config),
    );
  }

  getAnalyzeResult(analyzeId) {
    return this.instance.get(`${this.baseUrl}/api/insurances/card/analyze_document/${analyzeId}`);
  }

  getInsuranceCard() {
    return axios.get(`${this.baseUrl}/api/accounts/me/payment_methods/primary/insurance_card`);
  }
}

export const paymentApi = new PaymentApi();
