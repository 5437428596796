import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, TextField } from '@mui/material';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import MenuItem from '@mui/material/MenuItem';
import withStyles from '@mui/styles/withStyles';
import Autocomplete from '@mui/material/Autocomplete';

import { paymentApi } from '../../../../../utils/services/payment.api';
import { userInfoUtil } from '../../../../../utils/user';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../../utils/events';
import { insuranceStorage } from '../../../../../utils/insurance.storage';
import { DecodedSecondaryButton, DecodedSubmitButton } from '../../../../common/DecodedButton';
import DecodedComponent from '../../../../shared/DecodedComponent';
import PageContainer from '../../../../common/PageContainer';
import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../../../common/ScrollableContainer';
import { WizardContextConsumer } from '../../../../../shared-library/components/Wizard/wizard-context';
import { FlexValidatorForm } from '../../../../common/FlexValidatorForm';
import LinearProgress from '@mui/material/LinearProgress';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

const initState = {
  initialising: true,
  planLoading: false,

  providerList: [],
  planTypeList: [],
  planList: [],

  form: {
    memberId: '',
    provider: '',
    planType: '',
    userPlan: '',
    customUserPlanName: '',
  },
};

class InsuranceInformation extends DecodedComponent {
  constructor(props) {
    super(props);
    this.bloc = props.bloc;
    this.state = { ...initState };
  }

  handleProviderChange = (event, newValue) => {
    this.bloc.setProvider(newValue);
  };

  handlePlanTypeChange = (event) => {
    this.bloc.setPlanType(event.target.value);
  };

  handleUserPlanChange = (event) => {
    this.bloc.setPlan(event.target.value);
  };

  handleTextFieldChange = (event) => {
    this.bloc.handleTextChange(event.target.name, event.target.value);
  };

  handleGenderChange = (event) => {
    this.setState({
      gender: event.target.value,
    });
  };

  handleDateChange = (date) => {
    this.setState({
      dateOfBirth: date,
    });
  };

  doSubmit = () => {
    const { context } = this.props;
    let {
      form: { userPlan },
    } = this.state;

    if (this._isPlanIdUnknown(userPlan.planId)) {
      this.bloc.showUnknown();
    } else {
      this.bloc.next(context);
    }
  };

  doUnsure = () => {
    this.bloc.showUnknown();
  };

  _isPlanIdUnknown = (planId) => {
    let parsePlanId = parseInt(planId);
    return parsePlanId === -1;
  };

  isFormValid = () => {
    let { provider, planType, userPlan } = this.state;

    return (
      provider && planType && userPlan && provider !== '' && planType !== '' && userPlan !== ''
    );
  };

  render() {
    let { context } = this.props;

    let { providerList, planTypeList, planList, planLoading, form, initialising } = this.state;

    let { memberId, provider, planType, userPlan } = form;

    return (
      <PageContainer
        loading={initialising || planLoading}
        onBack={() => {
          this.bloc.back(context);
        }}
      >
        <FlexValidatorForm autoComplete="off" onSubmit={this.doSubmit}>
          <ScrollableQuinnContainer
            messageId="insurance.form.bubble"
            message="Please enter the patient's insurance information"
          >
            <Grid container spacing={3} sx={{ paddingBottom: '24px' }}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  required
                  fullWidth
                  id="memberId"
                  label="Member ID"
                  name="memberId"
                  value={memberId}
                  validators={['required']}
                  errorMessages={['Please enter your member ID']}
                  onChange={this.handleTextFieldChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="provider"
                  required
                  disabled={!(memberId && memberId.length !== 0)}
                  inputValue={provider}
                  onInputChange={this.handleProviderChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText="Please select your insurance provider"
                      label="Insurance Provider"
                      margin="normal"
                    />
                  )}
                  options={providerList.map((option) => option.label).sort()}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectValidator
                  id="providerPlan"
                  fullWidth
                  select
                  required
                  disabled={!(provider && provider.length !== 0)}
                  label="Insurance Plan Type"
                  value={planType}
                  onChange={this.handlePlanTypeChange}
                  validators={['required']}
                  errorMessages={['Please select your insurance plan type.']}
                  helperText="Please select an insurance plan type"
                >
                  {planTypeList.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </SelectValidator>
              </Grid>

              <Grid item xs={12}>
                <SelectValidator
                  id="userPlan"
                  fullWidth
                  select
                  required
                  disabled={!(planList && planList.length !== 0)}
                  label="Your Insurance Plan"
                  value={userPlan}
                  onChange={this.handleUserPlanChange}
                  validators={['required']}
                  errorMessages={['Please select your insurance plan.']}
                  helperText="Please select your insurance plan"
                >
                  {planList.map((option) => (
                    <MenuItem key={option.planName} value={option}>
                      {option.planName}
                    </MenuItem>
                  ))}
                </SelectValidator>
                {planLoading && <LinearProgress />}
              </Grid>
            </Grid>
          </ScrollableQuinnContainer>
          <DefaultDisconnectedPageFooter justifyContent={'left'}>
            <Grid item xs={12}>
              <DecodedSubmitButton loading={initialising}>
                <FormattedMessage
                  id="insurance.form.button.label.submit"
                  defaultMessage={'Continue'}
                />
              </DecodedSubmitButton>
            </Grid>
            <Grid item xs={12}>
              <DecodedSecondaryButton loading={initialising} onClick={this.doUnsure}>
                <FormattedMessage
                  id="insurance.form.button.label.unsure"
                  defaultMessage={'Insurance Not Listed or Unsure'}
                />
              </DecodedSecondaryButton>
            </Grid>
          </DefaultDisconnectedPageFooter>
        </FlexValidatorForm>
      </PageContainer>
    );
  }
}

export default withStyles(styles)((props) => (
  <WizardContextConsumer>
    {(value) => {
      return <InsuranceInformation context={value} {...props} />;
    }}
  </WizardContextConsumer>
));
