import React from 'react';
import { Redirect } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { uriStorage } from '../../utils/storage';
import BasicInformation from './BasicInformation';
import { FormControlLabel, Link } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Address from './AddressForm';
import { registrationApi } from '../../utils/services/register.api';
import { authService } from '../../utils/auth';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { notificationService } from '../../utils/notification';
import { ErrorMessage, errorResolver } from '../../utils/error.resolver';
import { userInfoUtil } from '../../utils/user';
import { phoneUtil } from '../../utils/phone';
import { QUINN_ROUTE, routeUtil } from '../../utils/route.name';
import {
  InvalidPhoneNumberModal,
  NotConnectedPhoneNumberModal,
} from './PhoneNumberVerificationModals';
import { Checkbox } from '../shared/components/Checkbox';
import DecodedComponent from '../shared/DecodedComponent';
import { EmergencyContact } from './EmergencyContact';
import { Bloc } from './bloc';
import { pharmacyApi } from '../../utils/services/pharmacy.api';
import { PreScan } from './PreScan';
import { IntroStep } from './IdentityVerification';
import { providerStorage } from '../../utils/provider.qs';
import FrontScan from './FrontScan';
import BackScan from './BackScan';
import { IdentityVerificationRequired } from './IdentityVerificationRequired';
import InsuranceComponent from './Insurance';

const styles = (theme) => ({
  grid: {
    flex: '1',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  checkBox: {
    transform: 'scale(2)',
    marginLeft: '1em',
    marginRight: '1em',
  },
  button: {
    width: '100%',
    padding: '3em',
    textAlign: 'center',
  },
});

const initState = {
  loading: false,
  activeStep: 0,
  scanSkipped: false,
  hideBackButton: false,

  firstName: '',
  lastName: '',
  addressLine1: '',
  addressLine2: '',
  addressAdministrativeArea: '',
  addressCity: '',
  addressPostcode: '',
  addressCountry: 'US',
  codeCountry: 'US',
  code: '+1',
  number: '',
  email: '',
  gender: '',
  ssn: '',
  dateOfBirth: '',

  hearAboutUs: '',
  race: '',
  ethnicity: '',
  language: '',

  acceptTerms: false,

  registrationSuccess: false,

  invalidPhoneNumber: false,
  notConnectedPhoneNumber: false,

  frontImageValue: {},
  backImageValue: {},
  idVerificationReference: null,
  isPatientMyself: 'true',
  isFirstTimeVisit: 'true',

  pharmacy: {},
  responsibleParty: { relationship: 'SELF' },
  emergency: {},
};

class PatientInformation extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = new Bloc({});
    this.state = initState;
  }

  componentDidMount() {
    super.componentDidMount();

    const { action } = this.bloc.subject.value;

    // uriStorage.setCurrentPath(this.props.match.url);

    analyticsEventLogger.log(AnalyticsEvent.REGISTER_OPEN, { action: action });

    this.setState({ loading: true });
    this.bloc
      .__initialise()
      .then((user) => {
        this.setState({
          ...this.props.location.state,
          ...user,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  handleNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleTextChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.value;
    this.setState(change);
  };

  handleDataChange = (data) => {
    this.setState(data);
  };

  handleCodeChange = (callingCode, addressCountry, countryCode) => {
    this.setState({
      code: callingCode,
      codeCountry: countryCode,
      ...(addressCountry ? { addressCountry } : {}),
      number: '',
    });
  };

  handleCheckboxChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.checked;
    this.setState(change);
  };

  handleGenderChange = (event) => {
    this.setState({
      gender: event.target.value,
    });
  };

  handlePhoneVerification = () => {
    const { loading } = this.state;

    if (loading) return;

    this.setState({
      loading: true,
    });
    const formattedNumber = phoneUtil.formatPhoneNumberForRegistration(
      this.state.number,
      this.state.code,
      this.state.codeCountry,
    );
    const number =
      formattedNumber.country +
      formattedNumber.area +
      formattedNumber.prefix +
      formattedNumber.line;
    registrationApi
      .verifyPhoneNumber(number)
      .then((response) => {
        const verificationStatus = response.data;

        if (verificationStatus.status === 3) {
          this.setState({ invalidPhoneNumber: true });
          return;
        }

        if (
          verificationStatus.status === 0 &&
          verificationStatus.current_carrier.network_type !== 'mobile'
        ) {
          this.setState({ notConnectedPhoneNumber: true });
          return;
        }

        analyticsEventLogger.log(AnalyticsEvent.REGISTER_PHONE_VERIFICATION_SUCCESS);
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.REGISTER_PHONE_VERIFICATION_ERROR, {
          reason: error,
        });
      })
      .finally(() => {
        this.setState({ loading: false }, () => {
          this.handleNext();
        });
      });
  };

  handleNextPersonalVerification = (_, submitWithPhoneVerification = true) => {
    if (submitWithPhoneVerification) {
      this.handlePhoneVerification();
    } else {
      this.handleNext();
    }
  };

  doSubmit = (formState) => {
    const { loading } = this.state;

    if (loading) return;

    this.setState({ loading: true });
    const formRequest = this.bloc.__createAccountRequest({
      ...this.state,
      emergencyData: { ...formState },
    });

    const registrationType = sessionStorage.getItem('registrationType');

    this.bloc
      .__submitRegistration(
        formRequest,
        { ...this.state, emergency: { ...formState } },
        registrationType,
      )
      .then(() => {
        const { registrationSuccess, registrationException } = this.bloc.subject.value;
        this.setState({
          registrationSuccess,
          registrationException,
        });
      });
  };

  submitPharmacyRequest = () => {
    const pharmacy = this.createPharmacyRequest();

    pharmacyApi
      .setPharmacy(pharmacy)
      .then((result) => {
        analyticsEventLogger.log(AnalyticsEvent.PHARMACY_UPDATE_SUCCESS);
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.PHARMACY_UPDATE_ERROR, {
          reason: `${error}`,
        });
        notificationService.error(
          'Unable to update pharmacy information.' + ErrorMessage.CALL_SUPPORT,
        );
      });
  };

  createPharmacyRequest = () => {
    const { pharmacy } = this.state;

    if (pharmacy.contactInformation) {
      return pharmacy;
    }
    return {
      id: pharmacy.id,
      name: pharmacy.name,
      group: pharmacy.group,
      contactInformation: pharmacy.contactInformation,
    };
  };

  handleGoBackDialog = () => {
    this.setState({
      // activeStep: 0,
      notConnectedPhoneNumber: false,
      invalidPhoneNumber: false,
    });
  };

  handleContinueOnDialog = () => {
    this.setState((state, props) => ({
      activeStep: state.activeStep + 1,
      notConnectedPhoneNumber: false,
    }));
  };

  handleScanFinish = (data) => {
    if (data) {
      if (Object.keys(data.name).length > 0 && Object.keys(data.address).length > 0) {
        this.setState((prevState) => ({
          ...prevState,
          firstName:
            data?.name?.given.trim().length > 0 ? data?.name?.given.trim() : prevState.firstName,
          lastName:
            data?.name?.family.trim().length > 0 ? data?.name?.family.trim() : prevState.lastName,
          dateOfBirth: this.__getCorrectDob(data, prevState.dateOfBirth),
          addressLine1: data?.address?.line1?.trim() ?? prevState.addressLine1,
          addressLine2: data?.address?.line2?.trim() ?? prevState.addressLine2,
          addressAdministrativeArea:
            (data?.address?.administrativeArea || data?.address?.state) ??
            prevState.addressAdministrativeArea,
          addressCity: data?.address?.city ?? prevState.addressCity,
          addressPostcode:
            data?.address?.postcode?.length > 5
              ? ''
              : data?.address?.postcode ?? prevState.addressPostcode,
          idVerificationReference: data?.ref,
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          idVerificationReference: data?.ref,
        }));
      }
    }
    this.handleNext();
  };

  __getCorrectDob = (data, originalDob) => {
    let result;
    if (
      data?.dob === undefined ||
      data?.dob === '' ||
      (data?.dob === 'DATE_OF_BIRTH' && originalDob)
    ) {
      result = originalDob;
    } else {
      let extractedDate = data?.dob?.replace('-', '/');
      const dateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
      result = dateRegex.test(extractedDate) ? extractedDate : originalDob;
    }

    return result;
  };

  handleFrontScanCapture = (file) => {
    this.setState({ frontImageValue: { image: URL.createObjectURL(file), imageBlob: file } });
  };

  handleBackScanCapture = (file) => {
    this.setState({ backImageValue: { image: URL.createObjectURL(file), imageBlob: file } });
  };

  handleBackImageSubmit = () => {
    // api call
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append('front', this.state.frontImageValue.imageBlob);
    formData.append('back', this.state.backImageValue.imageBlob);
    registrationApi
      .analyzeDocument(formData)
      .then((r) => {
        this.handleScanFinish(r.data);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  insuranceComponentBack = () => {
    this.setState((ps) => ({
      ...ps,
      activeStep: ps.scanSkipped ? 0 : ps.activeStep - 1,
    }));
  };

  basicInformationBack = () => {
    this.setState((ps) => ({
      ...ps,
      activeStep: ps.activeStep - 1,
    }));
  };

  handlePreScan = (newState) => {
    this.setState(newState);
  };

  handleAddressSubmit = (newState) => {
    this.setState(newState, () => {
      this.handleNext();
    });
  };

  handleSkipInsurance = () => {
    this.handleNext();
  };

  isPrimaryCareRegistrationOnly = () => {
    const url = window.location.href;
    // Extract the domain name
    const domain = new URL(url).hostname;
    return (
      process.env.REACT_APP_DH_PRIMARY_CARE_REGISTRATION_ONLY_DOMAIN &&
      process.env.REACT_APP_DH_PRIMARY_CARE_REGISTRATION_ONLY_DOMAIN.includes(domain)
    );
  };

  getStepContent = (step) => {
    const isLoading = this.state.loading || authService.isLoading();
    const isWalkin = providerStorage.isWalkin();
    const isUrgentCare = sessionStorage.getItem('registrationType') === 'UC';

    if (isUrgentCare && step === 3) {
      this.handleSkipInsurance();
    }

    switch (step) {
      case 0:
        return (
          <IntroStep
            isLoading={isLoading}
            isWalkin={isWalkin}
            onSkip={() =>
              this.setState({
                scanSkipped: true,
                activeStep: isWalkin ? 9 : 3,
              })
            }
            userFirstName={this.state.firstName}
            onScanID={() =>
              this.setState((ps) => ({ scanSkipped: false, activeStep: ps.activeStep + 1 }))
            }
            existing={this.state.driversLicense && this.isPrimaryCareRegistrationOnly()}
          />
        );
      case 1:
        return (
          <FrontScan
            loading={isLoading}
            onBack={this.handleBack}
            fileName={'front.jpg'}
            imageValue={this.state.frontImageValue.image}
            onScanCapture={this.handleFrontScanCapture}
            heading={
              <FormattedMessage
                id="registration.scan.document.front.heading"
                defaultMessage="Step 1: Front side of the card"
              />
            }
            onImageSubmit={this.handleNext}
            submitLabel={
              <FormattedMessage
                id="registration.scan.document.front.submit.label"
                defaultMessage="Submit"
              />
            }
          />
        );
      case 2:
        return (
          <BackScan
            loading={isLoading}
            onBack={this.handleBack}
            fileName={'back.jpg'}
            frontImageValue={this.state.frontImageValue.image}
            imageValue={this.state.backImageValue.image}
            onScanCapture={this.handleBackScanCapture}
            heading={
              <FormattedMessage
                id="registration.scan.document.back.heading"
                defaultMessage="Step 2: Back side of the card"
              />
            }
            onImageSubmit={this.handleBackImageSubmit}
            submitLabel={
              <FormattedMessage
                id="registration.scan.document.back.submit.label"
                defaultMessage="Submit"
              />
            }
          />
        );
      case 3:
        return (
          <InsuranceComponent
            bloc={this.bloc}
            callback={this.handleNext}
            onBack={this.insuranceComponentBack}
            switchToSelfPay={() => ({})}
            handleSkipInsurance={this.handleSkipInsurance}
          />
        );
      case 4:
        return (
          <BasicInformation
            {...this.state}
            handleBack={this.basicInformationBack}
            handleNext={this.handleNextPersonalVerification}
            onCodeChange={this.handleCodeChange}
            handleTextChange={this.handleTextChange}
            handleGenderChange={this.handleGenderChange}
          />
        );
      case 5:
        return (
          <Address
            {...this.state}
            isLoading={isLoading}
            handleBack={this.handleBack}
            doSubmit={this.handleAddressSubmit}
            code={this.state.code}
            updateAddressChange={this.handleDataChange}
            additionalFields={[
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={this.state.acceptTerms}
                    onChange={this.handleCheckboxChange}
                    className={this.props.classes.checkBox}
                    name="acceptTerms"
                    color="primary"
                  />
                }
                label={
                  <React.Fragment>
                    <FormattedMessage
                      id="registration.user.consent"
                      defaultMessage="I acknowledge and consent to"
                    />
                    <br />
                    <FormattedMessage id="global.consent.link">
                      {(chunks) => (
                        <Link
                          rel="noopener noreferrer"
                          target="_blank"
                          href={chunks}
                          underline="hover"
                        >
                          <FormattedMessage
                            id="registration.user.tnc"
                            defaultMessage="The Terms of Use, Consent to Treatment, and Notice of Privacy Practices"
                          />
                        </Link>
                      )}
                    </FormattedMessage>
                  </React.Fragment>
                }
              />,
            ]}
          />
        );
      case 6:
        return (
          <EmergencyContact
            {...this.state}
            user={this.bloc.subject.value.user}
            handleBack={this.state.hideBackButton ? null : this.handleBack}
            handleSubmit={(formState) => {
              this.setState({ hideBackButton: true });
              this.doSubmit(formState);
            }}
          />
        );
      case 7:
        return (
          <IdentityVerificationRequired
            {...this.state}
            isWalkin={isWalkin}
            onNext={() => {
              this.setState({
                registrationException: true,
              });
            }}
            handleBack={() => {
              this.setState((state) => ({
                activeStep: 1,
              }));
            }}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  };

  render() {
    let {
      activeStep,
      registrationSuccess,
      registrationException,
      invalidPhoneNumber,
      notConnectedPhoneNumber,
      email,
    } = this.state;
    if (registrationException) {
      return <Redirect to={routeUtil.buildRegistrationOnlyResult('exception')} />;
    }
    if (registrationSuccess) {
      return <Redirect to={routeUtil.buildRegistrationOnlyResult('success')} />;
    }
    return (
      <>
        {this.getStepContent(activeStep)}
        <InvalidPhoneNumberModal open={invalidPhoneNumber} onGoBack={this.handleGoBackDialog} />
        <NotConnectedPhoneNumberModal
          open={notConnectedPhoneNumber}
          email={email}
          onContinue={this.handleContinueOnDialog}
          onGoBack={this.handleGoBackDialog}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PatientInformation);
