import React from 'react';

import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { appointmentApi } from '../../../utils/services/appointments.api';
import { notificationService } from '../../../utils/notification';
import { withRouter } from 'react-router-dom';
import PageContainer from '../../common/PageContainer';
import { ReactComponent as DirectionIcon } from '../../../assets/direction.svg';
import { Grid, Link, styled } from '@mui/material';
import { SetSession, uriStorage } from '../../../utils/storage';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { authService } from '../../../utils/auth';
import { BOOKING_LIST_ROUTE, routeUtil } from '../../../utils/route.name';
import { phoneUtil } from '../../../utils/phone';
import { dateUtil } from '../../../utils/date';
import { providerStorage } from '../../../utils/provider.qs';
import {
  CancellationBloc,
  CancellationBlocBlocEvent,
} from '../../shared/AppointmentCancelDialog/cancellation.bloc';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import ScrollableContainer from '../../common/ScrollableContainer';
import DecodedButton from '../../common/DecodedButton';
import DecodedComponent from '../../shared/DecodedComponent';
import { Bloc, BlocEvent } from './bloc';
import { AppointmentCard } from '../../shared/AppointmentCard';
import AppointmentCancelDialog from '../../shared/AppointmentCancelDialog';

const styles = (theme) => ({
  grid: {
    flex: '1',
    paddingTop: '2em',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '1em',
    marginLeft: '1em',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
    },
  },
  textItem: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
    },
  },
  button: {
    width: '100%',
    padding: '1em',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  checkinText: {
    fontWeight: 500,
    fontSize: 18,
    textAlign: 'center',
    color: '#71675E',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      paddingTop: '3em',
    },
  },
  estimationText: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
    color: '#71675E',
    paddingTop: '2.4em',
    paddingBottom: '3em',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      paddingBottom: '1.5em',
      paddingTop: 0,
    },
  },
  phoneNumberText: {
    color: '#71675E',
    fontWeight: '500',
    fontSize: 18,
    textAlign: 'center',
    paddingBottom: '2.3em',
    flexDirection: 'row',
    alignContent: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      paddingBottom: '3em',
    },
  },
  phoneNumber: {
    color: theme.palette.primary.main,
  },
  noteText: {
    fontWeight: '550',
    fontSize: 14,
    textAlign: 'center',
    color: '#1A0C00',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      marginTop: '4em',
    },
  },
  waitingTime: {
    fontWeight: '500',
    color: theme.palette.primary.main,
  },
  queueNumber: {
    fontWeight: '700',
    color: theme.palette.primary.main,
    fontSize: '10em',
  },
  directionLink: {
    fontSize: '1.2em',
    color: '#fffff',
  },
  directionLinkText: {
    fontSize: '1.3em',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  linkBox: {
    fontWeight: 700,
    textDecoration: 'none',
    paddingBottom: '5em',
    paddingTop: '3em',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '4em',
    },
  },
  icon: {
    width: '1.8em',
    paddingRight: '0.3em',
  },
  homeLink: {
    fontSize: '2em',
    fontWeight: '1',
  },
  progress: {
    color: '#E50B14',
  },
  cancelLinkText: {
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  getDirection: {
    flexDirection: 'row',
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  getDirectionIcon: {
    marginRight: 10,
    color: theme.palette.primary.main,
  },
  phoneNumberMargin: {
    margin: 0,
  },
});

const initState = {
  loading: false,
  checkinSuccess: false,
  checkinAvailable: false,
  queueNumber: '_',
  waitTime: 'unknown',
  provider: '',

  isCancelled: false,

  dialogOpen: false,
};

const H1TextTitle = styled(Typography, { variant: 'h1' })(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px !important',
  lineHeight: '24px',
  color: '#777777',
}));

const TextBody = styled(Typography, { variant: 'body' })(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px !important',
  lineHeight: '26px',
  color: '#777777',
}));

const TextBodyBoldSecondary = styled(Typography, { variant: 'body' })(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '24px !important',
  lineHeight: '32px',
  color: '#E50B14',
}));

class BookingStatus extends DecodedComponent {
  constructor(props) {
    super(props);
    this.state = initState;
    this.bloc = new Bloc(this._getCurrentAppointmentId());
    this.cancelBloc = new CancellationBloc();
    this._handleCancelEvent = this._handleCancelEvent.bind(this);
    analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS);
  }

  componentDidMount() {
    super.componentDidMount();
    this.cancelBlocEventSubscription = this.cancelBloc.subscribeToEvents(this._handleCancelEvent);
    uriStorage.setCurrentPath(this.props.match.url);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.cancelBlocEventSubscription?.unsubscribe();
  }

  __handleEvent = (event) => {
    const { type, data } = event;

    if (BlocEvent.NAVIGATE_TO === type) {
      this.props.history.push(data.url);
    }
  };

  _handleCancelEvent = (e) => {
    const { type } = e;
    const { isFullLogin } = this.state;

    switch (type) {
      case CancellationBlocBlocEvent.APPOINTMENT_CANCELLING:
        this.setState({
          loading: true,
        });
        break;
      case CancellationBlocBlocEvent.APPOINTMENT_CANCELLED_SUCCESS:
        this.setState({
          loading: false,
          isCancelled: true,
        });
        notificationService.success('You reservation has been cancelled.');
        if (!isFullLogin) {
          setTimeout(() => {
            this.props.history.replace('/');
          }, 5000);
        }
        break;
      case CancellationBlocBlocEvent.APPOINTMENT_CANCELLED_ERROR:
        this.setState({
          loading: false,
        });
        break;
      default:
        break;
    }
  };

  _getCurrentAppointmentId = () => {
    return this.props.match.params.appointmentId
      ? this.props.match.params.appointmentId
      : this.props.appointmentId;
  };

  _formatWaitTime = (num) => {
    const day = Math.floor(num / 1440); // 60*24
    const hour = Math.floor((num - day * 1440) / 60);
    const minute = Math.round(num % 60);

    const dayUnit = day > 1 ? 'days' : 'day';
    const hourUnit = hour > 1 ? 'hours' : 'hour';
    const minuteUnit = minute > 1 ? 'minutes' : 'minute';

    if (day > 0) {
      return `${day} ${dayUnit} ${hour}, ${hourUnit} and ${minute} ${minuteUnit}`;
    } else if (hour > 0) {
      return `${hour} ${hourUnit} and ${minute} ${minuteUnit}`;
    }
    return `${minute} ${minuteUnit}`;
  };

  handleButtonClick = () => {
    const { history } = this.props;
    let appointmentId = this._getCurrentAppointmentId();

    analyticsEventLogger.log(AnalyticsEvent.CHECKIN_START, { appointmentId: appointmentId });
    history.push(routeUtil.buildBookingIdentityDocument(appointmentId));
  };

  _canCheckin = () => {
    const { waitTime, checkinSuccess } = this.state;

    return !checkinSuccess && waitTime !== 'unknown';
  };

  _renderReservationTimeAndWait = () => {
    const { classes } = this.props;
    let { startTime, timezone } = this.state;

    return (
      <Typography variant="h4" className={classes.estimationText}>
        <FormattedMarkdown
          id={'checkin.reservation.time'}
          defaultMessage={'Estimated time for the reservation: '}
        />
        <br />
        <span className={classes.waitingTime}>
          {dateUtil.formatDateTimeWithTimeZone(startTime, timezone)}
        </span>
      </Typography>
    );
  };

  _renderReservationTime = () => {
    const { classes } = this.props;
    let { startTime, timezone } = this.state;

    return (
      <Typography variant="h4" className={classes.estimationText}>
        <FormattedMarkdown
          id={'checkin.reservation.time'}
          defaultMessage={'Estimated time for the reservation: '}
        />
        <br />
        <span className={classes.waitingTime}>
          {dateUtil.formatDateTimeWithTimeZone(startTime, timezone)}
        </span>
      </Typography>
    );
  };

  _renderTextContents = () => {
    const { waitTime, queueNumber } = this.state;

    if (waitTime > 0) {
      return (
        <React.Fragment>
          <TextBodyBoldSecondary>
            <FormattedMarkdown
              id={'bookingstatus.checkin.queue.message'}
              defaultMessage={`Your place in line: ${queueNumber}`}
            />
          </TextBodyBoldSecondary>
          <TextBody>
            <FormattedMarkdown
              id={'bookingstatus.checkin.waiting.time'}
              defaultMessage={`Estimated time to see the provider: ${this._formatWaitTime(
                waitTime,
              )}`}
            />
          </TextBody>
        </React.Fragment>
      );
    } else if (waitTime <= 0) {
      return (
        <>
          <TextBodyBoldSecondary>
            <FormattedMarkdown
              id={'bookingstatus.checkin.queue.message'}
              defaultMessage={`Your place in line: ${queueNumber}`}
            />
          </TextBodyBoldSecondary>
          <TextBody>
            {waitTime >= -10 ? (
              <FormattedMarkdown
                id={'bookingstatus.checkin.waiting.soon'}
                defaultMessage={'A provider will see you soon'}
              />
            ) : (
              <FormattedMarkdown
                id={'bookingstatus.checkin.waiting.late'}
                defaultMessage={
                  'We are experiencing a delay. A provider will see you as soon as one becomes available.'
                }
              />
            )}
          </TextBody>
        </>
      );
    } else if (waitTime === 'unknown') {
      return <React.Fragment></React.Fragment>;
    }
  };

  _createMapUrl = (url) => {
    let { providerDetails } = this.state;
    return providerDetails
      ? `${url || 'https://www.google.com/maps/dir/Current+Location/'}${providerDetails.lat},${
          providerDetails.lng
        }`
      : '';
  };

  _renderConfirmationDialog = (appointment) => {
    if (appointment.status === 'WAITING') {
      return (
        <>
          <AppointmentCancelDialog
            selectedAppointment={appointment.id}
            bloc={this.cancelBloc}
            onCancelSuccess={() => {
              authService.logout().then(() => {
                uriStorage.clearPath();
                providerStorage.clearProvider();
              });
            }}
          />
          <Grid item xs={12}>
            <TextBody
              sx={(theme) => ({
                lineHeight: '1',
                [theme.breakpoints.down('sm')]: {
                  textAlign: 'center',
                },
              })}
            >
              <FormattedMarkdown
                id={'appointment.status.change.text'}
                defaultMessage={'Do you need to cancel your booking?'}
              />
            </TextBody>
          </Grid>
          <Grid item xs={12}>
            <DecodedButton
              onClick={() => {
                this.cancelBloc.setCancelDialogOpen(true);
              }}
            >
              Cancel
            </DecodedButton>
          </Grid>
        </>
      );
    } else if (appointment.status === 'RESERVED') {
      return (
        <>
          <AppointmentCancelDialog
            selectedAppointment={appointment.id}
            bloc={this.cancelBloc}
            onCancelSuccess={() => {
              authService.logout().then(() => {
                uriStorage.clearPath();
                providerStorage.clearProvider();
              });
            }}
          />
          <Grid item xs={12}>
            <TextBody
              sx={(theme) => ({
                lineHeight: '1',
                [theme.breakpoints.down('sm')]: {
                  textAlign: 'center',
                },
              })}
            >
              <FormattedMarkdown
                id={'appointment.status.change.text'}
                defaultMessage={'Do you need to change your booking?'}
              />
            </TextBody>
          </Grid>
          <Grid item xs={12}>
            <DecodedButton
              onClick={() => {
                this.cancelBloc.setCancelDialogOpen(true);
              }}
            >
              Cancel
            </DecodedButton>
          </Grid>
          <Grid item xs={12}>
            <DecodedButton
              onClick={() => {
                this.bloc.rescheduleDraft();
              }}
            >
              Reschedule
            </DecodedButton>
          </Grid>
        </>
      );
    }

    return <></>;
  };

  render() {
    const { classes, history } = this.props;
    const {
      loading,
      startTime,
      appointment,
      provider,
      providerContactNumber,
      checkinSuccess,
      isCancelled,
      isFullLogin,
    } = this.state;

    if (!appointment) {
      return <PageContainer loading={true} hideBack={true}></PageContainer>;
    }

    if (isCancelled) {
      return this.__renderCancelled();
    }

    if (checkinSuccess) {
      return this.__renderCheckedIn();
    }

    return (
      <PageContainer
        loading={loading}
        paperBackground={true}
        hideBack={!isFullLogin}
        onBack={
          !isFullLogin
            ? undefined
            : () => {
                this.props.history.replace(BOOKING_LIST_ROUTE);
              }
        }
      >
        <ScrollableContainer applyFormat={true}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <H1TextTitle>
                <FormattedMarkdown
                  id={'appointment.status.title'}
                  defaultMessage={'YOUR UPCOMING RESERVATION'}
                />
              </H1TextTitle>
            </Grid>
            <Grid item xs={12}>
              {appointment && (
                <AppointmentCard
                  appointment={appointment}
                  organisation={provider}
                  visitTime={startTime}
                ></AppointmentCard>
              )}
            </Grid>
            {appointment && this.buildCheckinInformation(appointment, history)}
            {appointment && this._renderConfirmationDialog(appointment)}
            <Grid item xs={12}>
              <TextBody
                sx={(theme) => ({
                  lineHeight: '1',
                  [theme.breakpoints.down('sm')]: {
                    textAlign: 'center',
                  },
                })}
              >
                <FormattedMarkdown
                  id={'appointment.status.clinic.message'}
                  defaultMessage={'For any queries, please call the clinic:'}
                />
                <Link
                  sx={(theme) => ({
                    color: theme.palette.secondary.main,
                  })}
                  href={`tel:+1${phoneUtil.stripCharacters(providerContactNumber)}`}
                >
                  {providerContactNumber}
                </Link>
              </TextBody>
            </Grid>
            {!checkinSuccess && (
              <Grid
                sx={(theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  },
                })}
                item
                xs={12}
              >
                <Link
                  sx={(theme) => ({
                    fontWeight: '700',
                    fontSize: '18px !important',
                    lineHeight: '22px',
                    display: 'flex',
                    marginTop: '24px',
                    marginBottom: '24px',
                    color: theme.palette.primary.main,
                    [theme.breakpoints.down('sm')]: {
                      textAlign: 'center',
                    },
                    ['& p']: {
                      margin: '2px 0 0 0',
                    },
                  })}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={this._createMapUrl()}
                  className={classes.directionLinkText}
                  underline="hover"
                >
                  <DirectionIcon className={classes.getDirectionIcon} />
                  <FormattedMarkdown
                    id={'checkin.direction.button'}
                    defaultMessage={'Get Direction'}
                  />
                </Link>
              </Grid>
            )}
          </Grid>
        </ScrollableContainer>
      </PageContainer>
    );
  }

  buildCheckinInformation(appointment, history) {
    const { inClinic, checkinAvailable } = this.state;

    if (appointment?.status === 'WAITING') {
      return <></>;
    }

    return (
      <>
        {!inClinic && (
          <Grid item xs={12}>
            <H1TextTitle>
              <FormattedMarkdown
                id={'appointment.status.instructions.title'}
                defaultMessage={'HOW TO CHECK IN:'}
              />
            </H1TextTitle>
            <TextBody>
              <FormattedMarkdown
                id={'appointment.status.instructions.text'}
                defaultMessage={
                  '1. Arrive about 15 minutes early and bring your ID and Proof of Insurance.\n\n2. Scan QR Code in lobby to check-in.\n\n3. Take a seat, we’ll be with you soon!'
                }
              />
            </TextBody>
          </Grid>
        )}
        {checkinAvailable && inClinic && (
          <Grid item xs={12}>
            <H1TextTitle>
              <FormattedMarkdown
                id={'appointment.status.instruction.walkin.text'}
                defaultMessage={'READY TO CHECKIN:'}
              />
            </H1TextTitle>
            <DecodedButton onClick={this.handleButtonClick}>Check in</DecodedButton>
          </Grid>
        )}
      </>
    );
  }

  __renderCheckedIn = () => {
    const { loading, startTime, appointment, provider, providerContactNumber, isFullLogin } =
      this.state;

    return (
      <PageContainer
        loading={loading}
        paperBackground={true}
        hideBack={!isFullLogin}
        onBack={false}
      >
        <ScrollableContainer applyFormat={true}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <H1TextTitle>
                <FormattedMarkdown
                  id={'appointment.status.checkedin.title'}
                  defaultMessage={'YOUR RESERVATION'}
                />
              </H1TextTitle>
            </Grid>
            <Grid item xs={12}>
              {appointment && (
                <AppointmentCard
                  appointment={appointment}
                  organisation={provider}
                  visitTime={startTime}
                ></AppointmentCard>
              )}
            </Grid>
            <Grid item xs={12}>
              {appointment && this._renderTextContents()}
            </Grid>
            {appointment && this._renderConfirmationDialog(appointment)}
            <Grid item xs={12}>
              <TextBody
                sx={(theme) => ({
                  lineHeight: '1',
                  [theme.breakpoints.down('sm')]: {
                    textAlign: 'center',
                  },
                })}
              >
                <FormattedMarkdown
                  id={'appointment.status.clinic.message'}
                  defaultMessage={'For any queries, please call the clinic:'}
                />
                <Link
                  sx={(theme) => ({
                    color: theme.palette.secondary.main,
                  })}
                  href={`tel:+1${phoneUtil.stripCharacters(providerContactNumber)}`}
                >
                  {providerContactNumber}
                </Link>
              </TextBody>
            </Grid>
          </Grid>
        </ScrollableContainer>
      </PageContainer>
    );
  };

  __renderCancelled = () => {
    const { loading, startTime, appointment, provider, isFullLogin } = this.state;

    return (
      <PageContainer loading={loading} paperBackground={true} hideBack={true}>
        <ScrollableContainer applyFormat={true}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <H1TextTitle>
                <FormattedMarkdown
                  id={'appointment.status.cancelled.title'}
                  defaultMessage={'YOUR RESERVATION'}
                />
              </H1TextTitle>
            </Grid>
            <Grid item xs={12}>
              {appointment && (
                <AppointmentCard
                  appointment={appointment}
                  organisation={provider}
                  visitTime={startTime}
                ></AppointmentCard>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextBodyBoldSecondary>
                <FormattedMarkdown
                  id={'checkin.cancelled.message'}
                  defaultMessage={'Your reservation has been cancelled.'}
                />
              </TextBodyBoldSecondary>
            </Grid>
          </Grid>
        </ScrollableContainer>
      </PageContainer>
    );
  };
}

export default withStyles(styles)(withRouter(BookingStatus));
