import { Box, Card, CardContent, CardHeader, useTheme } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import * as React from 'react';
import { useState } from 'react';
import { providerUtil } from '../../../utils/provider';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { appointmentIntervalUtil } from '../../../utils/appointment';
import Typography from '@mui/material/Typography';
import { dateUtil } from '../../../utils/date';
import { logger } from '../../../utils/logging';
import {
  TextLargeStandard,
  TextMediumStandard,
  TextSmallStandard,
  TextStandard,
} from '../Typography';
import DecodedButton from '../../common/DecodedButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { PRE_AUTH_BOOKING_ROUTE, PRE_AUTH_QUINN_ROUTE } from '../../../utils/route.name';
import { is } from 'date-fns/locale';

const AltOrgCard = ({ organisation, bloc, pointTo, setSelected, isSelected }) => {
  const theme = useTheme();

  const [reservationSlot, setReservationSlot] = useState('Loading...');
  const [walkinSlot, setWalkinSlot] = useState('Loading...');
  const [firstAvailableDate, setFirstAvailableDate] = useState(new Date());
  const [firstAvailableCapacity, setFirstAvailableCapacity] = useState('not set');

  const history = useHistory();

  function notifyAvailable() {
    bloc.makeReservationAvailable();
  }

  React.useEffect(() => {
    const now = new Date();
    const tomorrow = dateUtil.nextStartOfDay(now);

    bloc.loadAvailability(now, tomorrow, organisation.id, bloc.service(), undefined, false).then(
      (schedulingIntervals) => {
        try {
          let firstSlot = appointmentIntervalUtil.findFirstAvailableSlotForDay(
            schedulingIntervals,
            now,
          );
          if (firstSlot) {
            notifyAvailable();
            setFirstAvailableDate(now);
            setFirstAvailableCapacity('');
            setReservationSlot(`TODAY ${dateUtil.formatTimeDisplay(firstSlot.start)}`);
            analyticsEventLogger.log(
              AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS,
              { schedule: `${firstSlot.hourDisplay}` },
            );
          } else {
            firstSlot = appointmentIntervalUtil.findFirstAvailableSlotForDay(
              schedulingIntervals,
              tomorrow,
            );

            if (firstSlot) {
              notifyAvailable();
              setFirstAvailableDate(tomorrow);
              setFirstAvailableCapacity('');

              setReservationSlot(`TOMORROW ${dateUtil.formatTimeDisplay(firstSlot.start)}`);
              analyticsEventLogger.log(
                AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS,
                { schedule: `TOMORROW ${firstSlot.hourDisplay}` },
              );
            } else {
              setFirstAvailableCapacity('full');
              setReservationSlot(`UNAVAILABLE`);
              analyticsEventLogger.log(
                AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS,
                { schedule: 'full' },
              );
            }
          }
        } catch (e) {
          setFirstAvailableDate(now);
          setReservationSlot('Unable to load');
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_ERROR, {
            error: e,
          });
          notifyAvailable();
        }
      },
      (reason) => {
        setReservationSlot('Unable to load');
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_ERROR, {
          error: reason,
        });
        bloc.makeReservationAvailable();
      },
    );

    bloc.loadAvailability(now, tomorrow, organisation.id, bloc.service(), undefined, true).then(
      (schedulingIntervals) => {
        let firstWalkinSlot = appointmentIntervalUtil.findFirstAvailableSlotForDay(
          schedulingIntervals,
          now,
        );

        if (firstWalkinSlot) {
          setWalkinSlot(`CURRENTLY AVAILABLE`);
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS, {
            schedule: `walkinavailable ${firstWalkinSlot.slots[0].display}`,
          });
        } else {
          setWalkinSlot('UNAVAILABLE');
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS, {
            schedule: 'walkinfull',
          });
        }
      },
      (reason) => {
        setWalkinSlot('Unable to load');
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_ERROR, {
          error: reason,
          schedule: 'walkin',
        });
      },
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClick = () => {
    setSelected({
      organisation,
      firstAvailableCapacity: firstAvailableCapacity,
      firstAvailableDate: firstAvailableDate,
    });

    //==> add loading state to button to show ✨ action ✨
    setTimeout(() => {
      history.push(PRE_AUTH_QUINN_ROUTE);
    }, 1000);
  };

  return (
    <Card
      sx={(theme) => ({
        boxShadow: isSelected
          ? '0px 0px 5px 2px rgba(0, 128, 128, 0.5)'
          : '0px 1px 3px 1px rgba(0, 0, 0, 0.3)',
        width: '100%',
        minWidth: '100%',
        maxWidth: '100%',
        minHeight: '280px',
        margin: '0',
        border: isSelected ? '1px solid rgba(0, 128, 128, 0.5)' : '0 solid #ffffff',
        borderRadius: '6px',
      })}
      variant="outlined"
    >
      <CardHeader
        title={
          <>
            <TextMediumStandard
              sx={{
                fontWeight: '800 !important',
                lineHeight: '18px',
                color: '#5C5D5F',
              }}
            >
              {organisation.name}
            </TextMediumStandard>
          </>
        }
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: '0 16px 16px 16px', 
        }}
      >
        <Box
          mb={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            minHeight: '36px',
          }}
        >
          <LocationOnIcon
            sx={{
              fontSize: '18px',
              color: theme.palette.accent.main,
            }}
            fontSize={'large'}
            color={'primary'}
          />

          <TextSmallStandard
            sx={{
              color: '#5C5D5F',
              paddingLeft: '10px',
            }}
          >
            {providerUtil.formatOrganisationAddress(organisation)}
          </TextSmallStandard>
        </Box>

        <Box
          mb={2}
          flexGrow={1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '-12px',
          }}
        >
          <QueryBuilderIcon
            sx={{
              fontSize: '18px',
              marginTop: '5px',
              color: theme.palette.accent.main,
            }}
            fontSize={'large'}
            color={'primary'}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '10px',

            }}
          >
            <TextLargeStandard
              style={{
                color: '#5C5D5F',
                marginBottom: '16px',
              }}
            >
              Walk-In availability:
            </TextLargeStandard>


            <TextMediumStandard
              sx={{
                fontWeight: 'bold',
                marginTop: '-20px',
                lineHeight: '24px',
                color: walkinSlot === 'UNAVAILABLE' ? 'red' : '#2D8088',
              }}
            >
              {walkinSlot || 'Loading'}
            </TextMediumStandard>

            <TextLargeStandard
              sx={{
                color: '#5C5D5F',
                marginBottom: '16px',
              }}
            >
              Next reserved availability:
            </TextLargeStandard>
            <TextMediumStandard
              sx={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                marginTop: '-20px',
                lineHeight: '24px',
                color: reservationSlot === 'UNAVAILABLE' ? 'red' : '#2D8088',
              }}
            >
              {reservationSlot || 'Loading'}
            </TextMediumStandard>
          </Box>
        </Box>
        {/*</div>*/}
        <Box mt="auto">
          {firstAvailableCapacity !== 'not set' && (
            <DecodedButton
              sx={{
                marginTop: 'auto',
              }}
              onClick={() => handleOnClick()}
              disabled={firstAvailableCapacity === 'full'}
            >
              {firstAvailableCapacity === 'full' ? 'NOT AVAILABLE' : 'MAKE A RESERVATION'}
            </DecodedButton>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default AltOrgCard;
