import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { IntlProvider } from 'react-intl';

import { Route, Switch, withRouter } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

import './App.css';

import Login from '../Login';
import Secure from '../Secure';

import { PrivateRoute } from '../../utils/protected.route';
import LinkHandler from '../Link';
import LocalNotifications from '../shared/Notifications';
import PostEncounter from '../Secure/VirtualClinic/PostEncounter';
import {
  INVALID_ROUTE,
  LINK_ROUTE,
  LOGIN_ROUTE,
  QUINN_TERMINATE_ROUTE,
  ROOT_ROUTE,
  VIRTUAL_CLINIC_POST_ENCOUNTER_ROUTE,
  KIOSK_ROUTE,
  WALKIN_ONLY,
  THANKYOU_ROUTE,
  PATIENT_REGISTRATION_ONLY_RESULT_ROUTE,
  EMPLOYER_OCC_ROUTE,
  PRE_AUTH_QUINN_ROUTE,
  PRE_AUTH_BOOKING_ROUTE,
  PRE_AUTH_URGENTCARE_ROUTING_ROUTE,
  TEST,
  OCCHEALTH_ROUTING_ROUTE,
} from '../../utils/route.name';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isAfter, isBefore, isValid, parse, subYears } from 'date-fns';
import { messages } from '../../utils/intl/messages';
import Loading from '../shared/Loading';
import { globalBloc } from '../global.bloc';
import PostTerminate from '../Secure/Assistant/PostTerminate';
import { userInfoUtil } from '../../utils/user';
import { getThemeBasedOnTenant } from './themes';
import Invalid from '../Invalid';
import KioskAdmin from '../KioskAdmin';
import WalkinNotice from '../WalkinNotice';
import ThankYou from '../ThankYou';
import RegistrationOnlyComplete from '../RegistrationOnlyComplete';
import AssistantChat from '../../components/Secure/Assistant';
import { Booking } from '../Booking/Booking';
import { authService } from '../../utils/auth';
import Employer from '../BookingPaymentMethod/Employer';
import { CheckSession, uriStorage } from '../../utils/storage';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import IdleTimer from 'react-idle-timer';
import { providerStorage } from '../../utils/provider.qs';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { logger } from '../../utils/logging';
import PreAuthUrgentCareRouting from '../UrgentCareRouting/PreAuthUrgentCareRouting';
import Test from './__tests__/Test';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import OccHealthRouting from '../OccHealthRouting';

const styles = {
  root: {
    display: 'flex',
    minHeight: '100%',
    width: '100%',
    maxHeight: '100%',
  },
};

const TIMEOUT = 60 * 15;
const KIOSK_TIMEOUT = 60 * 5;
const LEEWAY = 60;

class App extends Component {
  globalStateSubsciption;

  constructor(props) {
    super(props);
    this.state = {
      initialising: true,
      countdownSeconds: LEEWAY,
      idle: false,
      open: false,
      anonUser: true,
      logoutDialogOpen: false,
    };

    this.__watchGlobalState = this.__watchGlobalState.bind(this);
    this.idleTimer = null;
    this.countDownTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.onCountdown = this._countdown.bind(this);
    this.onCancelIdleTimeout = this._cancelIdleTimeout.bind(this);
    this.idleTimeout = providerStorage.isKiosk() ? KIOSK_TIMEOUT : TIMEOUT;
  }

  componentDidMount() {
    logger.debug('🌇 App component did mount**');

    // this._checkAndRedirect();

    if (!providerStorage.getCurrentProvider()) {
      this.setState({ anonUser: true });
    }

    if (!CheckSession('action', 'manage-appointments')) {
      sessionStorage.removeItem('user_token');
    }

    if (process.env.REACT_APP_HTTPS !== 'http') {
      window.location.protocol === 'http:' &&
        (window.location.href = window.location.href.replace(/^http:/, 'https:'));
    }

    this.globalStateSubsciption = globalBloc.subscribeToState(this.__watchGlobalState);

    ValidatorForm.addValidationRule('isDateValid', (value) => {
      const date = parse(value, 'MM/dd/yyyy', new Date());
      if (!isValid(date)) return false;

      const now = new Date();

      return isBefore(date, now) && isAfter(date, subYears(now, 130));
    });

    ValidatorForm.addValidationRule('isValidName', (value) => {
      return userInfoUtil.validName(value.trim());
    });
  }

  componentWillUnmount() {
    logger.debug('🎑 App -> componentWillUnmount');
    if (this.countDownTimer) {
      clearInterval(this.countDownTimer);
      this.countDownTimer = undefined;
    }
  }

  // _checkAndRedirect = () => {
  //   const currentURL = this.props.history.location.pathname;
  //   const user = firebase.auth().currentUser;

  //   if (
  //     currentURL === '/' &&
  //     !user &&
  //     !providerStorage.isKiosk() &&
  //     !providerStorage.isWalkin() &&
  //     !providerStorage.hasProvider()
  //   ) {
  //     logger.info('App -> _checkAndRedirect');
  //     this.props.history.push(PRE_AUTH_QUINN_ROUTE);
  //   }
  // };

  __watchGlobalState = (state) => {
    this.setState({
      ...state,
    });
  };

  _onAction(e) {}

  _onActive(e) {}

  _onIdle(e) {
    if (!this.countDownTimer) {
      this.countDownTimer = setInterval(this.onCountdown, 1000);
    }
    this.setState({
      idle: true,
    });
    analyticsEventLogger.log(AnalyticsEvent.IDLE_SHOW, {});
  }

  _countdown = () => {
    const { countdownSeconds } = this.state;

    if (countdownSeconds < 0) {
      this.doLogout();
    } else {
      this.setState({
        countdownSeconds: countdownSeconds - 1,
      });
    }
  };

  _cancelIdleTimeout = () => {
    this.idleTimer.reset();
    clearInterval(this.countDownTimer);
    this.countDownTimer = undefined;
    this.setState({
      idle: false,
      countdownSeconds: LEEWAY,
    });
  };

  doLogout = () => {
    authService.logout().then(() => {
      uriStorage.clearPath();
      providerStorage.clearProvider();
      window.location = '/';
    });
  };

  cancelLogoutDialog = () => {
    this.setState({ logoutDialogOpen: false });
  };

  openLogoutDialog = () => {
    this.setState({ logoutDialogOpen: true });
  };

  handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        providerStorage.clearProvider();
        this.setState({ logoutDialogOpen: false });
        this.props.history.push('/');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
        providerStorage.clearProvider();
        this.setState({ logoutDialogOpen: false });
        this.props.history.push('/');
      });
  };

  isPrimaryCareRegistrationOnly = () => {
    const url = window.location.href;
    // Extract the domain name
    const domain = new URL(url).hostname;
    return (
      process.env.REACT_APP_DH_PRIMARY_CARE_REGISTRATION_ONLY_DOMAIN &&
      process.env.REACT_APP_DH_PRIMARY_CARE_REGISTRATION_ONLY_DOMAIN.includes(domain)
    );
  };

  getAvailableRoutes = () => {
    if (this.isPrimaryCareRegistrationOnly()) {
      sessionStorage.setItem('action', 'registration.only');
      sessionStorage.setItem('registrationType', 'PC');

      return (
        <Switch>
          <Route path={INVALID_ROUTE} component={Invalid} />
          <Route path={THANKYOU_ROUTE} component={ThankYou} />
          <Route path={LOGIN_ROUTE} component={Login} />
          <Route
            path={PATIENT_REGISTRATION_ONLY_RESULT_ROUTE}
            component={RegistrationOnlyComplete}
          />
          <PrivateRoute path={ROOT_ROUTE} component={Secure} />
        </Switch>
      );
    }

    return (
      <Switch>
        <Route path={PRE_AUTH_QUINN_ROUTE} component={AssistantChat} />
        <Route path={PRE_AUTH_BOOKING_ROUTE} component={Booking} />
        <Route path={PRE_AUTH_URGENTCARE_ROUTING_ROUTE} component={PreAuthUrgentCareRouting} />
        <Route path={INVALID_ROUTE} component={Invalid} />
        <Route path={THANKYOU_ROUTE} component={ThankYou} />
        <Route path={KIOSK_ROUTE} component={KioskAdmin} />
        <Route path={LINK_ROUTE} component={LinkHandler} />
        <Route path={LOGIN_ROUTE} component={Login} />
        <Route path={VIRTUAL_CLINIC_POST_ENCOUNTER_ROUTE} component={PostEncounter} />
        <Route path={QUINN_TERMINATE_ROUTE} component={PostTerminate} />
        <Route path={WALKIN_ONLY} component={WalkinNotice} />
        <Route path={TEST} component={Test} />
        <Route path={OCCHEALTH_ROUTING_ROUTE} component={OccHealthRouting} />
        <Route path={PATIENT_REGISTRATION_ONLY_RESULT_ROUTE} component={RegistrationOnlyComplete} />
        <Route path={EMPLOYER_OCC_ROUTE} component={Employer} />
        <PrivateRoute path={ROOT_ROUTE} component={Secure} />
      </Switch>
    );
  };

  render() {
    const { classes } = this.props;
    const { initialising, idle, countdownSeconds, loading, logoutDialogOpen } = this.state;
    const { kioskRestart } = globalBloc.subject.value;

    if (initialising) {
      return (
        <>
          <Loading />
        </>
      );
    }

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={getThemeBasedOnTenant()}>
          <IntlProvider locale="en" messages={messages}>
            <div className={classes.root}>
              <CssBaseline />
              {this.getAvailableRoutes()}
              <IdleTimer
                ref={(ref) => {
                  this.idleTimer = ref;
                }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * this.idleTimeout}
              />
              {!this.state.loading && providerStorage.isKiosk() && kioskRestart && (
                <Button
                  variant="text"
                  color="error"
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  disableElevation
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  onClick={this.openLogoutDialog}
                >
                  <RestartAltIcon fontSize="large" />
                  Restart
                </Button>
              )}
              <Dialog
                open={idle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{'Session Expiring'}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    You have {countdownSeconds > 0 ? countdownSeconds : 0} second(s) to extend your
                    session.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    disabled={countdownSeconds <= 0}
                    onClick={this.onCancelIdleTimeout}
                    variant="contained"
                    color="primary"
                    autoFocus
                  >
                    Extend
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={logoutDialogOpen}
                onClose={this.cancelLogoutDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {'Are you sure you want to end the Check-In process and Log Out?'}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={this.handleLogout} variant="contained" color="error" autoFocus>
                    YES
                  </Button>
                  <Button
                    onClick={this.cancelLogoutDialog}
                    variant="outlined"
                    color="primary"
                    autoFocus
                  >
                    NO
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <LocalNotifications />
          </IntlProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default withStyles(styles)(withRouter(App));
