import React from 'react';

import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { Box, FormControlLabel, Grid } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { FormattedMessage } from 'react-intl';
import Link from '@mui/material/Link';
import PageContainer from '../../common/PageContainer';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { Checkbox } from '../../shared/components/Checkbox';
import { withTheme } from '@mui/styles';
import ScrollableContainer, { PageContent, PageFooter } from '../../common/ScrollableContainer';
import { providerStorage } from '../../../utils/provider.qs';
import { H2TextTitleContent, H2TextTitleSecondaryContent } from '../../shared/Typography';

import QRCode from 'react-qr-code';
import DecodedButton, { DecodedSubmitButton } from '../../common/DecodedButton';
import DecodedComponent from '../../shared/DecodedComponent';
import { Bloc } from './bloc';
import OrganisationCard from '../../shared/OrganisationCard';
import { authService } from '../../../utils/auth';
import { QUINN_ROUTE } from '../../../utils/route.name';
import { SetSession } from '../../../utils/storage';
import { logger } from '../../../utils/logging';
import AltOrgSelector from '../../Booking/components/AltOrgSelector';
import { globalBloc } from '../../global.bloc';

const GridItem = (props) => <Grid {...props} item />;

const styles = (theme) => ({
  grid: {
    flex: '1',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textItem: {
    flex: '1',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    textAlign: 'left',
    paddingTop: '3em',
    width: '100%',
    flex: '1',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2em',
    },
  },
  amount: {
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '5.6em',
    lineHeight: '1.214',
    color: '#0F0F0F',
    marginBottom: '0.44643em',
  },
  confirmation: {
    width: '100%',
    lineHeight: '1.75',
    color: '#575757',
    paddingTop: '0.5em',
    textAlign: 'center',
    marginBottom: '1em',
    fontWeight: '500',
    fontSize: '2em',
    [theme.breakpoints.down('sm')]: {
      fontSize: 'unset',
    },
  },
  opening: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    width: '100%',
    lineHeight: '1.75',
    paddingTop: '0.5em',
    textAlign: 'left',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  kioskOpening: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    lineHeight: '1.75',
    paddingTop: '0.5em',
    textAlign: 'left',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  instructionList: {
    width: '100%',
    lineHeight: '1.75',
    fontWeight: '500',
    color: '#575757',
    paddingTop: '2.8em',
    paddingBottom: '2.2em',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0em',
      paddingBottom: '0.5em',
    },
  },
  list: {
    width: '100%',
    lineHeight: '1.75',
    fontWeight: '500',
    color: '#575757',
    paddingTop: '0.5em',
    textAlign: 'left',
  },
  order: {
    fontSize: '1.5em',
    color: theme.palette.primary.main,
    paddingRight: '0.8em',
    fontWeight: '600',
  },
  warning: {
    width: '100%',
    lineHeight: '1.75',
    color: '#1A0C00',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  button: {
    width: '100%',
    paddingTop: '2.5em',
    paddingBottom: '2.5em',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingTop: '1.5em',
      paddingBottom: '1.5em',
    },
  },
  checkBox: {
    transform: 'scale(1.5)',
    marginRight: '1em',
    marginLeft: '0.5em',
  },
  locationTerm: {
    color: theme.palette.primary.main,
  },
});

class Welcome extends DecodedComponent {
  constructor(props) {
    super(props);

    const queryParams = new URLSearchParams(props.location.search);

    const isKiosk = providerStorage.isKiosk();
    const isWalkin = queryParams.has('provider') || providerStorage.isWalkin();
    const isLoggedIn = authService.getUser();

    this.bloc = new Bloc({
      isKiosk: isKiosk,
      isWalkin: isWalkin,
      isLoggedIn: isLoggedIn,
      showWalkin: isWalkin,
      onNext: this.props.handleNext,
      props: props,
    });

    this.state = {
      initialising: true,
      showAvailability: true,
      isWalkin: isWalkin,
      isKiosk: isKiosk,
      isLoggedIn: isLoggedIn,
    };
  }

  componentDidMount() {
    logger.debug('Welcome -> componentDidMount', this.state.firstWalkinSlot);
    super.componentDidMount();
    this.bloc.initialise();
    globalBloc.updateGlobalBloc({ kioskRestart: false });
  }

  render() {
    let {
      classes,
      acceptLocationTerm,
      handleNext,
      handleCheckboxChange,
      loading,
      location,
      theme,
    } = this.props;

    let {
      isWalkin,
      isKiosk,
      isLoggedIn,
      initialising,
      showAvailability,
      showWalkin,
      showWalkinTime,
      firstWalkinSlot,
      organisations,
    } = this.state;

    const queryParams = new URLSearchParams(location.search);

    const showCheckbox = !isKiosk && (location.search === '' || !queryParams.has('provider'));

    if (initialising) {
      return (
        <PageContainer isEnd={true} loading={true}>
          <ScrollableContainer applyFormat={true}></ScrollableContainer>
        </PageContainer>
      );
    }

    if (showWalkin) {
      return (
        <PageContainer isEnd={true} loading={false}>
          <ScrollableContainer applyFormat={true}>
            {this.__renderWalkinSelection(firstWalkinSlot, isKiosk)}
          </ScrollableContainer>
        </PageContainer>
      );
    }

    if (showWalkinTime) {
      return (
        <PageContainer isEnd={true} loading={false} onBack={() => this.bloc.showWalkin()}>
          <ScrollableContainer applyFormat={true}>
            {this.__renderWalkinTime(firstWalkinSlot)}
          </ScrollableContainer>
        </PageContainer>
      );
    }

    if (showAvailability && organisations.length !== 0) {
      return (
        <PageContainer
          isEnd={true}
          loading={false}
          onBack={
            !isWalkin
              ? undefined
              : () => {
                  this.bloc.showWalkin();
                }
          }
        >
          {this.__renderMap(organisations)}
        </PageContainer>
      );
    }
    return (
      <PageContainer isEnd={true} loading={loading}>
        <ScrollableContainer applyFormat={true}>
          <PageContent>
            <H2TextTitleContent className={isKiosk ? classes.kioskOpening : classes.opening}>
              <FormattedMarkdown
                id="welcome.bubble"
                defaultMessage="In just a few steps, we can help you find the medical care you need."
              />
            </H2TextTitleContent>
            {isKiosk ? this.getKioskWelcome(classes) : this.getStandardWelcome(classes)}
          </PageContent>
          <PageFooter>
            <ValidatorForm autoComplete="off" onSubmit={handleNext} className={classes.form}>
              {showCheckbox ? (
                <FormControlLabel
                  style={{ fontSize: '1.2em', textAlign: 'left' }}
                  control={
                    <Checkbox
                      required
                      checked={acceptLocationTerm}
                      onChange={handleCheckboxChange}
                      name="acceptLocationTerm"
                      color="primary"
                      className={classes.checkBox}
                    />
                  }
                  label={
                    <div className={classes.locationTerm}>
                      <FormattedMarkdown
                        id="welcome.location.confirmation"
                        defaultMessage="Please confirm that you will be physically located in the state of California at the time of your visit."
                      />
                    </div>
                  }
                />
              ) : null}

              <div className={classes.button}>
                <DecodedSubmitButton id={'lets-start-btn'}>Let's start!</DecodedSubmitButton>
              </div>

              <div className={classes.warning}>
                <Typography
                  variant="body1"
                  component={'div'}
                  sx={{
                    color: theme.palette.accent.main,
                  }}
                >
                  <FormattedMarkdown
                    id="global.instruction.emergency"
                    defaultMessage="In case of a medical emergency, call 911 immediately."
                  />
                </Typography>
              </div>
            </ValidatorForm>
          </PageFooter>
        </ScrollableContainer>
      </PageContainer>
    );
  }

  __renderWalkinSelection = (firstWalkinSlot, isKiosk) => {
    return (
      <Grid sx={{ paddingBottom: '24px' }} spacing={2} container>
        <Grid item xs={12}>
          <DecodedButton
            sx={{ paddingBottom: '20px', paddingTop: '20px' }}
            onClick={() => {
              this.bloc.setCheckin();
              this.props.handleNext();
            }}
          >
            Already Reserved Your Place Online?
            <br />
            CLICK HERE to CHECK IN.
          </DecodedButton>
        </Grid>
        <Grid item xs={12}>
          <DecodedButton
            sx={{ paddingBottom: '20px', paddingTop: '20px' }}
            onClick={() => {
              this.bloc.unsetCheckin();
              if (firstWalkinSlot) {
                this.bloc.showWalkinTime();
              } else {
                this.bloc.hideWalkin();
              }
            }}
          >
            Walk-In Patients
            <br />
            CLICK HERE to GET IN LINE
          </DecodedButton>
        </Grid>
        <H2TextTitleSecondaryContent component={GridItem}>
          <FormattedMarkdown
            id="interaction.appointment.walkin.selection.alert"
            defaultMessage={`**If you are experiencing any of the following please see the Front Desk:**\n\n * **Breathing/Airway Distress**\n * **Current Chest Pain**\n * **Concern for Stroke**\n * **Active Bleeding**\n * **Severe Pain**\n * **Broken Bone or Fracture Concern**\n * **Seizure**\n * **Unable to Walk**\n`}
          />
        </H2TextTitleSecondaryContent>
      </Grid>
    );
  };

  __renderWalkinTime = (firstWalkinSlot) => {
    return (
      <Grid sx={{ paddingBottom: '24px' }} spacing={2} container>
        <H2TextTitleContent component={GridItem}>
          <FormattedMarkdown
            id={`interaction.appointment.walkin${
              firstWalkinSlot.isTomorrow ? '.nextday' : ''
            }.confirmation.message3`}
            defaultMessage={`**The next approximate available time is ${
              firstWalkinSlot.isTomorrow ? 'tomorrow' : 'today'
            } at: ${firstWalkinSlot.slots[0].display}**`}
          />
        </H2TextTitleContent>
        <Grid item xs={12}>
          <DecodedButton
            onClick={() => {
              this.props.handleNext();
            }}
          >
            CLICK HERE to GET IN LINE
          </DecodedButton>
        </Grid>
        <H2TextTitleSecondaryContent component={GridItem}>
          <FormattedMarkdown
            id="interaction.appointment.walkin.full.message2"
            defaultMessage="**Please go to the Front Desk if you are experiencing any of the following symptoms including but not limited to Chest Pain, Shortness of Breath, possible Stroke have an open Wound or Laceration, or a possible Broken Bone.**"
          />
        </H2TextTitleSecondaryContent>
      </Grid>
    );
  };

  __renderMap = (organisations) => {
    const { isWalkin, reservationAvailable, switchAvailable } = this.state;

    if (isWalkin) {
      const otherLocations = organisations.filter(
        (organisation) => organisation.id !== this.bloc.walkinLocation(),
      );

      return (
        <ScrollableContainer applyFormat={true}>
          <Grid sx={{ paddingBottom: '24px' }} spacing={2} container>
            <Grid item direction="column">
              <H2TextTitleContent component={GridItem}>
                <FormattedMarkdown
                  id="interaction.appointment.walkin.full.message1"
                  defaultMessage={`**We have reached our patient capacity for the today. We apologize for the inconvenience.**`}
                />
              </H2TextTitleContent>
              <H2TextTitleSecondaryContent component={GridItem}>
                <FormattedMarkdown
                  id="interaction.appointment.walkin.full.message2"
                  defaultMessage={`**Please go to the Front Desk if you are experiencing any of the following symptoms including but not limited to Chest Pain, Shortness of Breath, possible Stroke have an open Wound or Laceration, or a possible Broken Bone.**`}
                />
              </H2TextTitleSecondaryContent>
            </Grid>
            <Grid item xs={12}>
              <DecodedButton
                onClick={() => {
                  providerStorage.clearProviderOnly(); //TODO WHY REMOVE?
                  window.location = '/';
                }}
              >
                Click Here to View Availability at Our Other Clinics
              </DecodedButton>
            </Grid>
          </Grid>
        </ScrollableContainer>
      );
    } else {
      return (
        <>
          <AltOrgSelector
            organisations={organisations}
            bloc={this.bloc}
            isSelected={true}
            //  onClick={() => {}} //==> needs to send to chat after selection
          />
        </>
      );
    }
  };

  getKioskWelcome(classes) {
    const { history } = this.props;

    return (
      <>
        <Box sx={{ paddingBottom: '12px' }}>
          <H2TextTitleContent>
            <FormattedMarkdown
              id={`login.welcome.kiosk.message`}
              defaultMessage={`Please scan the QR code with your mobile device or select 'Let's Start' to continue.\n\n `}
            />
          </H2TextTitleContent>
        </Box>
        <QRCode
          size={200}
          value={`${window.location.protocol}//${
            window.location.host
          }/?provider=${providerStorage.getCurrentProvider()}`}
        />
      </>
    );
  }

  getStandardWelcome(classes) {
    return (
      <>
        <div>
          <Typography variant="body2" component={'div'} className={classes.confirmation}>
            <FormattedMessage id="welcome.instruction.note" defaultMessage=" " />

            <FormattedMessage id="welcome.instruction.link.ref">
              {(refChunks) =>
                refChunks[0] !== '_' ? (
                  <Link
                    rel="noopener noreferrer"
                    target="_blank"
                    href={refChunks}
                    underline="hover"
                  >
                    <FormattedMessage id="welcome.instruction.link.text">
                      {(text) => (text[0] !== '_' ? text : null)}
                    </FormattedMessage>
                  </Link>
                ) : null
              }
            </FormattedMessage>

            <FormattedMessage id="welcome.instruction.note.cont">
              {(text) => (text[0] !== '_' ? text : null)}
            </FormattedMessage>
          </Typography>
        </div>

        <FormattedMessage
          id="welcome.instruction.guide"
          defaultMessage="The process to book an urgent care visit takes about 5 minutes:"
        >
          {(text) =>
            text[0] !== ' ' &&
            text[0] !== '_' && (
              <React.Fragment>
                <div className={classes.instructionList}>
                  <Typography variant="body2" component={'div'}>
                    {text[0]}
                  </Typography>
                </div>

                <FormattedMessage
                  id="welcome.instruction.one"
                  defaultMessage="Complete a basic registration."
                >
                  {(text) =>
                    text[0] !== ' ' &&
                    text[0] !== '_' && (
                      <div className={classes.list}>
                        <Typography variant="body2" component={'div'}>
                          <span className={classes.order}>1</span>
                          {text[0]}
                        </Typography>
                      </div>
                    )
                  }
                </FormattedMessage>

                <FormattedMessage
                  id="welcome.instruction.two"
                  defaultMessage="Tell me why you’d like to be seen."
                >
                  {(text) =>
                    text[0] !== ' ' &&
                    text[0] !== '_' && (
                      <div className={classes.list}>
                        <Typography variant="body2" component={'div'}>
                          <span className={classes.order}>2</span>
                          {text[0]}
                        </Typography>
                      </div>
                    )
                  }
                </FormattedMessage>

                <FormattedMessage
                  id="welcome.instruction.three"
                  defaultMessage="Choose a visit location and time."
                >
                  {(text) =>
                    text[0] !== ' ' &&
                    text[0] !== '_' && (
                      <div className={classes.list}>
                        <Typography variant="body2" component={'div'}>
                          <span className={classes.order}>3</span>
                          {text[0]}
                        </Typography>
                      </div>
                    )
                  }
                </FormattedMessage>
              </React.Fragment>
            )
          }
        </FormattedMessage>
      </>
    );
  }
}

export default withTheme(withStyles(styles)(withRouter(Welcome)));
