import axios from 'axios';
import { VERSION } from '../version';
import { tenantUtil } from '../tenant';
import { useEffect, useState } from 'react';
import retry from '../retry';
import { phoneUtil } from '../phone';

class RegistrationApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';

    this.instance = axios.create();
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers['X-DH-source'] = `Consumer Webapp`;
        config.headers['X-DH-version'] = VERSION;
        config.headers['Content-Type'] = 'application/json; version=0.1.0';
        config.headers['Authorization'] = `Bearer ${tenantUtil.tenantToken()}`; //TODO MAKE SURE THIS IS CORRECT
        config.headers['X-API-KEY'] = process.env.REACT_APP_DH_API_KEY;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  register(form) {
    return retry(() => axios.post(`${this.baseUrl}/api/accounts/me/_register`, form));
  }

  registerExternal(data) {
    const request = {
      command: 'register_patient',
      request: data,
    };

    return retry(() => axios.post(`${this.baseUrl}/api/workflow/_command`, request));
  }

  match_patient(data) {
    const request = {
      command: 'match_patient',
      request: {
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: data.dateOfBirth,
        number: phoneUtil.stripCharacters(data.number),
        registrationType: data.registrationType,
      },
    };

    return retry(() => axios.post(`${this.baseUrl}/api/workflow/_command`, request));
  }

  analyzeDocument(form) {
    return retry(() => this.instance.post(`${this.baseUrl}/api/accounts/me/_validate_id`, form));
  }

  verifyPhoneNumber(phone_number) {
    return this.instance.post(`${this.baseUrl}/api/utilities/_check_contact_number`, {
      phone_number,
    });
  }

  getRaceCodesets() {
    const $this = this;
    return () => $this.instance.get(`${this.baseUrl}/api/codesets/race`);
  }

  getEthnicCodesets() {
    const $this = this;
    return () => $this.instance.get(`${this.baseUrl}/api/codesets/ethnicity`);
  }

  getMarketingChannelCodesets() {
    const $this = this;
    return () => $this.instance.get(`${this.baseUrl}/api/codesets/marketing_channel`);
  }

  getGuarantorRelationshipCodesets() {
    const $this = this;
    return () => $this.instance.get(`${this.baseUrl}/api/codesets/guarantor_relationship`);
  }

  getEmergencyRelationshipCodesets() {
    const $this = this;
    return () => $this.instance.get(`${this.baseUrl}/api/codesets/emergency_relationship`);
  }

  getPolicyHolderRelationshipCodesets() {
    return this.instance.get(`${this.baseUrl}/api/codesets/guarantor_relationship`);
  }

  getPharmacies() {
    const $this = this;
    return () => $this.instance.get(`${this.baseUrl}/api/pharmacies`);
  }
}

export const registrationApi = new RegistrationApi();

const createHookToLoadData = (callback, initialValue) => {
  return () => {
    const [data, setData] = useState(initialValue);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      let unMount = false;
      const fetchData = async () => {
        const result = await callback();
        if (!unMount) {
          setData(result.data.items);
          setLoaded(true);
        }
      };
      fetchData();
      return () => {
        unMount = true;
      };
    }, []);

    return [data, loaded];
  };
};

export const useRaceCodesets = createHookToLoadData(registrationApi.getRaceCodesets(), []);

export const useEthnicCodesets = createHookToLoadData(registrationApi.getEthnicCodesets(), []);

export const useMarketingChannelCodesets = createHookToLoadData(
  registrationApi.getMarketingChannelCodesets(),
  [],
);

export const useGuarantorCodesets = createHookToLoadData(
  registrationApi.getGuarantorRelationshipCodesets(),
  [],
);

export const useEmergencyCodesets = createHookToLoadData(
  registrationApi.getEmergencyRelationshipCodesets(),
  [],
);

export const usePharmacies = createHookToLoadData(registrationApi.getPharmacies(), []);
