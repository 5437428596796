import * as React from 'react';
import { AddressForm, FormattedMarkdown } from '@decodedhealth/react-library';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PageContainer from '../../common/PageContainer';
import ChatBubble from '../../shared/ChatBubble';
import {
  ScrollableBox,
  ScrollableQuinnContainer,
  ScrollableWrapper,
} from '../../common/ScrollableContainer';
import { TextH5Standard } from '../../shared/Typography';
import { useEffect, useState } from 'react';
import { logger } from '../../../utils/logging';

const useStyles = makeStyles({
  title: {
    paddingTop: '1em',
    color: '#FF7700',
  },
});

const Address = ({
  isLoading,
  doSubmit,
  handleBack,
  additionalFields,
  code,
  updateAddressChange,
  readOnlyInit = true,
  setDirty,
  ...rest
}) => {
  const classes = useStyles();

  const [readOnly, setReadOnly] = useState(true);
  const [loading, setLoading] = useState(true);

  const checkProps = () => {
    const propsArray = [
      rest.addressLine1,
      rest.addressAdministrativeArea,
      rest.addressCity,
      rest.addressPostcode,
      rest.addressCountry,
    ];

    const isAnyPropEmpty = propsArray.some((prop) => prop == null || prop === '');

    if (isAnyPropEmpty) {
      setReadOnly(false);
      setLoading(false);
      setDirty();
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkProps();
  }, [
    rest.addressLine1,
    rest.addressAdministrativeArea,
    rest.addressCity,
    rest.addressPostcode,
    rest.addressCountry,
  ]);

  return (
    <PageContainer loading={loading} onBack={handleBack}>
      {loading ? (
        <ScrollableQuinnContainer
          messageId="registration.user.bubble.loading.readonly"
          message={'Loading your address information.'}
        ></ScrollableQuinnContainer>
      ) : (
        <ScrollableQuinnContainer
          messageId="registration.user.bubble.two"
          message="Please enter or verify the PATIENT information below and click NEXT."
        >
          <AddressForm
            enableLine2OptionalField
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
            updateAddressData={updateAddressChange}
            addressDefaultvalue={{
              addressLine1: rest.addressLine1,
              addressLine2: rest.addressLine2,
              addressAdministrativeArea: rest.addressAdministrativeArea,
              addressCity: rest.addressCity,
              addressPostcode: rest.addressPostcode,
              addressCountry: rest.addressCountry,
            }}
            heading={
              <TextH5Standard orange sx={{ paddingTop: '1em' }}>
                <FormattedMarkdown
                  id="registration.user.step.two"
                  defaultMessage="2 of 2 Personal Information"
                />
              </TextH5Standard>
            }
            additionalFields={additionalFields}
            isLoading={isLoading}
            doSubmit={doSubmit}
            readOnlyInit={readOnly}
            setDirty={setDirty}
          />
        </ScrollableQuinnContainer>
      )}
    </PageContainer>
  );
};

export default Address;
