import { notificationService } from '../../../utils/notification';
import { userInfoUtil } from '../../../utils/user';
import { phoneUtil } from '../../../utils/phone';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { Bloc } from '../bloc';
import * as rxjs from 'rxjs';

const initial = {
  initialised: false,
  insuranceCardData: {},
};

export class InsuranceBloc extends Bloc {
  callback;

  appointmentApi;
  extensionApi;
  codesetApi;
  insuranceApi;
  paymentApi;
  analyticsEventLogger;
  notificationService;
  AnalyticsEvent;

  constructor(props, callback, switchToSelfPay, onBack, handleSkipInsurance) {
    super(props);
    this.codesetApi = props.codesetApi;
    this.appointmentApi = props.appointmentApi;
    this.extensionApi = props.extensionApi;
    this.insuranceApi = props.insuranceApi;
    this.paymentApi = props.paymentApi;
    this.isKiosk = props.isKiosk;
    this.logoObject = props.logoObject;
    this.analyticsEventLogger = analyticsEventLogger;
    this.notificationService = notificationService;
    this.AnalyticsEvent = AnalyticsEvent;

    this.subject = new rxjs.BehaviorSubject({
      ...initial,
      ...props,
    });

    this.events = new rxjs.Subject();

    this.callback = callback;
    this.switchToSelfPay = switchToSelfPay;
    this.onBack = onBack;
    this.handleSkipInsurance = handleSkipInsurance;
  }

  initialise = (isInternal, insurances) => {
    const promises = isInternal
      ? [
          this.codesetApi.getPolicyHolderRelationshipCodesets(),
          this.insuranceApi.searchPlan({ strategy: 'all_plans' }),
          this.paymentApi.getPaymentMethods(),
        ]
      : [
          this.codesetApi.getPolicyHolderRelationshipCodesets(),
          this.insuranceApi.searchPlan({ strategy: 'all_plans' }),
          Promise.resolve({ data: { items: insurances, page: { size: insurances.length } } }),
        ];
    Promise.all(promises)
      .then(
        (result) => {
          const insuranceResult = result[1];

          /* insurance */
          let availablePlansSearchIndex = [];
          insuranceResult.data.items.forEach((_insurance) => {
            for (let indexItem of availablePlansSearchIndex) {
              if (indexItem['plan_name'].toLowerCase() === _insurance['plan_name'].toLowerCase()) {
                return;
              }
            }

            availablePlansSearchIndex.push({ plan_name: _insurance['plan_name'] });
          });

          const availablePlans = insuranceResult.data.items;

          availablePlansSearchIndex.sort((a, b) => {
            return a['plan_name'].localeCompare(b['plan_name']);
          });

          /* Payment methods */
          const paymentMethodResult = result[2];

          let insurances = [];
          let deletedInsurance = [];

          if (paymentMethodResult.data.page.size > 0) {
            insurances = paymentMethodResult.data.items
              .filter((item) => item.type.code === '1')
              .sort((a, b) => {
                if (a.primary === 1 && b.primary === 0) {
                  return -1;
                } else if (a.primary === 0 && b.primary === 1) {
                  return 1;
                }

                if (a.order > b.order) {
                  return 1;
                }

                if (a.order < b.order) {
                  return -1;
                }

                return 0;
              });
          }

          this.__updateSubject({
            availablePlans: availablePlans,
            availablePlansSearchIndex: availablePlansSearchIndex,
            relationshipCodes: result[0].data.items,
            original: [...insurances],
            edit: insurances.length === 0,
            insurances: insurances,
            deletedInsurance: deletedInsurance,
          });
        },
        (reason) => {
          this.__updateSubject({ error: true });
          notificationService.httpError(reason);
        },
      )
      .finally(() => {
        this.__updateSubject({ initialised: true, isSelfPay: false });
      });
  };

  appendInsurance = (insurance) => {
    let { insurances } = this.subject.value;

    insurance.dirty = true;

    let policyHolder = [];

    if (insurance.policyHolder.relationship !== 'SELF') {
      let phoneNumberParts = phoneUtil.getNumberParts(insurance.policyHolder.phoneNumber);

      policyHolder.push({
        name: {
          given: insurance.policyHolder.firstName,
          family: insurance.policyHolder.lastName,
        },
        role: 'POLICY_HOLDER',
        relationship: {
          system: 'decoded/person/relationship',
          code: 'code',
          value: insurance.policyHolder.relationship,
        },
        dob: userInfoUtil.formatDate(insurance.policyHolder.dateOfBirth),
        sex: insurance.policyHolder.gender,
        contactDetails: {
          address: {
            type: '',
            line1: insurance.policyHolder.address,
            city: insurance.policyHolder.city,
            postcode: insurance.policyHolder.postcode,
            administrativeArea: insurance.policyHolder.state,
            country: insurance.policyHolder.country,
          },
          number: {
            type: 'CELL',
            country: '+1',
            area: phoneNumberParts[0],
            prefix: phoneNumberParts[1],
            line: phoneNumberParts[2],
          },
        },
      });
    }

    const insuranceContactDetails =
      insurance.planId === '-1'
        ? {
            address: insurance.address,
          }
        : undefined;

    let entry = {
      primary: insurances.filter((_item) => _item.primary === 1).length === 0 ? 1 : 0,
      order: insurances.length,
      type: {
        system: 'decoded/payment_method',
        code: '1',
      },
      insuranceInformation: {
        memberId: insurance.memberId,
        groupId: insurance.groupId,
        type: 'INSURANCE',
        provider: {
          name: 'default',
          contactDetails: insuranceContactDetails,
        },
        plan: {
          id: insurance.planId,
          name: insurance.plan,
          type: 'default',
        },
        members: policyHolder,
      },
      attachments: insurance.attachments,
      dirty: true,
    };

    insurances.push(entry);

    this.__updateSubject({
      insurances: insurances,
      useExternalInsurance: !(insurances.length > 0),
    });
  };

  removeInsurance = (insurance) => {
    let { insurances, deletedInsurance } = this.subject.value;

    deletedInsurance.push(insurance);
    insurances = insurances.filter((_item) => _item !== insurance);
    this.__updateSubject({
      insurances: insurances,
      deletedInsurance: deletedInsurance,
      useExternalInsurance: !(insurances.length > 0),
    });
  };

  edit = () => {
    let { insurances } = this.subject.value;
    this.__updateSubject({
      insurances: [],
      deletedInsurance: insurances,
      edit: true,
    });
  };

  selfPay = () => {
    const selfPay = [
      {
        primary: 1,
        order: 0,
        type: {
          system: 'decoded/payment_method',
          code: '0',
        },
        attachments: [],
      },
    ];
    this.__updateSubject({ insurances: selfPay, useExternalInsurance: false });
    this.onCallback();
  };

  unknownInsurance = () => {
    const selfPay = [
      {
        primary: 1,
        order: 0,
        type: {
          system: 'decoded/payment_method',
          code: '0',
        },
        attachments: [],
      },
    ];
    this.__updateSubject({ insurances: selfPay, useExternalInsurance: true });
    this.onCallback();
  };

  save = () => {
    this.onCallback();
  };

  onCallback = () => this.callback();

  closeAccept = () => {
    this.__updateSubject({ confirm: false });
  };

  accept = () => {
    this.onCallback();
  };

  back = () => {
    const { source } = this.subject.value;
    this.onBack(source);
  };

  handleBackFromEdit = () => {
    let { deletedInsurance } = this.subject.value;
    this.__updateSubject({ insurances: deletedInsurance, deletedInsurance: [], edit: false });
  };

  close = () => {};
}

export class Constants {}

export class BlocEvent {}
