export const preventGoingBack = (cleanUp = false) => {
  if (cleanUp) {
    return () => {
      window.onpopstate = null;
    };
  } else {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  }
};
