import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { CircularProgress } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { providerStorage } from '../../../utils/provider.qs';

const styles = (theme) => ({
  fullscreen: {
    display: 'flex',
    position: 'fixed',
    right: '0',
    bottom: '0',
    minWidth: '100%',
    minHeight: '100%',
    backgroundColor: '#ffffff',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    margin: '0 auto',
    height: '80px',
  },
  progress: {
    color: '#F29202',
  },
  message: {
    paddingLeft: '24px',
    color: '#ccc',
  },
});

class Loading extends Component {
  //==> added to avoid hanging on loading screen in the event of an error
  componentDidMount() {
    this.timeout = setTimeout(() => {
      providerStorage.clearProvider();
      this.props.history.push('/');
    }, 5000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    let { classes, message } = this.props;

    return (
      <div className={classes.fullscreen}>
        <CircularProgress classes={{ circle: classes.progress }} />
        {message ? <p className={classes.message}>{message}</p> : <></>}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Loading));
