import React, { useState, useContext } from 'react';
import { Chip, Box, Grid } from '@mui/material';
import { H1TextStepTitle, TextStepSecondary } from '../../../shared/Typography';
import { LoginDataContext } from '../LoginDataProvider';
import { useMediaQueries } from '../../../../utils/hooks/useMediaQueries';
import { NextButton } from './NextButton';
import { FormattedMessage } from 'react-intl';
import ScrollableContainer, {
  LoginDisconnectedPageFooter,
} from '../../../common/ScrollableContainer';
import PageContainer from '../../../common/PageContainer';
import { StyledGrid } from './StyledGrid'


const options = ['Female', 'Male'];


const SexAtBirth = (props) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const { loginData, setLoginData } = useContext(LoginDataContext);

  const mediaQuery = useMediaQueries();

  const handleChipClick = (option) => {
    setSelectedOption(option);
    setLoginData((prevState) => ({ ...prevState, gender: option }));
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsFormDisabled(true);

    setTimeout(() => {
      if (props.index !== 6) {
        setIsFormDisabled(false);

        props.setIndex((prevIndex) => (prevIndex + 1) % 7);
      }
    }, 250);
  };

  return (
    <PageContainer>
      <form onSubmit={handleClick} style={{ display: 'contents' }}>
        <ScrollableContainer>
          <StyledGrid md={12} xl={8} container sx={{ padding: mediaQuery.isSm ? '20px' : '80px' }}>
            <Grid item >
              <FormattedMessage
                id="login.patient.sex"
                defaultMessage="Patient assigned sex at birth"
              >
                {(txt) => (
                  <H1TextStepTitle isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}>
                    {txt}
                  </H1TextStepTitle>
                )}
              </FormattedMessage>
            </Grid>
            <Grid sx={{ marginY: '30px' }}>
              <FormattedMessage
                id="login.patient.sex.disclaimer"
                defaultMessage="THIS INFORMATION IS USED TO VERIFY YOUR IDENTITY. IT WILL NOT BE USED TO ASSUME YOUR GENDER IDENTITY AND IT WILL NOT BE SHARED WITH ANYONE."
              >
                {(txt) => (
                  <TextStepSecondary isMobile={mediaQuery.isMobileLandscape}>
                    {txt}
                  </TextStepSecondary>
                )}
              </FormattedMessage>
            </Grid>
            <Grid
              container
              gap={2}
              sx={{
                width: '75%',
                '@media (max-width:600px)': {
                  width: '100%',
                },
                display: mediaQuery.isMobileLandscape && 'flex',
                flexDirection: mediaQuery.isMobileLandscape && 'row',
                justifyContent: mediaQuery.isMobileLandscape && 'flex-start',
                alignItems: mediaQuery.isMobileLandscape && 'flex-end',
                flexWrap: mediaQuery.isMobileLandscape && 'nowrap',
              }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" gap={2}>
                  {options.map((option) => (
                    <Chip
                      size="large"
                      key={option}
                      label={option}
                      variant={loginData.gender === option ? 'filled' : 'outlined'}
                      color="primary"
                      clickable
                      onClick={() => handleChipClick(option)}
                      sx={{
                        fontSize: '1.5rem',
                        padding: '1.7rem 1.4rem',
                        '&:hover': {
                          backgroundColor: '#24666c',
                        },
                        '&:focus': {
                          backgroundColor: '#24666c',
                        },
                        '&.Mui-focused': {
                          backgroundColor: '#24666c',
                        },
                      }}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </StyledGrid>
        </ScrollableContainer>
        <LoginDisconnectedPageFooter>
            <NextButton label='NEXT' disabled={!loginData.gender || isFormDisabled} />
        </LoginDisconnectedPageFooter>
      </form>
    </PageContainer>
  );
};

export default SexAtBirth;