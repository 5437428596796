import * as rxjs from 'rxjs';
import { appointmentApi } from '../../utils/services/appointments.api';
import { compareAsc, format, parse } from 'date-fns';
import { DATE_FORMAT } from '../../utils/user';
import { notificationService } from '../../utils/notification';
import { dateUtil } from '../../utils/date';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { errorResolver } from '../../utils/error.resolver';
import React, { useEffect, useMemo, useState } from 'react';
import { BookingType } from './utils';
import OrgSelector from './components/OrgSelector';
import TimeSelector from './components/TimeSelector';
import Confirmation from './components/Confirmation';
import { providerStorage } from '../../utils/provider.qs';
import WalkinConfirmation from './components/WalkinConfirmation';
import { routeUtil, THANKYOU_ROUTE } from '../../utils/route.name';
import { appointmentIntervalUtil } from '../../utils/appointment';
import { authService } from '../../utils/auth';
import { logger } from '../../utils/logging';
import { CheckSession } from '../../utils/storage';
import { simulateError } from '../../utils/__tests__/api-call-error-test';
import { globalBloc } from '../global.bloc';

const remoteSteps = (bloc) => {
  const loggedIn = authService.isLoggedIn();

  if (loggedIn) {
    return [
      {
        id: 'org-selector',
        component: <OrgSelector />,
        dataKey: 'booking.selectedOrg',
        onSubmit: async () => {
          let step = 'org-selector';
          return bloc.__saveAppointmentProgress(step);
        },
      },

      {
        id: 'time-selector',
        component: <TimeSelector />,
        message: {
          id: () =>
            bloc.isTomorrow()
              ? 'interaction.appointment.reservation.chatbubble.tomorrow.selecttime'
              : 'interaction.appointment.reservation.chatbubble.selecttime',
          text: () => {
            return bloc.isTomorrow()
              ? `When would you like to come in tomorrow ${dateUtil.formatLocalDateAdjust(
                  bloc.bookingSelectedDate(),
                )}?`
              : 'When would you like to come in?';
          },
        },
        dataKey: 'booking.selectedSlot',
        onSubmit: async () => {
          const { walkinOnly } = bloc.subject.value;
          if (walkinOnly) {
            bloc.showWalkinInstructions();
            return 'stop';
          }

          let step = 'time-selector';
          return bloc.__saveAppointmentProgress(step);
        },
        submitLabel: () => {
          const { walkinOnly } = bloc.subject.value;
          if (walkinOnly) {
            return 'Walk-In';
          }
          return 'Continue';
        },
      },

      {
        id: 'confirmation',
        component: <Confirmation />,
        message: {
          id: 'interaction.appointment.reservation.chatbubble.confirm',
          text: 'Thank you. Before we continue, please review and confirm the message below.',
        },
        submitLabel: 'Confirm',
      },
    ];
  } else {
    return [
      {
        id: 'org-selector',
        component: <OrgSelector />,
        disableBack: true,
        dataKey: 'booking.selectedOrg',
        onSubmit: async () => {
          let step = 'org-selector';
          return bloc.__saveAppointmentProgress(step);
        },
      },
      {
        id: 'time-selector',
        component: <TimeSelector />,
        message: {
          id: () =>
            bloc.isTomorrow()
              ? 'interaction.appointment.reservation.chatbubble.tomorrow.selecttime'
              : 'interaction.appointment.reservation.chatbubble.selecttime',
          text: () => {
            return bloc.isTomorrow()
              ? `When would you like to come in tomorrow ${dateUtil.formatLocalDateAdjust(
                  bloc.bookingSelectedDate(),
                )}?`
              : 'When would you like to come in?';
          },
        },
        dataKey: 'booking.selectedSlot',
        onSubmit: async () => {
          const { walkinOnly } = bloc.subject.value;
          if (walkinOnly) {
            bloc.showWalkinInstructions();
            return 'stop';
          }

          let step = 'time-selector';
          return bloc.__saveAppointmentProgress(step);
        },
        submitLabel: () => {
          const { walkinOnly } = bloc.subject.value;
          if (walkinOnly) {
            return 'Walk-In';
          }
          return 'Continue';
        },
      },
    ];
  }
};

const walkinSteps = (bloc) => {
  const service = bloc.subject.value.appointment?.service;

  if (service === 'FAT-UC') {
    return [
      {
        id: 'booking-confirmation',
        message: {
          id: 'interaction.appointment.walkin.fat-uc.chatbubble.selectdate',
          text: 'We are almost there. Just review the information below and confirm to reserve your place in line with a Fast Track Provider.',
        },
        component: <WalkinConfirmation />,
        onSubmit: () => bloc.confirmWalkin(),
        submitLabel: () => {
          const { booking } = bloc.subject.value;

          if (!booking.selectedSlot) {
            return 'End';
          }

          if (booking.locked) {
            return booking.error ? 'End' : 'Confirm';
          }

          return 'Loading';
        },
      },
    ];
  }

  return [
    {
      id: 'booking-confirmation',
      message: {
        id: 'interaction.appointment.walkin.chatbubble.selectdate',
        text: 'We are almost there. Just review the below and confirm to reserve your place in line.',
      },
      component: <WalkinConfirmation />,
      onSubmit: () => bloc.confirmWalkin(),
      submitLabel: () => {
        const { booking } = bloc.subject.value;

        if (!booking.selectedSlot) {
          return 'End';
        }

        if (booking.locked) {
          return booking.error ? 'End' : 'Confirm';
        }

        return 'Loading';
      },
    },
  ];
};

export class BookingBloc {
  constructor(appointmentId, appointmentType) {
    const organisationId = providerStorage.getCurrentProvider();
    const isWalkin = providerStorage.isWalkin();
    const acuity = sessionStorage.getItem('acuity' + appointmentId) || 'non-urgent';

    this.subject = new rxjs.BehaviorSubject({
      initialising: true,
      loadingData: true,
      isWalkin: isWalkin,
      acuity: acuity,
      appointmentId: appointmentId,
      appointmentType: appointmentType,
      booking: {},
      calendarSummary: undefined,
      availableOrganisations: [],
      systemProperties: [],
    });

    this.events = new rxjs.Subject();

    this.__initialise(appointmentId, organisationId);
  }

  __updateSubject = (value) => {
    const newState = {
      ...this.subject.value,
      ...value,
    };
    this.subject.next(newState);
  };

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  __initialise = (appointmentId, organisationId) => {
    const globalValue = globalBloc.subject.value || {};
    const { orgSelected } = globalValue;
    const {
      firstAvailableCapacity,
      firstAvailableDate,
      organisation = {},
    } = globalValue.booking || {};
    const { id } = organisation;

    appointmentApi
      .getAppointmentStatus(
        appointmentId,
        !CheckSession('action', 'manage-appointments') ? 'isStatus' : null,
      )
      .then(
        (value) => {
          const appointment = value.data;

          if (orgSelected) {
            this.__updateSubject({
              appointment: appointment,
              loading: false,
              initialising: false,
              loadingData: true,
              booking: {
                selectedOrg: id,
                selectedOrgCapacity: firstAvailableCapacity,
                selectedDate: firstAvailableDate,
              },
            });
            this.__saveAppointmentProgress('org-selector'); //==>sets org w/ _command
          } else {
            this.__updateSubject({
              appointment: appointment,
              loading: false,
              initialising: false,
              loadingData: true,
            });
          }

          if (appointment?.type === 'IN_PERSON_WALK_IN' && appointment?.status === 'RESERVED') {
            this.events.next({
              type: BookingBlocEvent.NAVIGATE_TO,
              data: {
                url: routeUtil.buildBookingIdentityDocument(appointment.id),
              },
            });
          } else {
            this.getAvailableOrganisations(appointment?.service, organisationId);

            this.events.next({
              type: BookingBlocEvent.INITIALISED,
              data: {
                appointment: appointment,
              },
            });
          }
        },
        (reason) => {
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOAD_ERROR, {
            error: reason.message,
          });
          notificationService.error(
            'Unable to load available clinics. Please try again and if the problem persists call the clinic.',
          );
        },
      );
  };

  clearWalkinOnly = () => {
    let { booking } = this.subject.value;
    booking.selectedSlot = '';
    this.__updateSubject({ walkinOnly: false, booking: booking });
  };

  setWalkinOnly = () => {
    let { booking } = this.subject.value;
    booking.selectedSlot = 'walkin';
    this.__updateSubject({ walkinOnly: true, booking: booking });
  };

  _constructScheduleData = (intervalData, selectedDate) =>
    appointmentIntervalUtil.constructScheduleData(intervalData, selectedDate);

  setDoctor = (doctor, doctorDetail) => {
    const { booking } = this.subject.value;

    if (booking.doctor !== doctor) {
      let newBooking = { ...booking };
      newBooking.doctor = doctor;
      newBooking.doctorDetail = doctorDetail;
      newBooking.reminderTime = undefined;

      this.subject.next({
        ...this.subject.value,
        booking: newBooking,
        calendarSummary: undefined,
      });
    }

    this.events.next({ type: BookingBlocEvent.DOCTOR_SELECTED, data: {} });

    analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_PROVIDER_SELECT, {});
  };

  loadServiceSchedule = () => {
    const { appointment, booking, isWalkin } = this.subject.value;

    this.subject.next({
      ...this.subject.value,
      loadingData: true,
      calendarSummary: undefined,
    });

    const now = new Date();
    const lastPeriod = new Date(new Date().setDate(now.getDate() + 2));

    let start = format(now, DATE_FORMAT);
    let end = format(lastPeriod, DATE_FORMAT);

    return appointmentApi
      .getServiceScheduleSummary(
        start,
        end,
        appointment.service,
        booking.selectedOrg,
        undefined,
        !isWalkin,
      )
      .then(
        (value) => {
          this.subject.next({
            ...this.subject.value,
            calendarSummary: value.data,
            loadingData: false,
          });

          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_DATE_LOADED, {});
        },
        (reason) => {
          notificationService.error(
            'Error loading available times for appointment type. Please refresh. If the problem persists please contact the clinic.',
          );
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_DATE_LOADED, {
            status: 'error',
            message: `${reason}`,
          });
          this.subject.next({
            ...this.subject.value,
            loadingData: false,
          });
        },
      );
  };

  setSelectedDate = (date) => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.selectedDate = date;
    newBooking.availability = undefined;
    newBooking.reminderTime = undefined;

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });

    this.events.next({ type: BookingBlocEvent.DATE_SELECTED, data: {} });
  };

  isTomorrow = () => {
    const { booking } = this.subject.value;
    const now = new Date();

    //==> Check if selectedDate is a Date object
    const date =
      booking.selectedDate instanceof Date ? booking.selectedDate : new Date(booking.selectedDate);

    return now.getDate() + 1 === date?.getDate();
  };

  bookingSelectedDate = () => {
    const { booking } = this.subject.value;
    return booking.selectedDate;
  };

  setReminderTime = (interval) => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.reminderTime = interval;

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });

    this.events.next({ type: BookingBlocEvent.REMINDER_SELECTED, data: {} });
  };

  loadSelectedDayAvailability = () => {
    const { appointment, booking, isWalkin } = this.subject.value;

    this.clearWalkinOnly();

    this.subject.next({
      ...this.subject.value,
      loadingData: true,
    });

    let apiCall;
    if (!providerStorage.hasProvider()) {
      apiCall = appointmentApi.anonGetAvailableAppointmentSchedule(
        booking.selectedDate,
        booking.selectedOrg ? booking.selectedOrg : appointment?.provider,
        appointment?.service, //TODO need to ensure this is revisited after server fix
        booking.doctor,
        !isWalkin,
      );
    } else {
      apiCall = appointmentApi.getAvailableAppointmentSchedule(
        booking.selectedDate,
        booking.selectedOrg || appointment.provider,
        appointment.service,
        booking.doctor,
        !isWalkin,
      );
    }

    return apiCall
      .then((value) => {
        let newBooking = { ...booking, availability: value.data, reminderTimer: undefined };

        this.subject.next({
          ...this.subject.value,
          booking: newBooking,
          schedulingIntervals: this._constructScheduleData(
            value.data.results[0].intervals,
            booking.selectedDate,
          ),
        });
      })
      .finally(() => {
        this.subject.next({
          ...this.subject.value,
          loadingData: false,
        });
      });
  };

  loadAvailability = (start, end, organisationId, service, doctor, isWalkin) => {
    return appointmentIntervalUtil.loadAvailability(
      appointmentApi,
      start,
      end,
      organisationId,
      service,
      doctor,
      isWalkin,
    );
  };

  setBookingTime = (datetime, save, step) => {
    const { booking, appointment } = this.subject.value;

    let newBooking = { ...booking, selectedSlot: datetime, reminderTime: undefined };

    console.log('setBookingTime', newBooking, datetime, save, step);

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });

    if (save) {
      appointmentApi
        .updateAppointment(appointment.id, this.__createAppointmentRequestData(step))
        .then(
          (value) => {
            this.events.next({ type: BookingBlocEvent.TIME_SELECTED, data: {} });
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {});
          },
          (reason) => {
            notificationService.error(
              'There was a problem selecting the desired time. Please select another time. If the problem persists please contact the clinic.',
            );
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {
              status: 'error',
              message: `${reason.message}`,
            });
          },
        );
    } else {
      this.events.next({ type: BookingBlocEvent.TIME_SELECTED, data: {} });
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {});
    }
  };

  __saveAppointmentProgress = (step) => {
    const { appointment, appointmentId } = this.subject.value;

    return appointmentApi
      .updateAppointment(
        appointment?.id ?? appointmentId,
        this.__createAppointmentRequestData(step),
      )
      .then(
        (value) => {
          this.events.next({ type: BookingBlocEvent.TIME_SELECTED, data: {} });
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {});
          return value;
        },
        (reason) => {
          notificationService.error(
            'There was a problem selecting the desired time. Please select another time. If the problem persists please contact the clinic.',
          );
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {
            status: 'error',
            message: `${reason.message}`,
          });
          return reason;
        },
      );
  };

  __createAppointmentRequestData = (step, startTimeResolver) => {
    const { appointment, booking } = this.subject.value;

    let selectedStart = startTimeResolver
      ? startTimeResolver(booking)
      : this.extractSlotStartDate(booking);

    let participants = [
      {
        role: 'O_SRV_PROV',
        identifier: {
          code: 'id',
          system: 'decoded/party/organisation',
          value: booking.selectedOrg || appointment.provider,
        },
      },
    ];

    if (booking.doctor) {
      participants.push({
        role: 'S_PROVIDER',
        identifier: {
          value: booking.doctor,
        },
      });
    }

    let requestData;

    if (providerStorage.hasProvider()) {
      requestData = {
        service: {
          code: {
            value: appointment.service,
          },
          channel: {
            value: appointment.type,
          },
        },
        slot: {
          intervalStart: selectedStart,
        },
        reminder: booking.reminderTime || 60,
        participants: participants,
      };
    } else if (step === 'org-selector') {
      requestData = {
        command: 'set_service_provider',
        participants: participants,
      };
    } else if (step === 'time-selector') {
      requestData = {
        command: 'update_slot',
        start: selectedStart,
      };
    }

    return requestData;
  };

  __createAppointmentWalkinRequestData = () => {
    const { appointment, booking } = this.subject.value;

    let participants = [
      {
        role: 'O_SRV_PROV',
        identifier: {
          value: booking.selectedOrg,
        },
      },
    ];

    if (booking.doctor) {
      participants.push({
        role: 'S_PROVIDER',
        identifier: {
          value: booking.doctor,
        },
      });
    }

    return {
      service: {
        code: {
          value: appointment.service,
        },
        channel: {
          value: appointment.type,
        },
      },
      reminder: booking.reminderTime || 60,
      participants: participants,
    };
  };

  extractSlotStartDate(booking) {
    //TODO might need to remove if statement
    if (booking.availability) {
      const interval = booking.availability.results
        .flatMap((_result) => _result.intervals)
        .filter((_interval) => {
          if (!_interval.availableSlots) return false;
          if (_interval.start === booking.selectedSlot) return true;
          const arr = _interval.availableSlots.filter(
            (_slot) => _slot.slotId === booking.selectedSlot,
          );
          return arr.length > 0;
        })[0];

      const slot = interval.availableSlots;

      const selectedStart = dateUtil.parseDate(interval.start);
      if (slot.length > 0) {
        selectedStart.setMinutes(slot[0].start.split(':')[1]);
      }
      return selectedStart;
    } else {
      return undefined;
    }
  }

  confirmAppointment = (data) => {
    const { appointment } = this.subject.value;
    this.subject.next({
      ...this.subject.value,
      isConfirmingAppointment: true,
    });
    if (appointment?.type === 'VIRTUAL') {
      return appointmentApi
        .checkInAppointment(appointment.id)
        .then(
          (response) => {
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_VIRTUAL_SCHEDULE_CONFIRM_SUCCESS, {
              appointmentId: appointment.id,
            });
            this.events.next({ type: BookingBlocEvent.BOOKING_CONFIRMED, data: {} });
            return response;
          },
          (reason) => {
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_VIRTUAL_SCHEDULE_CONFIRM_ERROR, {
              status: 'error',
              appointmentId: appointment.id,
              message: `${reason}`,
            });
            notificationService.error(errorResolver.resolveBookingConfirmationError(reason));
            return reason;
          },
        )
        .finally(() => {
          this.subject.next({
            ...this.subject.value,
            isConfirmingAppointment: false,
          });
        });
    } else {
      return appointmentApi
        .remoteConfirmAppointment(appointment.id, sessionStorage.getItem('user_token'))
        .then(
          (response) => {
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_CONFIRM_SELECT, {
              appointmentId: appointment.id,
            });
            this.events.next({ type: BookingBlocEvent.BOOKING_CONFIRMED, data: {} });
            this.subject.next({
              ...this.subject.value,
              isConfirmingAppointment: true,
            });

            return response;
          },

          (reason) => {
            this.__updateSubject({
              isConfirmingAppointment: false,
            });
            notificationService.error(
              'Appointment time slot is unavailable. Please try a different location or choose a another time.',
            );
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_CONFIRM_SELECT, {
              status: 'error',
              message: `${reason}`,
            });
            return reason;
          },
        );
    }
  };

  switchType = (newType) => {
    const { appointment } = this.subject.value;

    if (newType === 'reservation' && appointment.type === 'IN_PERSON_WALK_IN') {
      appointment.type = 'IN_PERSON';
    }

    this.subject.next({
      ...this.subject.value,
      appointment: appointment,
    });

    this.events.next({
      type: BookingBlocEvent.SWITCH_BOOKING_TYPE,
      data: {
        appointment: appointment,
        overrides: { type: newType },
      },
    });

    analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_TYPE_SWITCH, {
      appointmentId: appointment.id,
    });
  };

  getAvailableOrganisations = (service, organisationId) => {
    const { orgSelected } = globalBloc.subject.value;
    this.__updateSubject({
      loadingData: true,
    });

    let apiCall = appointmentApi.getAvailableOrgs(service);

    apiCall.then(
      (res) => {
        const organisations = res.data.items;
        let selectedOrganisation = organisationId;
        let skipSelection = false;
        if (!(organisationId?.length > 0)) {
          if (organisations.length === 1) {
            selectedOrganisation = organisations[0].id;
            skipSelection = true;
          }
        }

        this.__updateSubject({
          loadingData: false,
          availableOrganisations: organisations.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
          ),
          steps: organisationId?.length > 0 ? walkinSteps(this) : remoteSteps(this),
        });

        // if (!orgSelected) {
        //   this.setSelectedOrg(selectedOrganisation, ''); //==> added if to not effect other use cases of this setter
        // }

        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOADED, {
          locations: `${organisations.length}`,
        });
      },
      (reason) => {
        notificationService.error(
          'Error loading available organisations for appointment type. Please refresh. If the problem persists please contact the clinic.',
        );
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOADED, {
          status: 'error',
          message: `${reason}`,
        });
        this.__updateSubject({ loadingData: false });
      },
    );
  };

  setSelectedOrg = (org, capacity, date) => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.selectedOrg = org;
    newBooking.selectedOrgCapacity = capacity;
    newBooking.selectedDate = date || new Date();
    newBooking.availability = undefined;
    newBooking.reminderTime = undefined;

    analyticsEventLogger.log(
      AnalyticsEvent.BOOKING_APPOINTMENT_BOOKING_APPOINTMENT_ORGANISATION_LOADED_SELECT,
      { organisation: org, capacity: `${capacity}` },
    );

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });
  };

  //? v===ADDED TO CLEAR ORG WHEN USER GOES BACK TO AVOID ERROR===v

  clearSelectedOrg = () => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.selectedOrg = undefined;
    newBooking.selectedOrgCapacity = undefined;
    newBooking.selectedDate = undefined;
    newBooking.availability = undefined;
    newBooking.reminderTime = undefined;

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });
  };

  lockWalkinDetails = (start) => {
    const { booking, appointmentId, appointment } = this.subject.value;

    const iso = start.toISOString();
    const selectedSlot = parse(iso, "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date());

    let newBooking = { ...booking };
    newBooking.selectedSlot = selectedSlot;
    newBooking.reminderTime = 60;
    newBooking.locked = false;
    newBooking.selectedOrg = appointment.provider;

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });

    const request = this.__createAppointmentWalkinRequestData();
    request.command = 'update_details';

    return appointmentApi.command(appointmentId, request);
  };

  lockedWalkinDetails = (error) => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.locked = true;
    newBooking.error = error;

    this.__updateSubject({ booking: newBooking });
  };

  confirmWalkin = () => {
    const { booking, loadingData } = this.subject.value;

    if (loadingData || !booking.locked) {
      return new Promise((resolve) => resolve('stop'));
    }

    if (!booking.selectedSlot || booking.error) {
      this.events.next({
        type: BookingBlocEvent.NAVIGATE_TO,
        data: { url: THANKYOU_ROUTE },
      });
      return new Promise((resolve) => resolve('stop'));
    }

    return new Promise((resolve) => resolve('continue'));
  };

  estimateQueueStats = () => {
    const { availableOrganisations, booking, appointment } = this.subject.value;

    const target = availableOrganisations.filter((org) => org.id === appointment.provider);

    if (target.length > 0) {
      const clinic = target[0];
      this.setSelectedDate(new Date());
      this.loadSelectedDayAvailability().finally(() => {
        this.events.next({ type: BookingBlocEvent.WALKIN_DETAILS_LOADED, data: {} });
      });
    } else {
      notificationService.error(
        'An error occurred trying to lookup the clinics information. Please try refresh or contact the clinic directly.',
      );
    }
  };

  isEmergent = () => {
    const { acuity } = this.subject.value;
    return acuity === 'emergent';
  };
  isWalkin = () => {
    const { isWalkin } = this.subject.value;
    return isWalkin;
  };

  isVirtual = () => {
    const { appointment } = this.subject.value;
    return appointment.type === 'VIRTUAL';
  };

  showWalkinInstructions = () => {
    const { appointment } = this.subject.value;
    this.events.next({
      type: BookingBlocEvent.NAVIGATE_TO,
      data: { url: routeUtil.buildAWalkinOnlyInstructions(appointment.provider) },
    });
  };

  appointment = () => {
    return this.subject.value.appointment;
  };

  service = () => 'ANY-UC';

  makeReservationAvailable = () => {};

  isUserLoggedIn = () => {
    const loggedIn = authService.isLoggedIn();
    return loggedIn;
  };
}

export class BookingBlocEvent {
  static INITIALISED = 'INITIALISED';
  static SWITCH_BOOKING_TYPE = 'SWITCH_BOOKING_TYPE';
  static DOCTOR_SELECTED = 'DOCTOR_SELECTED';
  static DATE_SELECTED = 'DATE_SELECTED';
  static TIME_SELECTED = 'TIME_SELECTED';
  static REMINDER_SELECTED = 'REMINDER_SELECTED';
  static BOOKING_CONFIRMED = 'BOOKING_CONFIRMED';

  static WALKIN_DETAILS_LOADED = 'WALKIN_DETAILS_LOADED';

  static NAVIGATE_TO = 'NAVIGATE_TO';
}

const determineAppointmentType = (appointment, overrides) => {
  return BookingType.RESERVATION;
};

// useBookingBloc is a hook that returns the booking bloc appointment type and state

export const useBookingBloc = (appointmentId, type, history) => {
  const [state, setState] = useState({});
  const [appointmentType, setAppointmentType] = useState(null);
  const bookingBloc = useMemo(() => new BookingBloc(appointmentId, type), [appointmentId, type]);

  const subscribeToState = React.useCallback((newState) => {
    setState((state) => ({ ...state, ...newState }));
  }, []);

  const subscribeToEvents = React.useCallback((event) => {
    const { data } = event;
    switch (event.type) {
      case BookingBlocEvent.INITIALISED:
        setAppointmentType(determineAppointmentType(data.appointment, data.overrides));
        break;
      case BookingBlocEvent.SWITCH_BOOKING_TYPE:
        setAppointmentType(determineAppointmentType(data.appointment, data.overrides));
        break;
      case BookingBlocEvent.NAVIGATE_TO:
        history.replace(data.url);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    const stateSub = bookingBloc.subscribeToState(subscribeToState);
    const eventSub = bookingBloc.subscribeToEvents(subscribeToEvents);
    return () => {
      stateSub.unsubscribe();
      eventSub.unsubscribe();
    };
  }, [bookingBloc, subscribeToState, subscribeToEvents]);

  return {
    ...state,
    appointmentType,
    bookingBloc,
  };
};
