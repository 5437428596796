import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { H1TextStepTitle, TextStepSecondary } from '../../../shared/Typography';
import { isValidName } from '../validationUtils';
import DecodedTextField from '../../../../utils/DecodedTextFIeld';
import { LoginDataContext } from '../LoginDataProvider';
import { useMediaQueries } from '../../../../utils/hooks/useMediaQueries';
import { NextButton } from './NextButton';
import ScrollableContainer, {
  LoginDisconnectedPageFooter,
} from '../../../common/ScrollableContainer';
import PageContainer from '../../../common/PageContainer';
import { StyledGrid } from './StyledGrid'


const Name = (props) => {

  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const mediaQuery = useMediaQueries();

  const { formatMessage } = useIntl();

  const isPrimaryCare = sessionStorage.getItem('registrationType') === 'PC';

  const { loginData, setLoginData } = useContext(LoginDataContext);

  useEffect(() => {
    if (loginData.firstName) {
      setError1(!isValidName(loginData.firstName));
    }
    if (loginData.lastName) {
      setError2(!isValidName(loginData.lastName));
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setLoginData((prevState) => ({ ...prevState, [name]: value }));

    if (name === 'firstName') {
      setError1(!isValidName(value));
    }
    if (name === 'lastName') {
      setError2(!isValidName(value));
    }
  };

  const handleClick = () => {
    setIsFormDisabled(true);

    setTimeout(() => {
      if (props.index !== 6) {
        setIsFormDisabled(false);

        props.setIndex((prevIndex) => (prevIndex + 1) % 7);
      }
    }, 250);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleClick();
    setLoginData((prevData) => ({ ...prevData, goBackStep: true }));
  };

  const headerVerbiage = () => {
    return isPrimaryCare ? (
      <FormattedMessage
        id="login.patient.header.pc"
        defaultMessage="Welcome to Duke City Primary Care Registration."
      >
        {(txt) => (
          <H1TextStepTitle isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}>
            {txt}
          </H1TextStepTitle>
        )}
      </FormattedMessage>
    ) : (
      <FormattedMessage
        id="login.patient.header"
        defaultMessage="Lets verify the patient account quickly."
      >
        {(txt) => (
          <H1TextStepTitle isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}>
            {txt}
          </H1TextStepTitle>
        )}
      </FormattedMessage>
    );
  };

  return (
    <PageContainer>
      <form onSubmit={onSubmit} style={{ display: 'contents' }}>
        <ScrollableContainer>
          <StyledGrid  md={12} xl={8} container sx={{ padding: mediaQuery.isSm ? '20px' : '80px' }}>
            <Grid item>{headerVerbiage()}</Grid>
            <Grid item sx={{ marginY: '30px' }}>
              <FormattedMessage
                id="login.patient.body"
                defaultMessage="PLEASE PROVIDE THE PATIENT NAME AS IT APPEARS ON THE INSURANCE CARD OR GOVERNMENT ID"
              >
                {(txt) => (
                  <TextStepSecondary isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}>
                    {txt}
                  </TextStepSecondary>
                )}
              </FormattedMessage>
            </Grid>
            <Grid
              container
              spacing={mediaQuery.isSm ? 0 : 2}
              gap={mediaQuery.isSm ? 2 : 0}
              sx={{
                width: '75%',
                '@media (max-width:600px)': {
                  width: '100%',
                },
                display: mediaQuery.isMobileLandscape && 'flex',
                flexDirection: mediaQuery.isMobileLandscape && 'row',
                justifyContent: mediaQuery.isMobileLandscape && 'flex-start',
                alignItems: mediaQuery.isMobileLandscape && 'flex-end',
                flexWrap: mediaQuery.isMobileLandscape && 'nowrap',
              }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <DecodedTextField
                  autoFocus
                  name="firstName"
                  label="Patients First Name"
                  variant="outlined"
                  value={loginData.firstName}
                  onChange={handleInputChange}
                  fullWidth
                  error={error1}
                  helperText={
                    error1 &&
                    formatMessage({
                      id: 'registration.field.error.patient.name.family',
                      defaultMessage: 'Invalid name',
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <DecodedTextField
                  name="lastName"
                  label="Patients Last Name"
                  variant="outlined"
                  value={loginData.lastName}
                  onChange={handleInputChange}
                  fullWidth
                  error={error2}
                  helperText={
                    error2 &&
                    formatMessage({
                      id: 'registration.field.error.patient.name.family',
                      defaultMessage: 'Invalid name',
                    })
                  }
                />
              </Grid>
            </Grid>
          </StyledGrid>
        </ScrollableContainer>
        <LoginDisconnectedPageFooter>
            <NextButton
              label='NEXT'
              disabled={
                error1 ||
                error2 ||
                loginData.firstName === '' ||
                loginData.lastName === '' ||
                isFormDisabled
              }
            />
        </LoginDisconnectedPageFooter>
      </form>
    </PageContainer>
  );
};

export default Name;