import * as rxjs from 'rxjs';
import { assistantApi } from '../../../utils/services/assistant.api';
import { notificationService } from '../../../utils/notification';
import {
  LOGIN_ROUTE,
  QUINN_SCHEDULED,
  QUINN_TERMINATE_ROUTE,
  routeUtil,
} from '../../../utils/route.name';
import { dateUtil } from '../../../utils/date';
import { appointmentIntervalUtil } from '../../../utils/appointment';
import { appointmentApi } from '../../../utils/services/appointments.api';
import { providerStorage } from '../../../utils/provider.qs';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { logger } from '../../../utils/logging';
import { SetSession } from '../../../utils/storage';
import { addUserDataHeap } from '../../../utils/heap/heapUtils';

export class AssistantBloc {
  timeout;

  constructor(chatRef) {
    this.chatRef = chatRef;

    this.subject = new rxjs.BehaviorSubject({
      locale: 'en',
      chatAvailable: false,
      initialised: false,
    });

    this.events = new rxjs.Subject();
  }

  __updateSubject = (newProps) => {
    this.subject.next({
      ...this.subject.value,
      ...newProps,
    });
  };

  setChatRef = (ref) => {
    this.chatRef = ref;
  };

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  initialise = () => {
    assistantApi
      .generateToken()
      .then((value) => {
        if (providerStorage.isWalkin()) {
          this.isFastTrackAvailable().then(
            (available) => {
              this.subject.next({
                ...this.subject.value,
                token: value.data.token,
                initialised: true,
                fastTrack: available,
              });
              analyticsEventLogger.log(AnalyticsEvent.QUINN_FEATURES, {
                fasttrack: `${available}`,
              });
            },
            (reason) => {
              this.subject.next({
                ...this.subject.value,
                token: value.data.token,
                initialised: true,
              });
              analyticsEventLogger.log(AnalyticsEvent.QUINN_FEATURES, {
                fasttrack: `false`,
                error: reason,
              });
            },
          );
        } else {
          this.subject.next({
            ...this.subject.value,
            token: value.data.token,
            initialised: true,
          });
        }
      })
      .catch((reason) => notificationService.error('Error generating token for assistant.'));
  };

  isFastTrackAvailable = () => {
    const provider = providerStorage.getCurrentProvider();
    const now = new Date();
    const tomorrow = dateUtil.nextStartOfDay(now);
    const periodEnd = dateUtil.addhours(now, 2);

    return this.loadAvailability(now, tomorrow, provider, 'FAT-UC', undefined, true).then(
      (schedulingIntervals) => {
        try {
          let firstSlot = appointmentIntervalUtil.findFirstAvailableSlotForPeriod(
            schedulingIntervals,
            now,
            now,
            periodEnd,
          );

          if (firstSlot) {
            return true;
          }

          return false;
        } catch (e) {
          return false;
        }
      },
      (reason) => {
        return false;
      },
    );
  };

  loadAvailability = (start, end, organisationId, service, doctor, isWalkin) => {
    return appointmentIntervalUtil.loadAvailability(
      appointmentApi,
      start,
      end,
      organisationId,
      service,
      doctor,
      isWalkin,
    );
  };

  handleBotEvent = (event) => {
    let { chatAvailable } = this.subject.value;

    if (event.attachment) {
      logger.info('Quinn chat event:', event.attachment);
      if (event.attachment.type === 'dh_card') {
        this.disableChat();
      } else if (event.attachment.type === 'dh_event') {
        if (event.attachment?.payload?.id) {
          SetSession('appt', event.attachment.payload.id);

          addUserDataHeap({
            appointmentId: event.attachment.payload.id,
          });
        }
        const payload = event.attachment.payload;
        if (payload.type === 'schedule.appointment.drafted') {
          this.disableChat();

          if (payload.additionalProperties?.acuity === 'emergent') {
            sessionStorage.setItem('acuity' + payload.id, 'emergent');
          }
          if (
            payload.additionalProperties?.service === 'workerscomp' ||
            payload.additionalProperties?.service === 'occhealth'
          ) {
            this.events.next({
              type: AssistantBlocEvent.NAVIGATE_TO,
              url: routeUtil.buildRoutingOcchealth(payload.id),
            });
          } else if (
            ['INP-UC', 'ANY-UC', 'FAT-UC'].includes(payload.additionalProperties?.serviceCode)
          ) {
            if (payload.additionalProperties?.service === 'vip') {
              sessionStorage.setItem('service' + payload.id, 'vip');
            } else if (sessionStorage.getItem('currentPath') === '/new-conversation') {
              logger.info('🏒 ->', 'routed to preauth UC from new-conversation');
              return this.events.next({
                type: AssistantBlocEvent.NAVIGATE_TO,
                url: routeUtil.buildPreAuthRoutingUrgentCare(payload.id),
              });
            }
            this.events.next({
              type: AssistantBlocEvent.NAVIGATE_TO,
              url: routeUtil.buildRoutingUrgentCare(payload.id),
            });
          } else if (['MEB-UC'].includes(payload.additionalProperties?.serviceCode)) {
            console.log('MEB-UC');
            this.events.next({
              type: AssistantBlocEvent.NAVIGATE_TO,
              url: routeUtil.routeToMedicBuddy(),
            });
          } else {
            this.events.next({
              type: AssistantBlocEvent.NAVIGATE_TO,
              url: routeUtil.buildBookingPaymentMethodRouteWithDraftAppointmentID(
                payload.id,
                QUINN_SCHEDULED,
              ),
            });
          }
        } else if (payload.type === 'redirect') {
          if (payload.additionalProperties?.redirect === 'appointments') {
            sessionStorage.setItem('action', 'manage-appointments');
            this.events.next({
              type: AssistantBlocEvent.NAVIGATE_TO,
              url: LOGIN_ROUTE,
            });
          }
        } else if (payload.type === 'quinn.conversation.terminated') {
          this.disableChat();
          this.__redirectToThankyou();
        }
      }
    } else {
      this.enableChat();
    }

    if (event.text === 'TERMINATE_CONVERSATION|') {
      this.disableChat();
      this.__redirectToThankyou();
    } else if (!chatAvailable) {
      this.__updateSubject({ chatAvailable: true });
    }
  };

  __redirectToThankyou = () => {
    setTimeout(() => {
      this.events.next({
        type: AssistantBlocEvent.NAVIGATE_TO,
        url: QUINN_TERMINATE_ROUTE,
      });
    }, 2000);
  };

  quickTextReply = (reply, text) => {
    if (this.chatRef.current && this.chatRef.current.sendMessage) {
      this.chatRef.current.sendMessage(reply, text);
    }
  };

  disableChat = () => {
    this.__updateSubject({ chatInputEnabled: false });
  };

  enableChat = () => {
    this.__updateSubject({ chatInputEnabled: true });
    clearTimeout(this.timeout);
  };

  sendCommand = (command) => {
    if (this.chatRef.current && this.chatRef.current.sendMessage) {
      this.chatRef.current.sendMessage(command);
    }
  };
  sendMessage = (text) => {
    if (this.chatRef.current && this.chatRef.current.sendMessage) {
      this.disableChat();
      this.chatRef.current.sendMessage(text, text);
    }
  };

  sendMessageWithReply = (text, reply) => {
    if (this.chatRef.current && this.chatRef.current.sendMessage) {
      this.disableChat();
      this.chatRef.current.sendMessage(text, reply);
    }
  };
}

export class AssistantBlocEvent {
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
