import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import FrontScan from './FrontScan';
import BackScan from './BackScan';
import { paymentApi } from '../../../../../utils/services/payment.api';
import { notificationService } from '../../../../../utils/notification';
import { insuranceStorage } from '../../../../../utils/insurance.storage';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../../utils/events';
import imageCompression from 'browser-image-compression';
import { WizardContextConsumer } from '../../../../../shared-library/components/Wizard/wizard-context';

const styles = (theme) => ({});

class InsuranceCard extends Component {
  constructor(props) {
    super(props);

    this.bloc = props.bloc;

    const { frontImageBlob, backImageBlob } = this.bloc.subject.value;

    let frontImage = null;
    let backImage = null;
    if (frontImageBlob && backImageBlob) {
      frontImage = URL.createObjectURL(frontImageBlob);
      backImage = URL.createObjectURL(backImageBlob);
    }

    this.state = {
      loading: false,
      activeStep: 0,
      frontImage: frontImage,
      frontImageBlob: frontImageBlob,
      backImage: backImage,
      backImageBlob: backImageBlob,
    };
  }

  handleNextScan = () => {
    const { context } = this.props;
    const { activeStep } = this.state;

    if (activeStep >= 1) {
      this.bloc.next(context);
    } else {
      this.setState((state) => ({
        activeStep: state.activeStep + 1,
      }));
    }
  };

  handlePreviousScan = () => {
    const { context } = this.props;
    const { activeStep } = this.state;

    if (activeStep <= 0) {
      this.bloc.back(context);
    } else {
      this.setState({
        activeStep: activeStep - 1,
      });
    }
  };

  handleFrontScanCapture = async ({ dataURL }) => {
    this.setState({ loading: true });

    const blob = await (await fetch(dataURL)).blob();

    const imageFile = new File([blob], 'front.jpg', {
      type: 'image/jpeg',
      lastModified: new Date(),
    });

    return imageCompression(imageFile, this._imageCompressionOption())
      .then((compressedFile) => {
        this.setState((prevState) => ({
          frontImage: dataURL,
          frontImageBlob: compressedFile,
        }));
      })
      .catch((error) => {
        console.error(error.message);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleBackScanCapture = async ({ dataURL }) => {
    this.setState({ loading: true });

    const blob = await (await fetch(dataURL)).blob();

    const imageFile = new File([blob], 'back.jpg', {
      type: 'image/jpeg',
      lastModified: new Date(),
    });

    return imageCompression(imageFile, this._imageCompressionOption())
      .then((compressedFile) => {
        this.setState((prevState) => ({
          backImage: dataURL,
          backImageBlob: compressedFile,
        }));
      })
      .catch((error) => {
        console.error(error.message);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleImageSubmit = (onError) => {
    let { context } = this.props;
    let { frontImage, backImage, frontImageBlob, backImageBlob } = this.state;

    if (!frontImage || !backImage) {
      notificationService.error(
        'Please attach both the front and back image of your insurance card before submitting.',
      );
      return;
    }

    const formData = new FormData();
    formData.append('front', frontImageBlob, 'front');
    formData.append('back', backImageBlob, 'back');

    this.setState({ loading: true });
    paymentApi
      .submitInsuranceCard(formData)
      .then((response) => {
        analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_INSURANCE_CARD_UPDATE_SUCCESS);

        insuranceStorage.setInsuranceCardRef(response.data);
        this.bloc.setInsuranceCardRef(response.data, frontImageBlob, backImageBlob);
        this.bloc.next(context);
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_INSURANCE_CARD_UPDATE_ERROR, {
          reason: error,
        });

        notificationService.error('Unable to upload the insurance card images.');
      })
      .finally(() => {
        this.setState({ loading: false });
        onError();
      });
  };

  _imageCompressionOption = () => {
    const maxResolutionFlag = process.env.REACT_APP_IMAGE_COMPRESSION_MAX_RESOLUTION;
    const maxResolution = maxResolutionFlag.length > 0 ? parseInt(maxResolutionFlag) : 480;

    return {
      maxSizeMB: 1,
      maxWidthOrHeight: maxResolution,
      useWebWorker: true,
    };
  };

  _getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <FrontScan
            {...this.state}
            handleBack={this.handlePreviousScan}
            history={this.props.history}
            handleNextScan={this.handleNextScan}
            handleFrontScanCapture={this.handleFrontScanCapture}
          />
        );
      case 1:
        return (
          <BackScan
            {...this.state}
            handlePreviousScan={this.handlePreviousScan}
            handleBackScanCapture={this.handleBackScanCapture}
            handleImageSubmit={this.handleImageSubmit}
            history={this.props.history}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  };

  render() {
    let { activeStep } = this.state;

    return this._getStepContent(activeStep);
  }
}

export default withStyles(styles)((props) => (
  <WizardContextConsumer>
    {(value) => {
      return <InsuranceCard context={value} {...props} />;
    }}
  </WizardContextConsumer>
));
