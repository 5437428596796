import { parse } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import enUS from 'date-fns/locale/en-US';
import { configurationUtil } from './configuration';
import { logger } from './logging';

export const DATE_FORMAT = 'yyyy-MM-dd';

class DateUtil {
  fullFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' });
  longFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'short' });

  encloses(at, from, to) {
    return at.getTime() >= from.getTime() && at.getTime() <= to.getTime();
  }

  formatTimeDisplay(timeString, additionalTime = '00:00') {
    const date = new Date(timeString);
    const hour = date.getHours();
    const minutes = date.getMinutes();

    const [, additionalMinutes] = additionalTime.split(':').map(Number);

    //==> Adds additional minutes from MST time to the start time
    date.setMinutes(minutes + additionalMinutes);
    const newHour = date.getHours();
    const newMinutes = date.getMinutes();

    const endDateTime = new Date(date);
    endDateTime.setHours(newHour + 1);
    const endHour = endDateTime.getHours();
    const endMinutes = endDateTime.getMinutes();

    const startHourFormatted = newHour % 12 === 0 ? 12 : newHour % 12;
    const endHourFormatted = endHour % 12 === 0 ? 12 : endHour % 12;

    const timeOfDayStart = newHour < 12 || newHour >= 24 ? 'am' : 'pm';
    const timeOfDayEnd = endHour < 12 || endHour >= 24 ? 'am' : 'pm';

    const formattedStartMinutes = newMinutes < 10 ? `0${newMinutes}` : newMinutes;
    const formattedEndMinutes = endMinutes < 10 ? `0${endMinutes}` : endMinutes;

    return `${startHourFormatted}:${formattedStartMinutes}${timeOfDayStart}-${endHourFormatted}:${formattedEndMinutes}${timeOfDayEnd}`;
  }

  getUserTimeZoneAbbreviation() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date();
    const timeZoneString = date.toLocaleTimeString('en-US', {
      timeZoneName: 'short',
      timeZone: timeZone,
    });
    const abbreviation = timeZoneString.split(' ')[2]; // Extract the abbreviation part
    return abbreviation;
  }

  //==> this function adds the minutes from the returned MST time to the provided UTC time, kinda confusing but its needed to show accurate times on the UI
  addMinutesToUtc(mstTime, utcTimeStr) {
    //==> converts the UTC time string to a Date object
    let utcTime = new Date(utcTimeStr);

    //==> extracts minutes from the provided MST time
    let [, mstMinutes] = mstTime.split(':').map(Number);

    //==> adds the MST minutes to the UTC time
    utcTime.setMinutes(utcTime.getMinutes() + mstMinutes);

    //==> converts to ISO string and removes milliseconds
    return utcTime.toISOString().slice(0, -5) + 'Z';
  }

  parseDate(dateString) {
    const format = "yyyy-MM-dd'T'HH:mm:ssX";
    return parse(dateString, format, new Date());
  }

  formatLocalDateAdjust(date) {
    return `${date.toLocaleDateString()}`;
  }

  formatIsoDateAdjust(date) {
    return `${date.getFullYear()}-${this.__formatTwoDigits(
      date.getMonth() + 1,
    )}-${this.__formatTwoDigits(date.getDate())}`;
  }

  __formatTwoDigits(digit) {
    return digit < 10 ? '0' + digit : digit;
  }

  formatDateTimeWithTimeZone = (startTime, timezone) => {
    return this.formatDateTimeWithTimeZoneInFormat(startTime, timezone, 'PPpp zzz');
  };

  formatDateTimeWithTimeZoneInFormat = (startTime, timezone, outputFormat) => {
    const displayTimezone =
      configurationUtil.getEnableTimeZoneFlag() === 1
        ? timezone
        : Intl.DateTimeFormat().resolvedOptions().timeZone;
    const clinicTime = utcToZonedTime(startTime, displayTimezone);

    return format(clinicTime, outputFormat, {
      timeZone: displayTimezone,
      locale: enUS,
    });
  };

  fullFriendlyOutput = (date) => {
    if (!date || typeof date === 'string') return date;
    return this.fullFormatter.format(date);
  };

  fullFriendlyOutputExcludeYear = (date) => {
    if (!date || typeof date === 'string') return date;
    return this.fullFormatter.format(date).replace(` ${date.getFullYear()}`, '');
  };

  longFriendlyOutputExcludeYear = (date) => {
    if (!date || typeof date === 'string') return date;
    return this.longFormatter.format(date).replace(` ${date.getFullYear()}`, '');
  };

  nextStartOfDay = (now) => {
    let tomorrow = new Date(new Date().setDate(now.getDate() + 1));
    tomorrow.setHours(0, 0, 0, 0);
    return tomorrow;
  };
  addhours = (date, number) => {
    let next = new Date(date.getTime());
    next.setHours(date.getHours() + number);
    return next;
  };
  addMinutes = (date, number) => {
    let next = new Date(date.getTime());
    next.setMinutes(date.getMinutes() + number);
    return next;
  };
}

export const dateUtil = new DateUtil();
