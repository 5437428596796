import React, { Component } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles';
import { appointmentApi } from '../../../utils/services/appointments.api';
import { notificationService } from '../../../utils/notification';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  styled,
  TextField,
} from '@mui/material';
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { CancellationBlocBlocEvent } from './cancellation.bloc';

import Typography from '@mui/material/Typography';
import { FormattedMarkdown } from '@decodedhealth/react-library';

const styles = (theme) => ({
  progress: {
    color: theme.palette.secondary.main,
  },
  buttons: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  button: {
    width: '100%',
    padding: '1em',
    textAlign: 'right',
  },
  dialogButton: {
    maxWidth: '150px',
    margin: '1em',
  },
  dialogTitle: {
    color: theme.palette.primary.main,
  },
});

const DEFAULT_REASONS = [
  {
    id: 5,
    description: 'Last Minute Change of Plans',
  },
  {
    id: 4,
    description: 'Wait was Too Long',
  },
  {
    id: 3,
    description: 'Faster Appointment Found Elsewhere',
  },
  {
    id: 2,
    description: 'Went to Emergency Room',
  },
  {
    id: 1,
    description: 'Visit No Longer Needed',
  },
  {
    id: 0,
    description: 'OTHER',
  },
];

class AppointmentCancelDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cancelReasonList: DEFAULT_REASONS,
      cancelDialogOpen: false,
      selectedReasonId: '',
      selectedReasonText: '',
    };
  }

  componentDidMount() {
    this.blocEventSubscription = this.props.bloc.subscribeToEvents(this._handleEvent);
    this.blocStateSubscription = this.props.bloc.subscribeToState(this._handleState);
  }

  componentWillUnmount() {
    this.blocEventSubscription.unsubscribe();
    this.blocStateSubscription.unsubscribe();
  }

  _handleState = (e) => {
    this.setState({
      cancelDialogOpen: e.cancelDialogOpen,
    });
  };

  _handleEvent = (e) => {
    const { type } = e;

    switch (type) {
      case CancellationBlocBlocEvent.APPOINTMENT_CANCELLING:
        this.setState({ loading: true });
        break;
      case CancellationBlocBlocEvent.APPOINTMENT_CANCELLED_SUCCESS:
        this._onCancelDialogClose();
        this.props.onCancelSuccess && this.props.onCancelSuccess();
        break;
      case CancellationBlocBlocEvent.APPOINTMENT_CANCELLED_ERROR:
        this.setState({
          loading: false,
        });
        break;
      default:
        break;
    }
  };

  _formatReasonDescription = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  _onCancelDialogClose = () => {
    this.props.bloc.setCancelDialogOpen(false);

    this.setState({
      cancelReasonList: DEFAULT_REASONS,
      selectedReasonText: '',
      selectedReasonId: '',
      loading: false,
    });
  };

  _handleTextChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.value;

    this.setState(change);
  };

  _handleCancelAppointment = () => {
    let { selectedReasonId, selectedReasonText } = this.state;

    let reason = DEFAULT_REASONS.find((value) => value.id === selectedReasonId);

    const requestData = {
      reason: {
        id: 0,
        description: selectedReasonId === 0 ? selectedReasonText : reason?.description || '',
      },
    };

    this.props.bloc.cancelAppointment(this.props.selectedAppointment, requestData);
  };

  render() {
    const { classes } = this.props;
    const { loading, cancelReasonList, selectedReasonId, selectedReasonText, cancelDialogOpen } =
      this.state;

    return (
      <Dialog
        maxWidth={'md'}
        open={cancelDialogOpen}
        onClose={this._onCancelDialogClose}
        onBackdropClick={this._onCancelDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ValidatorForm autoComplete="off" onSubmit={this._handleCancelAppointment}>
          <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
            <Typography variant="h4" component={'p'}>
              <FormattedMarkdown id={'dialog.cancel.title'} defaultMessage={'Cancel Reservation'} />
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="body2" component={'p'}>
                <FormattedMarkdown
                  id={'dialog.cancel.content'}
                  defaultMessage={
                    'Please specify a reason for cancelling the reservation and confirm.'
                  }
                />
              </Typography>
            </DialogContentText>
            <SelectValidator
              id="selectedReasonId"
              name="selectedReasonId"
              fullWidth
              select
              required
              validators={['required']}
              errorMessages={[
                <FormattedMarkdown
                  id="dialog.cancel.field.error.reason"
                  defaultMessage={'Please select a reason for cancelling.'}
                />,
              ]}
              label="Reason"
              value={selectedReasonId}
              disabled={loading}
              onChange={this._handleTextChange}
            >
              {cancelReasonList.map((option) => {
                const Item = styled(MenuItem)({ '&:hover': { color: '#ffffff' } });
                return (
                  <Item key={option.id} value={option.id}>
                    {this._formatReasonDescription(option.description)}
                  </Item>
                );
              })}
            </SelectValidator>

            {selectedReasonId === 0 && (
              <TextField
                autoFocus
                margin="dense"
                id="selectedReasonText"
                name="selectedReasonText"
                label="Description"
                type="text"
                fullWidth
                required
                value={selectedReasonText}
                onChange={this._handleTextChange}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              type="button"
              variant={'contained'}
              color="secondary"
              className={classes.dialogButton}
              onClick={this._onCancelDialogClose}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant={'contained'}
              color="primary"
              className={classes.dialogButton}
              disabled={loading}
            >
              {loading ? <CircularProgress size="1.5em" /> : 'Confirm'}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AppointmentCancelDialog);
