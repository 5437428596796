import { AnalyticsEvent, analyticsEventLogger } from './events';
import { compareAsc, format, parse } from 'date-fns';
import { DATE_FORMAT } from './date';

export class AppointmentStatus {
  static DRAFT = 'DRAFT';
  static RESERVED = 'RESERVED';
  static PENDING = 'PENDING';
  static WAITING = 'WAITING';
}

class AppointmentIntervalUtil {
  findFirstAvailableSlotForDay = (intervalData, selectedDate) => {
    const now = new Date();
    now.setMinutes(now.getMinutes());

    const schedule = this.constructScheduleData(intervalData, selectedDate);
    const applicableSchedule = this.filterOnlyAvailableForDay(schedule, selectedDate)
      .map((interval) => {
        interval.slots = interval.slots.filter((slot) => slot.startDatetime > now);
        return interval;
      })
      .filter((interval) => interval.slots?.length > 0);
    return applicableSchedule.length > 0 ? applicableSchedule[0] : undefined;
  };
  findFirstAvailableSlotForPeriod = (intervalData, selectedDate, start, end) => {
    const schedule = this.constructScheduleData(intervalData, selectedDate);
    const applicableSchedule = this.filterOnlyAvailableForDay(schedule, selectedDate)
      .map((interval) => {
        interval.slots = interval.slots.filter(
          (slot) => slot.startDatetime > start && slot.startDatetime < end,
        );
        return interval;
      })
      .filter((interval) => interval.slots?.length > 0);
    return applicableSchedule.length > 0 ? applicableSchedule[0] : undefined;
  };

  constructScheduleData = (intervalData, selectedDate) => {
    const compareDate =
      typeof selectedDate === 'object' ? format(selectedDate, DATE_FORMAT) : selectedDate;

    const scheduleData = intervalData
      .map((interval) => {
        const scheduleIntervalHour =
          interval.start instanceof Date
            ? interval.start
            : parse(interval.start, "yyyy-MM-dd'T'HH:mm:ssX", new Date());

        const hourDisplay = `${format(scheduleIntervalHour, 'h aa')}`;

        let slots = [];

        if (interval.availableSlots) {
          interval.availableSlots.forEach((_slot) => {
            const slotStartDateTime = new Date(scheduleIntervalHour);
            slotStartDateTime.setMinutes(_slot.start.split(':')[1], 0, 0);

            slots.push({
              id: _slot.slotId,
              display: `${format(slotStartDateTime, 'hh:mm')} ${format(
                scheduleIntervalHour,
                'aa',
              )}`,
              start: _slot.start,
              startDatetime: slotStartDateTime,
            });
          });
        } else if (interval.slots) {
          interval.slots.forEach((_slot) => {
            const slotStartDateTime = _slot.slotStartDateTime
              ? slotStartDateTime
              : new Date(scheduleIntervalHour);
            slotStartDateTime.setMinutes(_slot.start.split(':')[1], 0, 0);

            slots.push({
              id: _slot.slotId,
              display: `${format(slotStartDateTime, 'hh:mm')} ${format(
                scheduleIntervalHour,
                'aa',
              )}`,
              start: _slot.start,
              startDatetime: slotStartDateTime,
            });
          });
        }

        const startDate = format(scheduleIntervalHour, DATE_FORMAT);

        return {
          start: scheduleIntervalHour,
          startDate: startDate,
          waitTimes: interval.waitTimes.current,
          capacity: interval.capacity,
          parsedTime: scheduleIntervalHour,
          hourDisplay: hourDisplay,
          slots: slots,
        };
      })
      .filter((interval) => {
        return interval.startDate === compareDate;
      })
      .sort((a, b) => compareAsc(a.parsedTime, b.parsedTime));

    return scheduleData;
  };

  loadAvailability = (appointmentApi, start, end, organisationId, service, doctor, isWalkin) => {
    return appointmentApi
      .getAvailableAppointmentScheduleBetweenAnonymous(
        start,
        end,
        organisationId,
        service,
        doctor,
        !isWalkin,
      )
      .then(
        (value) => {
          return value.data.results[0].intervals;
        },
        (reason) => {
          return reason;
        },
      );
  };

  filterOnlyAvailableForDay = (schedulingIntervals, date) => {
    if (schedulingIntervals && Array.isArray(schedulingIntervals)) {
      const start = new Date(date.getTime());
      start.setHours(0, 0, 0, 0);
      const end = new Date(date.getTime());
      end.setHours(23, 59, 59, 999);
      const now = new Date(date.getTime());
      now.setSeconds(0, 0);

      return schedulingIntervals
        .filter((interval) => interval.slots?.length > 0)
        .filter((interval) => this.validInterval(interval, now, start, end));
    } else {
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_ERROR, {
        reason: `not array ${typeof schedulingIntervals}`,
      });
    }

    return [];
  };

  validInterval = (interval, now, start, end) => {
    if (interval.start >= end) return false;

    if (interval.start >= now) return true;

    let compareDate = new Date(interval.start);
    compareDate.setSeconds(0, 0);

    for (let i = 0; i < interval.slots.length; i++) {
      compareDate.setMinutes(interval.slots[i].start.split(':')[1]);
      if (compareDate > now) {
        interval.start = compareDate;
        return true;
      }
    }

    return false;
  };
}

export const appointmentIntervalUtil = new AppointmentIntervalUtil();
