import * as rxjs from 'rxjs';
import { systemApi } from '../utils/services/system.api';
import { notificationService } from '../utils/notification';
import { AnalyticsEvent, analyticsEventLogger } from '../utils/events';
import { logger } from '../utils/logging';

export class GlobalBloc {
  constructor() {
    const savedState = JSON.parse(sessionStorage.getItem('globalState'));

    const initialState = savedState || {
      initialising: true,
      systemProperties: [],
      booking: {},
      orgSelected: false,
      insurances: [],
      kioskRestart: false,
    };

    this.subject = new rxjs.BehaviorSubject(initialState);
    this.events = new rxjs.Subject();
  }

  previousState = null;

  subscribeToEvents = (func) => this.events.subscribe(func);

  subscribeToState = (func) => {
    return this.subject.subscribe((newState) => {
      logger.color('%cGlobal state has changed:', 'color: #E694FC', newState);

      if (this.previousState) {
        const changes = this.getChanges(this.previousState, newState);
        logger.color('%cWhat has changed in global state:', 'color: #FFA500', changes);
      }

      sessionStorage.setItem('globalState', JSON.stringify(newState));

      this.previousState = newState;

      func(newState);
    });
  };

  initialise = () => {
    this.__loadProperties();
  };

  makeInitialised = () => {
    this.subject.next({
      ...this.subject.value,
      initialising: false,
    });
  };

  __loadProperties = () => {
    systemApi.systemProperties().then(
      (value) => {
        analyticsEventLogger.log(AnalyticsEvent.SYSTEM_PROPERTIES_LOADED_SUCCESS);

        const systemProperties = value.data.items;
        this.subject.next({
          ...this.subject.value,
          initialising: false,
          systemProperties: systemProperties,
        });
      },
      (reason) => {
        analyticsEventLogger.log(AnalyticsEvent.SYSTEM_PROPERTIES_LOADED_ERROR, {
          reason: reason,
        });
        notificationService.error('Unable to load system properties. Please retry later.');
      },
    );
  };

  quinnChat = () => {
    const values = this.subject.value.systemProperties.filter(
      (_property) => _property.code === 'quinn.chat',
    );
    if (values.length === 0) {
      return true;
    }

    return values[0].status === '1';
  };

  fullScheduledAppointmentBooking = () => {
    const values = this.subject.value.systemProperties.filter(
      (_property) => _property.code === 'interaction.appointment.schedule.type',
    );
    if (values.length === 0) {
      return 'quick';
    }

    return values[0].status;
  };

  objective = () => {
    return this.subject.value.objective;
  };

  updateBooking(newBooking) {
    this.subject.next({
      ...this.subject.value, // Spread existing state
      booking: {
        ...this.subject.value.booking, // Spread existing booking state
        ...newBooking, // Merge with new booking state
      },
    });
  }

  updateGlobalBloc(update) {
    this.subject.next({
      ...this.subject.value,
      ...update,
    });
  }

  getChanges(prev, current) {
    const changes = {};
    for (const key in current) {
      if (prev[key] !== current[key]) {
        changes[key] = {
          oldValue: prev[key],
          newValue: current[key],
        };
      }
    }
    return changes;
  }
}

export class GlobalBlocEvent {}

export const globalBloc = new GlobalBloc();
