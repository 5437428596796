import { Card, CardContent, CardHeader } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { providerUtil } from '../../../utils/provider';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { serviceUtil } from '../../../utils/service';

const useStyles = makeStyles((theme) => ({
  fill: {
    flex: '1 1 auto',
  },
  card: {
    width: '320px',
    minWidth: '320px',
    maxWidth: '320px',
    margin: '12px 24px 12px 0',
    backgroundColor: '#F6F6F6',
    border: '0 solid #ffffff',
    borderRadius: '6px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '85vw',
      minWidth: '85vw',
      margin: '0',
    },
  },

  cardHeader: {},

  cardHeaderTitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '32px',
    color: '#000000',
  },

  cardHeaderTitleSubtitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '32px',
    color: '#777777',
  },
  cardSelectedHeaderTitleSubtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#FFF',
  },
  cardBody: {
    paddingTop: '24px',
    paddingBottom: '6px !important',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0',
      paddingBottom: '0',
    },
  },

  cardBodyLine: {
    display: 'flex',
    marginBottom: '14px',
  },

  cardBodyLineIcon: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#5C5D5F',
  },
  cardSelectedBodyLineIcon: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFF',
  },
  cardBodyLineText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#5C5D5F',
    paddingLeft: '18px',
  },
  cardSelectedBodyLineText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFF',
    paddingLeft: '18px',
  },

  buttonHolder: {
    marginTop: '18px',
  },
  button: {
    [theme.breakpoints.up('xs')]: {
      width: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  iconLarge: {
    fontSize: '18px',
  },
  iconLargeWhite: {
    fontSize: '18px',
    color: '#fff',
  },
  documents: {
    paddingTop: '14px',
    paddingBottom: '14px',
    lineHeight: '14px',
  },
  checkBox: {
    transform: 'scale(1.5)',
    marginRight: '1em',
    marginLeft: '0.5em',
  },
  locationTerm: {
    color: theme.palette.primary.main,
  },
}));

export const AppointmentCard = ({ appointment, organisation, visitTime }) => {
  const classes = useStyles();
  const serviceName = serviceUtil.friendlyNameSync(appointment.service);

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        classes={{
          title: classes.cardHeaderTitle,
          subheader: classes.cardHeaderTitleSubtitle,
        }}
        className={classes.cardHeader}
        title={serviceName}
        subheader={`at ${organisation.name}`}
      />
      <CardContent className={classes.cardBody}>
        <div className={classes.cardBodyLine}>
          <span className={classes.cardBodyLineIcon}>
            <LocationOnIcon
              classes={{
                fontSizeLarge: classes.iconLarge,
              }}
              fontSize={'large'}
              color={'accent'}
            />
          </span>
          <span className={classes.cardBodyLineText}>
            {appointment.type === 'VIRTUAL'
              ? 'Virtual'
              : providerUtil.formatOrganisationAddress(organisation)}
          </span>
        </div>
        <div className={classes.cardBodyLine}>
          <span className={classes.cardBodyLineIcon}>
            <QueryBuilderIcon
              classes={{
                fontSizeLarge: classes.iconLarge,
              }}
              fontSize={'large'}
              color={'accent'}
            />
          </span>
          <span className={classes.cardBodyLineText}>{visitTime}</span>
        </div>
        <div className={classes.cardBodyLine}></div>
      </CardContent>
    </Card>
  );
};
