import dukecity from './dukecity.json';

const dukecityMessages = process.env.NODE_ENV !== 'production' ? dukecity : {};

export const messages =
  process.env.NODE_ENV !== 'production'
    ? {
        ...dukecityMessages,
      }
    : {
        ...process.env.REACT_APP_TENANT_CONTENTS,
      };
