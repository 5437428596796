import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Button, Grid, styled} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {PrimaryButton} from "../../shared-library/components/buttons/PrimaryButton";


export const DecodedButtonBase = styled(Button)(({ theme, sx }) => ({
  ...sx,
  padding: 0,
    '& p': {
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
    [theme.breakpoints.down('md')]: {
        minWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
    }
}));

export const DecodedButton = ({ onClick, sx, size, loading, disabled, children, component, type, variant }) => (
<DecodedButtonBase
    type={type ? type : 'button'}
    variant={variant ? variant : "contained"}
    color="primary"
    fullWidth
    onClick={ (e) => {
        if(!loading && !disabled) {
            onClick(e);
        }
        e.preventDefault();
    }}
    sx={sx}
    size={size}
    disabled={disabled}
    component={component}
>
  { !loading ? children : <CircularProgress size={ size === "small" ? 16 : 24}  /> }
</DecodedButtonBase>
);

export const DecodedSecondaryButton = ({ onClick, sx, size, loading, disabled, children }) => (
<DecodedButtonBase
    type="button"
    variant="outlined"
    color="primary"
    fullWidth
    onClick={ (e) => {
        if(!loading && !disabled) {
            onClick(e);
        }
        e.preventDefault();
    }}
    sx={sx}
    size={size}
    disabled={disabled}
>
  { !loading ? children : <CircularProgress size={ size === "small" ? 16 : 24}  /> }
</DecodedButtonBase>
);


export const DecodedSubmitButton = ({ id, sx, size, loading, disabled, children }) => (
<DecodedButtonBase
    id={id}
    type="submit"
    variant="contained"
    color="primary"
    fullWidth
    sx={sx}
    size={size}
    disabled={disabled}
>
  { !loading ? children : <CircularProgress size={ size === "small" ? 16 : 24}  /> }
</DecodedButtonBase>
);

export default DecodedButton;
