import { DecodedBloc } from '../shared/DecodedComponent/bloc';
import { consumerApi } from '../../utils/services/consumers.api';
import { userInfoUtil } from '../../utils/user';
import { phoneUtil } from '../../utils/phone';
import { registrationApi } from '../../utils/services/register.api';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { notificationService } from '../../utils/notification';
import { errorResolver } from '../../utils/error.resolver';
import { authService } from '../../utils/auth';

export class Bloc extends DecodedBloc {
  loginDetails;

  constructor(props) {
    const action = sessionStorage.getItem('action');
    const registrationType = sessionStorage.getItem('registrationType');
    let extra = {};
    const loginDetails = JSON.parse(sessionStorage.getItem('loginDetails'));
    if (loginDetails) extra.loginDetails = loginDetails;

    super({ ...props, ...extra, action: action, registrationType });

    this.loginDetails = loginDetails;
  }

  __initialise = () => {
    this.__updateSubject({ loading: true });
    const { action, loginDetails, registrationType } = this.subject.value;

    if (action === 'registration.only' && registrationType === 'UC') {
      return consumerApi
        .getPersonSummary()
        .then(() => {
          this.__updateSubject({ registrationException: true });
        })
        .catch((e) => loginDetails)
        .finally(() => {
          this.__makeInitialised({});
        });
    }
    const payload = Object.assign({}, this.loginDetails, {
      number: phoneUtil.stripCharacters(this.loginDetails.number),
      registrationType,
    });

    return registrationApi
      .match_patient(payload)
      .then((results) => {
        if (Object.keys(results.data).length !== 0 && results.data.firstname !== '') {
          const athenaPatient = results.data;
          const user = {
            externalId: athenaPatient.patientid,
            firstName: athenaPatient.firstname,
            lastName: athenaPatient.lastname,
            dateOfBirth: athenaPatient.dob,
            email: athenaPatient.email,
            number: this.__formatNumber(athenaPatient.mobilephone),
            gender: athenaPatient.sex,
            acceptTerms: athenaPatient.privacyinformationverified,
            departmentId: athenaPatient.departmentid,
            addressLine1: athenaPatient.address1,
            addressLine2: athenaPatient.address2,
            addressAdministrativeArea: athenaPatient.state,
            addressCity: athenaPatient.city,
            addressPostcode: athenaPatient.zip,
            addressCountry: athenaPatient.countrycode3166,
            emergency: this.__mapEmergencyData(athenaPatient),
            driversLicense: athenaPatient.driverslicense,
            insurances: this.__mapInsurances(athenaPatient.insurances),
          };
          this.__updateSubject({ userExists: true, user: user });
          return user;
        }
        const user = {
          externalId: '',
          firstName: loginDetails.firstName,
          lastName: loginDetails.lastName,
          dateOfBirth: loginDetails.dateOfBirth,
          email: loginDetails.email,
          number: loginDetails.number,
          gender: loginDetails.gender,
          acceptTerms: false,
          addressLine1: '',
          addressLine2: '',
          addressAdministrativeArea: '',
          addressCity: '',
          addressPostcode: '',
          addressCountry: '',
        };
        this.__updateSubject({ userExists: false, user: user });
        return user;
      })
      .catch((e) => this.__updateSubject({ registrationException: true }))
      .finally(() => {
        this.__makeInitialised({});
      });
  };

  __mapInsurances = (insurances = []) => {
    return insurances
      .filter((i) => i['insurancepackageid'] > 0)
      .map((i) => {
        const result = {};
        result['primary'] = i['sequencenumber'];
        result['order'] = i['sequencenumber'];
        result['type'] = { code: '1' };
        result['insuranceInformation'] = {
          plan: {
            name: i['insuranceplanname'],
          },
          memberId: i['insuranceidnumber'],
          members: [
            {
              role: i['relationshiptoinsuredid'] === '1' ? 'POLICY_HOLDER' : '',
            },
          ],
        };
        if (i['policynumber']) {
          result['insuranceInformation']['groupId'] = i['policynumber'];
        }

        return result;
      });
  };

  __mapEmergencyData = (data) => {
    if (data.contactname) {
      const nameComponents = data?.contactname.split(' ');
      return {
        firstName: nameComponents[0],
        lastName: nameComponents[1],
        phoneNumber: this.__formatNumber(data.contactmobilephone),
        relationship: data.contactrelationship,
      };
    }

    return {
      relationship: 'SELF',
    };
  };

  __formatNumber = (number) => {
    const area = number.slice(0, 3);
    const prefix = number.slice(3, 6);
    const line = number.slice(6);

    return `(${area}) ${prefix}-${line}`;
  };

  __createAccountRequest = (stateInfo) => {
    let {
      firstName,
      lastName,
      gender,
      dateOfBirth,
      ssn,
      acceptTerms,
      number,
      email,
      code,
      codeCountry,
      idVerificationReference,
      addressLine1,
      addressLine2,
      addressCity,
      addressAdministrativeArea,
      addressPostcode,
      addressCountry,
      race,
      ethnicity,
      hearAboutUs,
      emergencyData,
    } = stateInfo;

    let externalReferences = [];

    if (ssn) {
      externalReferences.push({
        code: 'SS',
        reference: ssn,
      });
    }

    if (idVerificationReference) {
      externalReferences.push({
        code: 'verificationId',
        reference: idVerificationReference,
      });
    }

    let properties = [];
    if (hearAboutUs) {
      properties.push({
        code: 'marketingChannel',
        value: hearAboutUs,
      });
    }
    let demographic = [];
    if (race)
      demographic.push({
        code: 'race',
        value: race,
      });
    if (ethnicity)
      demographic.push({
        code: 'ethnicity',
        value: ethnicity,
      });

    const emergency = this.__createEmergencyContactRequest(emergencyData);
    const responsibleParty = this.__createGuarantorRequest(stateInfo.responsibleParty);

    return {
      code: 'enrollment.register',
      type: 'client',
      payload: {
        name: userInfoUtil.formatName(firstName, lastName),
        dob: userInfoUtil.formatDate(dateOfBirth),
        gender: gender,
        externalReferences: externalReferences,
        contactDetails: {
          address: {
            type: '',
            line1: addressLine1,
            line2: addressLine2,
            line3: '',
            city: addressCity,
            postcode: addressPostcode,
            administrativeArea: addressAdministrativeArea,
            country: addressCountry,
          },
          number: phoneUtil.formatPhoneNumberForRegistration(number, code, codeCountry),
          email: email,
        },
        acceptTerms: acceptTerms,
        communicationConsent: acceptTerms,
        acceptLocationTerm: true,
        emergency,
        guarantor: responsibleParty,
        demographic: demographic,
        properties: properties,
      },
    };
  };

  __createEmergencyContactRequest = (emergencyData) => {
    if (!emergencyData?.relationship) {
      return { relationship: 'SELF' };
    }

    const { relationship, firstName, lastName, phoneNumber, address, city, postCode, state } =
      emergencyData;

    if (relationship === 'SELF') return { relationship: relationship };

    return {
      relationship: relationship,
      name: userInfoUtil.formatName(firstName, lastName),
      contactNumber: phoneUtil.formatPhoneNumberForRegistration(phoneNumber, '+1', 'US'),
      address: {
        type: '',
        line1: address,
        line2: '',
        line3: '',
        city: city,
        postcode: postCode,
        administrativeArea: state,
        country: 'US',
      },
    };
  };

  __createGuarantorRequest = (guarantorData) => {
    if (!guarantorData?.relationship) {
      return { relationship: 'SELF' };
    }

    const {
      relationship,
      firstName,
      lastName,
      phoneNumber,
      address,
      city,
      postCode,
      state,
      gender,
      dateOfBirth,
    } = guarantorData;

    if (relationship === 'SELF') return { relationship: relationship };

    return {
      relationship: relationship,
      name: userInfoUtil.formatName(firstName, lastName),
      dob: dateOfBirth ? userInfoUtil.formatDate(dateOfBirth) : null,
      gender: gender,
      contactNumber: phoneUtil.formatPhoneNumberForRegistration(phoneNumber, '+1', 'US'),
      address: {
        type: '',
        line1: address,
        line2: '',
        line3: '',
        city: city,
        postcode: postCode,
        administrativeArea: state,
        country: 'US',
      },
    };
  };

  __addInsuranceExternalPlanId = (insurances) => {
    const { availablePlans } = this.insurance.subject.value;

    insurances.forEach((i) => {
      availablePlans
        .filter(
          (ap) =>
            i.type.code === '1' &&
            i.insuranceInformation &&
            ap.plan_id === i.insuranceInformation.plan.id,
        )
        .forEach((ap) => (i.insuranceInformation.plan.id = ap.external_plan_id));
    });

    return insurances;
  };

  __submitRegistration = (formRequest, rawData, registrationType) => {
    const { userExists } = this.subject.value;

    if (registrationType === 'PC') {
      const data = {
        idVerificationReference: rawData.idVerificationReference,
        externalId: rawData.externalId,
        firstName: rawData.firstName,
        lastName: rawData.lastName,
        dateOfBirth: rawData.dateOfBirth,
        email: rawData.email,
        gender: rawData.gender,
        ssn: rawData.ssn,
        addressLine1: rawData.addressLine1,
        addressLine2: rawData.addressLine2,
        addressAdministrativeArea: rawData.addressAdministrativeArea,
        addressCity: rawData.addressCity,
        addressPostcode: rawData.addressPostcode,
        addressCountry: rawData.addressCountry,
        number: rawData.number,
      };

      if (rawData.emergency.relationship === 'SELF') {
        rawData.emergency.firstName = '';
        rawData.emergency.lastName = '';
        rawData.emergency.phoneNumber = '';
      } else {
        rawData.emergency.phoneNumber = phoneUtil.stripCharacters(rawData.emergency.phoneNumber);
      }
      data.emergency = rawData.emergency;

      const newState = { ...data };
      const { insurances, useExternalInsurance } = this.insurance.subject.value;

      if (!useExternalInsurance) {
        newState['insurances'] = this.__addInsuranceExternalPlanId(insurances);
      }

      return registrationApi
        .registerExternal({
          newState,
          userExists,
          registrationType,
        })
        .then(() => {
          analyticsEventLogger.log(AnalyticsEvent.REGISTER_SUCCESS);
          this.__updateSubject({ registrationSuccess: true });
        })
        .catch((error) => {
          analyticsEventLogger.log(AnalyticsEvent.REGISTER_ERROR, {
            reason: error,
          });
          this.__updateSubject({ registrationFailed: true });
          notificationService.error(errorResolver.resolveRegistrationErrorDisplay(error));
        })
        .finally(() => {
          this.__updateSubject({ loading: false });
        });
    }

    if (registrationType === 'UC') {
      return registrationApi
        .register(formRequest)
        .then((value) => {
          authService
            .loginWithToken(value.data.token)
            .then((value) => {
              analyticsEventLogger.log(AnalyticsEvent.REGISTER_SUCCESS);
              this.__updateSubject({ registrationSuccess: true });
            })
            .catch((error) => {
              analyticsEventLogger.log(AnalyticsEvent.REGISTER_AUTH_ERROR, {
                reason: `${error}`,
              });

              notificationService.error(errorResolver.resolveAuthErrorDisplay(error));
              this.__updateSubject({
                registrationFailed: true,
                loading: false,
              });
            });
        })
        .catch((error) => {
          analyticsEventLogger.log(AnalyticsEvent.REGISTER_ERROR, {
            reason: error,
          });

          notificationService.error(errorResolver.resolveRegistrationErrorDisplay(error));
          this.__updateSubject({ registrationFailed: true });
        })
        .finally(() => {
          this.__updateSubject({ loading: false });
        });
    }
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
