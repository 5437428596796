import React, { Component } from 'react';

import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import PageContainer from '../../common/PageContainer';
import { Box, darken, Fab, Grid, Tooltip } from '@mui/material';
import {
  DefaultDisconnectedPageFooter,
  PagePanel,
  ScrollableBox,
  ScrollableWrapper,
} from '../../common/ScrollableContainer';
import DecodedButton from '../../common/DecodedButton';
import { providerStorage } from '../../../utils/provider.qs';
import { CaptureCardImage } from '@decodedhealth/react-component-lib';
import IconButton from '@mui/material/IconButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const styles = (theme) => ({
  fab: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: darken(theme.palette.secondary.main, 0.1),
    },
    '&:focus': {
      backgroundColor: darken(theme.palette.secondary.main, 0.1),
    },
  },
});

class TakeImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      image: props.initialImage,
      cameras: 0,
      allowUpload: !providerStorage.isKiosk(),
      allowSwitch: !providerStorage.isKiosk(),
      facingMode: providerStorage.isKiosk() ? 'user' : 'environment',
      triggerCapture: false,
      triggerUpload: false,
      isSm: window.matchMedia('(max-width: 600px)').matches,
    };
  }

  updateMedia = () => {
    this.setState({ isSm: window.matchMedia('(max-width: 600px)').matches });
  };

  componentDidMount() {
    this.updateMedia();
    window.addEventListener('resize', this.updateMedia);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateMedia);
  }

  next = async () => {
    this.setState({ loading: true });
    this.props.onSubmit(() => this.setState({ loading: false }));
  };

  handleCapture = (imageSrc) => {
    this.setState((prevState) => ({
      triggerCapture: false,
      image: imageSrc,
    }));
    this.props.handleScanCapture({ dataURL: imageSrc });
  };

  handleClick = () => {
    this.setState((prevState) => ({
      triggerCapture: true,
    }));
  };

  handleClickUpload = () => {
    this.setState((prevState) => ({
      triggerUpload: true,
    }));
  };

  resetUploadTrigger = () => {
    this.setState((prevState) => ({
      triggerUpload: false,
    }));
  };

  handleClickRestart = () => {
    this.setState((prevState) => ({
      image: null,
    }));
  };

  handleClickFlip = () => {
    if (this.state.facingMode === 'user') {
      this.setState((prevState) => ({
        facingMode: 'environment',
      }));
    } else {
      this.setState((prevState) => ({
        facingMode: 'user',
      }));
    }
  };

  render() {
    let { title, classes } = this.props;

    let {
      loading,
      image,
      allowSwitch,
      allowUpload,
      isSm,
      facingMode,
      triggerCapture,
      triggerUpload,
    } = this.state;

    return (
      <PageContainer loading={loading} onBack={this.props.handleBack}>
        <ScrollableWrapper>
          <ScrollableBox sx={{ overflowX: 'hidden', overflowY: isSm ? 'hidden' : 'auto' }}>
            <PagePanel>
              {title}
              <Grid container spacing={3} sx={{ paddingBottom: '24px' }}>
                <Grid item xs={12} sm={12} sx={{ position: 'relative !important' }}>
                  <Box
                    sx={{
                      position: 'relative',
                      height: isSm ? '45%' : 'auto',
                      width: isSm ? '87vw' : '650px',
                    }}
                  >
                    <CaptureCardImage
                      triggerCapture={triggerCapture}
                      onCapture={this.handleCapture}
                      captureImage={image}
                      triggerUpload={triggerUpload}
                      resetUploadTrigger={this.resetUploadTrigger}
                      facingMode={facingMode}
                      height={isSm ? '100vh' : 400}
                    />
                    {allowSwitch && (
                      <Fab
                        className={classes.fab}
                        size={isSm ? 'small' : 'large'}
                        sx={{
                          position: 'absolute',
                          bottom: isSm ? '18%' : 10,
                          right: isSm ? '5%' : 10,
                        }}
                      >
                        <Tooltip title="Flip Camera">
                          <IconButton onClick={this.handleClickFlip}>
                            <FlipCameraIosIcon
                              sx={{ fontSize: isSm ? '1.8rem' : '2.5rem', color: 'white' }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Fab>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </PagePanel>
          </ScrollableBox>
        </ScrollableWrapper>
        <DefaultDisconnectedPageFooter>
          <Grid item xs={12} sm={6}>
            <label htmlFor="contained-button-file">
              {image ? (
                <DecodedButton
                  id={'select-image1'}
                  onClick={this.handleClickRestart}
                  variant="outlined"
                  color="primary"
                  component="span"
                >
                  <FormattedMessage
                    id="component.takeimage.recapture.button"
                    defaultMessage={'RETAKE IMAGE'}
                  />
                </DecodedButton>
              ) : (
                <DecodedButton
                  id={'select-image1'}
                  onClick={this.handleClick}
                  variant="contained"
                  color="primary"
                  component="span"
                >
                  <CameraAltIcon fontSize="large" />
                  &nbsp;
                  <FormattedMessage
                    id="component.takeimage.capture.button"
                    defaultMessage={'CAPTURE'}
                  />
                </DecodedButton>
              )}
            </label>
          </Grid>
          <Grid item xs={12} sm={6}>
            {image ? (
              <DecodedButton disabled={!image || loading} onClick={this.next}>
                <FormattedMessage id="component.takeimage.next.button" defaultMessage={'NEXT'} />
              </DecodedButton>
            ) : allowUpload ? (
              <DecodedButton variant="outlined" onClick={this.handleClickUpload}>
                <FormattedMessage id="component.takeimage.next.button" defaultMessage={'UPLOAD'} />
              </DecodedButton>
            ) : (
              <DecodedButton disabled={!image || loading} onClick={this.next}>
                <FormattedMessage id="component.takeimage.next.button" defaultMessage={'NEXT'} />
              </DecodedButton>
            )}
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(TakeImage);
