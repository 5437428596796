import React, { Component } from 'react';

import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import { authService } from '../../utils/auth';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { FormattedMessage } from 'react-intl';

import CircularProgress from '@mui/material/CircularProgress';
import { Grid, styled } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { DateMask, SsnTextMask } from '../shared/InputTextMask';
import { GENDER_MANDATORY_OPTIONS } from '../../utils/user';
import Typography from '@mui/material/Typography';
import ChatBubble from '../shared/ChatBubble';
import PageContainer from '../common/PageContainer';
import { PhoneNumber } from '../shared/PhoneNumber/PhoneNumber';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import {
  PageContent,
  PageFooter,
  ScrollableBox,
  ScrollableQuinnContainer,
  ScrollableWrapper,
} from '../common/ScrollableContainer';
import { notificationService } from '../../utils/notification';
import { TextH5Standard } from '../shared/Typography';

const styles = (theme) => ({
  validation: {
    display: 'contents',
    width: '100%',
  },
  title: {
    paddingTop: '1em',
    color: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
  },
  formInput: {
    flex: '1',
  },
  buttonGroup: {
    paddingTop: '2em',
  },
  button: {
    width: '100%',
    padding: '1em',
  },
});

class BasicInformation extends Component {
  constructor(props) {
    super(props);
  }

  isLoading = () => {
    return this.props.loading || authService.isLoading();
  };

  __handleNext = (e) => {
    let { email, handleNext } = this.props;

    if (email?.length > 3) {
      handleNext(e);
    } else {
      notificationService.error('Please check your email address.');
    }
  };

  render() {
    let {
      classes,
      loading,
      firstName,
      lastName,
      gender,
      ssn,
      dateOfBirth,
      number,
      email,
      code,
      handleTextChange,
      onCodeChange,
      handleGenderChange,
      handleBack,
    } = this.props;

    return (
      <PageContainer loading={loading}>
        <ScrollableQuinnContainer
          messageId="registration.user.bubble.one"
          message="Please enter or verify the PATIENT information below and click NEXT."
        >
          <ValidatorForm
            autoComplete="off"
            onSubmit={this.__handleNext}
            className={classes.validation}
          >
            <PageContent>
              <div className={classes.formInput}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextH5Standard orange sx={{ paddingTop: '1em' }}>
                      <FormattedMarkdown
                        id="registration.user.step.one"
                        defaultMessage="1 of 2 Personal Information"
                      />
                    </TextH5Standard>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      required
                      fullWidth
                      autoFocus={true}
                      id="firstName"
                      label={
                        <FormattedMessage
                          id="registration.field.label.firstName"
                          defaultMessage={'Patients First Name'}
                        />
                      }
                      name="firstName"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      value={firstName}
                      validators={['isValidName']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.patient.name.given"
                          defaultMessage={'Invalid name.'}
                        />,
                      ]}
                      onChange={handleTextChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      required
                      fullWidth
                      id="lastName"
                      label={
                        <FormattedMessage
                          id="registration.field.label.lastName"
                          defaultMessage={'Patients Last Name'}
                        />
                      }
                      type="text"
                      name="lastName"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      value={lastName}
                      validators={['isValidName']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.patient.name.family"
                          defaultMessage={'Invalid name.'}
                        />,
                      ]}
                      onChange={handleTextChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <SelectValidator
                      id="gender"
                      fullWidth
                      select
                      required
                      label={
                        <FormattedMessage
                          id="registration.field.label.sex.at.birth"
                          defaultMessage={'Patient sex at birth'}
                        />
                      }
                      value={gender}
                      onChange={handleGenderChange}
                      helperText={
                        <FormattedMessage
                          id="registration.field.helper.gender"
                          defaultMessage={'Sex at birth'}
                        />
                      }
                      validators={['required']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.gender"
                          defaultMessage={'This field is required'}
                        />,
                      ]}
                    >
                      {GENDER_MANDATORY_OPTIONS.map((option) => {
                        const Item = styled(MenuItem)({ '&:hover': { color: '#ffffff' } });
                        return (
                          <Item key={option.value} value={option.value}>
                            {option.label}
                          </Item>
                        );
                      })}
                    </SelectValidator>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      required
                      fullWidth
                      id="dateOfBirth"
                      label={
                        <FormattedMessage
                          id="registration.field.label.dob"
                          defaultMessage={'Patients Date of Birth'}
                        />
                      }
                      name="dateOfBirth"
                      placeholder="MM/dd/yyyy e.g. 12/31/2000"
                      autoComplete="bday"
                      value={dateOfBirth}
                      onChange={handleTextChange}
                      validators={['isDateValid']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.dob"
                          defaultMessage={'Invalid date of birth'}
                        />,
                      ]}
                      InputProps={{
                        inputComponent: DateMask,
                      }}
                      inputProps={{ inputMode: 'numeric' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      required
                      fullWidth
                      id="email"
                      label={
                        <FormattedMessage
                          id="registration.field.label.email"
                          defaultMessage={'Patients Email Address'}
                        />
                      }
                      name="email"
                      type="email"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                        inputMode: 'email',
                      }}
                      value={email}
                      onChange={handleTextChange}
                      validators={['isEmail']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.email"
                          defaultMessage={'email is not valid'}
                        />,
                      ]}
                    />
                  </Grid>

                  <Grid container item xs={12} sm={12}>
                    <PhoneNumber
                      numberLabel={
                        <FormattedMessage
                          id="registration.field.label.mobile"
                          defaultMessage={'Patients Mobile Number'}
                        />
                      }
                      numberValue={number}
                      numberRequired
                      onNumberChange={handleTextChange}
                      codeRequired
                      codeValue={{ callingCode: code, code: this.props.codeCountry }}
                      onCodeChange={(e) => {
                        const codeValues = e.target.value;
                        onCodeChange(
                          codeValues.callingCode,
                          this.props.addressLine1 === '' ? codeValues.code : undefined,
                          codeValues.code,
                        );
                      }}
                      selectedCountry={this.props.codeCountry}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      id="ssn"
                      name="ssn"
                      label={
                        <FormattedMessage
                          id="registration.field.label.ssn"
                          defaultMessage={'Patients SSN (optional)'}
                        />
                      }
                      inputMode="numeric"
                      fullWidth
                      placeholder="e.g. 856-45-6789"
                      value={ssn}
                      onChange={handleTextChange}
                      validators={[
                        'matchRegexp:(^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$)',
                      ]}
                      errorMessages={[
                        <FormattedMarkdown
                          id="registration.field.error.ssn"
                          defaultMessage={'Invalid social security number'}
                        />,
                      ]}
                      InputProps={{
                        inputComponent: SsnTextMask,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </PageContent>
            <PageFooter>
              <Grid item xs={12} sm={6} className={classes.button}>
                <Button type="submit" variant="contained" color="primary" disabled={loading}>
                  {this.isLoading() ? (
                    <CircularProgress size="1.5em" />
                  ) : (
                    <FormattedMessage id="registration.button.label.next" defaultMessage="NEXT" />
                  )}
                </Button>
              </Grid>
            </PageFooter>
          </ValidatorForm>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(BasicInformation);
