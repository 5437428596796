import * as rxjs from 'rxjs';
import { notificationService } from '../../../../../utils/notification';
import { insurancesApi } from '../../../../../utils/services/insurances.api';
import { DecodedBloc } from '../../../../shared/DecodedComponent/bloc';

const initial = {
  initialised: false,
  step: 0,
};

export const defaultPlanTypes = [
  "I'm not sure",
  'EPO',
  'FFS',
  'HMO',
  'Indemnity',
  'Medicare Supplement',
  'PFFS',
  'POS',
  'POS II',
  'PPO',
  'QPOS',
];

const blankPolicyHolder = {
  relationship: 'SELF',
  firstName: '',
  lastName: '',
  gender: '',
  dateOfBirth: '',
  phoneNumber: '',
  address: '',
  city: '',
  postCode: '',
  state: '',
  country: 'US',
};

const insuranceCard = {
  reference: '',
  backImageBlob: undefined,
  frontImageBlob: undefined,
};

const defaultForm = {
  memberId: '',
  provider: '',
  planType: '',
  userPlan: '',
  customUserPlanName: '',
  policyHolder: {
    ...blankPolicyHolder,
  },
  attachments: [],
};

export class Bloc extends DecodedBloc {
  parent;
  closeCallback;

  constructor(parent, props) {
    super({
      ...initial,
      ...props,
      relationshipCodes: parent.subject.value.relationshipCodes,
      form: {
        memberId: '',
        provider: '',
        planType: '',
        userPlan: '',
        customUserPlanName: '',
        policyHolder: {
          ...blankPolicyHolder,
        },
        attachments: [],
      },
    });

    this.parent = parent;
    this.events = new rxjs.Subject();
  }

  initialise = () => {
    insurancesApi
      .getProviders()
      .then((value) => {
        let providerList = value.data.items.map((item) => {
          if (item.providerName.includes('~')) {
            item.label = item.providerName.split('~')[1];
          } else {
            item.label = item.providerName;
          }
          return item;
        });

        this.__updateSubject({ providerList: providerList });
      })
      .catch((reason) => {
        notificationService.error('Error loading insurance providers. Error - ' + reason);
      })
      .finally(() => {
        this.__updateSubject({ initialising: false });
      });
  };

  handleTextChange = (target, value) => {
    let { form } = this.subject.value;
    form[target] = value;
    this.__updateSubject({ form: form });
  };

  setProvider = (newValue) => {
    let { form } = this.subject.value;

    form.provider = newValue;
    form.planType = '';
    form.userPlan = '';

    this.__updateSubject({
      planTypeList: defaultPlanTypes,
      planList: [],
      form: form,
    });
  };

  setPlanType = (newValue) => {
    let { form, providerList } = this.subject.value;

    form.planType = newValue;
    form.userPlan = '';

    this.__updateSubject({ planLoading: true, form: form, planList: [] });

    const selectedProvider = providerList.find((item) => item.label === form.provider);

    let request = {
      providerName: selectedProvider.providerName.toLowerCase(),
      memberId: form.memberId,
      planType: newValue.toLowerCase(),
    };

    insurancesApi
      .searchPlan(request)
      .then((value) => {
        this.__updateSubject({
          planList: value.data.items,
        });
      })
      .catch((reason) => {
        notificationService.error('Error searching for insurance plans. Error - ' + reason);
      })
      .finally(() => {
        this.__updateSubject({
          planLoading: false,
        });
      });
  };

  setPlan = (newValue) => {
    let { form } = this.subject.value;

    form.userPlan = newValue;

    this.__updateSubject({ form: form });
  };

  handlePolicyHolderTextChange = (target, value) => {
    let { form } = this.subject.value;
    form.policyHolder[target] = value;
    this.__updateSubject({ form: form });
  };

  setInsuranceCardRef = (ref, front, back) => {
    const { form } = this.subject.value;

    form.attachments = [
      {
        reference: {
          code: 'customerCardURI',
          system: 'decoded/insurance',
          value: ref,
        },
      },
    ];

    this.__updateSubject({
      form: form,
      frontImageBlob: front,
      backImageBlob: back,
    });
  };
  handlePolicyHolderAddressChange = (address) => {
    const { form } = this.subject.value;

    form.policyHolder['address'] = address.addressLine1;
    form.policyHolder['city'] = address.addressCity;
    form.policyHolder['postcode'] = address.addressPostcode;
    form.policyHolder['state'] = address.addressAdministrativeArea;
    form.policyHolder['country'] = address.addressCountry;
    this.__updateSubject({ form: form });
  };

  registerSteps = (steps) => {
    let { form } = this.subject.value;

    this.__updateSubject({
      steps: steps,
      step: 0,
      form: {
        memberId: form.memberId || '',
        provider: form.provider || '',
        planType: form.planType || '',
        userPlan: form.userPlan || '',
        customUserPlanName: form.customUserPlanName || '',
        policyHolder: { ...(form.policyHolder || blankPolicyHolder) },

        attachments: [],
      },
    });
  };

  next = (context) => {
    const { step } = this.subject.value;

    if (context.isLastStep) {
      this.saveAndClose();
    } else {
      const nextStep = step + 1;
      context.goStepByIndex(nextStep);
      this.__updateSubject({ step: nextStep });
    }
  };

  back = (context) => {
    const { step } = this.subject.value;
    if (step === 0) {
      this.registerSteps([]);
    } else {
      const nextStep = step - 1;
      context.goStepByIndex(nextStep);
      this.__updateSubject({ step: nextStep });
    }
  };

  saveAndClose = () => {
    const { form } = this.subject.value;
    this.parent.appendInsurance(form);
    this.close();
  };

  registerCloseCallback = (callback) => {
    this.closeCallback = callback;
  };
  registerUnknownCallback = (callback) => {
    this.unknownCallback = callback;
  };

  showUnknown = () => {
    this.unknownCallback();
  };

  close = (next) => {
    this.closeCallback(next);
  };
}

export class Constants {}

export class BlocEvent {
  static ADDED = 'INSURANCE_ADDED';
}
