import React from 'react';
import { Insurance } from '@decodedhealth/react-component-lib';
import { getThemeBasedOnTenant } from '../../App/themes';
import DecodedComponent from '../../shared/DecodedComponent';
import { InsuranceBloc } from './bloc';
import { getLogoBasedOnTenant, getMobileLogoBasedOnTenant } from '../../../utils/logo';
import { registrationApi } from '../../../utils/services/register.api';
import { insurancesApi as insuranceApi } from '../../../utils/services/insurances.api';
import { paymentApi } from '../../../utils/services/payment.api';
import Quinn from '../../../assets/bebo.svg';
import { IntlProvider } from 'react-intl';
import { messages } from '../../../utils/intl/messages';

class InsuranceComponent extends DecodedComponent {
  parentBloc = {};
  constructor(props) {
    super(props);

    const isPrimaryCare = sessionStorage.getItem('registrationType') === 'PC';

    this.bloc = new InsuranceBloc(
      {
        codesetApi: registrationApi,
        appointmentApi: {},
        extensionApi: {},
        insuranceApi: insuranceApi,
        paymentApi: paymentApi,
        isKiosk: false,
        logoObject: {
          mobile: getMobileLogoBasedOnTenant(),
          desktop: getLogoBasedOnTenant(),
        },
        avatar: Quinn,
        avatarName: 'Quinn',
        isPrimaryCare: isPrimaryCare,
      },
      props.callback,
      props.switchToSelfPay,
      props.onBack,
      props.handleSkipInsurance,
    );
    props.bloc.insurance = this.bloc;
    this.parentBloc = this.props.bloc;
    this.bloc.__updateSubject({ useExternalInsurance: true });
  }

  componentDidMount() {
    super.componentDidMount();
    const { action, registrationType, user } = this.parentBloc.subject.value;
    if (action === 'registration.only' && registrationType === 'PC') {
      user.externalId.length > 0
        ? this.bloc.initialise(false, user.insurances)
        : this.bloc.initialise(false, []);
    } else {
      this.bloc.initialise(true);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <IntlProvider locale="en" messages={messages}>
        <Insurance theme={getThemeBasedOnTenant()} bloc={this.bloc} />
      </IntlProvider>
    );
  }
}

export default InsuranceComponent;
