import React from 'react';
import { Box, CircularProgress, styled, css, useTheme } from '@mui/material';
import { Bullseye } from './layouts/Bullseye';

const FullScreenBox = styled(Box)({
  display: 'flex',
  position: 'fixed',
  right: '0',
  bottom: '0',
  minWidth: '100%',
  minHeight: '100%',
  backgroundColor: '#ffffff',
  margin: '0 auto',
  alignItems: 'center',
  justifyContent: 'center',
});

const Loading = ({ isFullscreen, message }) => {
  const theme = useTheme();
  const Wrapper = isFullscreen ? FullScreenBox : Bullseye;
  return (
    <Wrapper>
      <CircularProgress
        sx={{
          '& .MuiCircularProgress-circle': {
            color: theme.palette.accent.main,
          },
        }}
      />
      {message ? (
        <p
          style={{
            paddingLeft: '24px',
            color: '#ccc',
          }}
        >
          {message}
        </p>
      ) : null}
    </Wrapper>
  );
};

export default Loading;
