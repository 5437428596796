import { logger } from './logging';

class UriStorage {
  setUpStorage() {
    if (!this.getCurrentPath()) {
      sessionStorage.setItem('currentPath', '');
    }
  }

  getCurrentPath() {
    return sessionStorage.getItem('currentPath');
  }

  setCurrentPath(path) {
    sessionStorage.setItem('currentPath', path);
  }

  isOnRightPath(path) {
    return path === this.getCurrentPath();
  }

  clearPath(onlyPath) {
    if (onlyPath) {
      sessionStorage.removeItem('currentPath');
    } else {
      sessionStorage.clear();
    }
  }
}
export const CheckSession = (key, value) => {
  const item = sessionStorage.getItem(key);
  if (item === value) {
    return true;
  } else {
    return false;
  }
};

export const SetSession = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const uriStorage = new UriStorage();
