import * as React from 'react';
import GoogleMapReact from 'google-map-react';
import { Box, Button, Grid, useTheme } from '@mui/material';
import { useBookingWizardContext } from '../BookingWizard/booking-wizard-context';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import OrganisationCard from '../../shared/OrganisationCard';
import { TextMediumStandard, TextStandard } from '../../shared/Typography';
import AltOrgCard from '../../shared/OrganisationCard/AltOrgCard';
import { globalBloc } from '../../global.bloc';
import { logger } from '../../../utils/logging';
import { ca } from 'date-fns/locale';
import DecodedButton from '../../common/DecodedButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { AssistantBlocEvent } from '../../Secure/Assistant/assistant.bloc';
import { LOGIN_ROUTE } from '../../../utils/route.name';

const AltOrgSelector = ({ organisations, bloc }) => {
  const theme = useTheme();

  const [selected, setSelected] = React.useState(null);
  const [pointTo, setPointTo] = React.useState(null);

  const history = useHistory();

  React.useEffect(() => {
    logger.debug(selected)
    if (selected) {
      globalBloc.updateBooking(selected);
      globalBloc.updateGlobalBloc({ orgSelected: true });
    }
  }, [selected]);

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
            version: 'weekly',
            libraries: ['places'],
          }}
          defaultCenter={{
            lat: 30.45537972887279,
            lng: -91.0783767180397,
          }}
          center={
            pointTo
              ? {
                  lat: pointTo?.contactInformation?.address?.geoLocation?.latitude,
                  lng: pointTo?.contactInformation?.address?.geoLocation?.longitude,
                }
              : {
                  lat: 30.45537972887279,
                  lng: -91.0783767180397,
                }
          }
          defaultZoom={16}
          options={{
            zoomControl: false,
            fullscreenControl: false,
          }}
        >
          {pointTo && (
            <>
              <LocationOnIcon
                sx={{
                  fontSize: '36px',
                  color: theme.palette.accent.main,
                }}
                fontSize={'large'}
                color={'primary'}
                lat={pointTo?.contactInformation?.address?.geoLocation?.latitude}
                lng={pointTo?.contactInformation?.address?.geoLocation?.longitude}
              />
            </>
          )}
          {organisations?.map((_org) => (
            <LocationOnIcon
              sx={{
                fontSize: '36px',
                color: theme.palette.accent.main,
              }}
              fontSize={'large'}
              color={'primary'}
              lat={_org?.contactInformation?.address?.geoLocation?.latitude}
              lng={_org?.contactInformation?.address?.geoLocation?.longitude}
            />
          ))}
        </GoogleMapReact>
      </div>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        height="100%"
        sx={(theme) => ({
          paddingLeft: '5em',
          marginBottom: '12px',
          [theme.breakpoints.down('md')]: {
            paddingLeft: '20px',
          },
        })}
      >
        <Grid
          item
          container
          flexWrap="nowrap"
          width="100%"
          sx={{ overflowX: 'scroll', maxWidth: '88vw !important', paddingLeft: '10px' }} //==> maxWidth: '88vw !important' is a hack to center
        >
          {organisations
            ? organisations.map((org) => (
                <Box
                  onMouseEnter={() => setPointTo(org)}
                  sx={{
                    width: '280px',
                    minWidth: '280px',
                    maxWidth: '280px',
                    margin: '12px 24px 12px 0',
                    [theme.breakpoints.down('sm')]: {
                      width: '260px',
                      minWidth: '260px',
                      maxWidth: '260px',
                    },
                  }}
                >
                  <AltOrgCard
                    organisation={org}
                    key={org.id}
                    isSelected={pointTo?.id === org.id}
                    setSelected={setSelected}
                    bloc={bloc}
                    pointTo={pointTo}
                  />
                </Box>
              ))
            : null}
        </Grid>
        <Grid
          item
          width="100%"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
            marginTop: '12px',
          }}
          // sx={{ overflowX: 'scroll', maxWidth: '88vw !important', paddingLeft: '10px' }} //==> maxWidth: '88vw !important' is a hack to center
        >
          <Button
            sx={{
              [theme.breakpoints.down('sm')]: {
                width: '75%',
              },
            }}
            variant="contained"
            onClick={() => {
              sessionStorage.setItem('action', 'manage-appointments');
              history.push('/');
            }}
          >
            MANAGE RESERVATION
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AltOrgSelector;
