import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import AppointmentLink from './AppointmentLink';
import CheckinLink from './CheckinLink';
import Encounter from '../Secure/VirtualClinic/Encounter';
import {
  CHECKIN_LINK_ROUTE,
  LOGIN_ROUTE,
  TELEHEALTH_LINK_ENCOUNTER_ROUTE,
  TELEHEALTH_LINK_ROUTE,
  SCHEDULE_LINK_ROUTE,
  TELEHEALTH_LINK_VIRTUAL_CLINIC_WAITING_ROOM_ROUTE,
  REGISTRATION_ONLY_LINK_DEFAULT_ROUTE,
  REGISTRATION_ONLY_LINK_ROUTE,
  routeUtil,
} from '../../utils/route.name';
import Precall from '../Secure/VirtualClinic/WaitingRoom/Precall';
import ScheduleLink from './ScheduleLink';
import RegistrationOnlyLink from './RegistrationOnlyLink';

const styles = (theme) => ({
  root: {
    overflow: 'auto',
    overscrollBehavior: 'contain',
    backgroundColor: '#ffffff',
    position: 'absolute',
    minHeight: '100%',
    height: '100%',
    width: '100%',
    color: '#fff',
  },
});

class LinkHandler extends Component {
  render() {
    let { classes } = this.props;

    return (
      <div className={classes.root}>
        <Switch>
          <Route path={REGISTRATION_ONLY_LINK_ROUTE} component={RegistrationOnlyLink} />
          <Route path={TELEHEALTH_LINK_ROUTE} component={AppointmentLink} />
          <Route path={TELEHEALTH_LINK_VIRTUAL_CLINIC_WAITING_ROOM_ROUTE} component={Precall} />
          <Route path={SCHEDULE_LINK_ROUTE} component={ScheduleLink} />
          <Route path={TELEHEALTH_LINK_ENCOUNTER_ROUTE} component={Encounter} />
          <Route path={CHECKIN_LINK_ROUTE} component={CheckinLink} />
          <Redirect
            from={REGISTRATION_ONLY_LINK_DEFAULT_ROUTE}
            to={routeUtil.buildRegistrationOnlyLink('UC')}
          />
          <Redirect to={LOGIN_ROUTE} />
        </Switch>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(LinkHandler));
