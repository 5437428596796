import React from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../shared/DecodedComponent';
import { withRouter } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { Loading } from '../../../shared-library';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { H2TextTitleContent } from '../../shared/Typography';
import PageContainer from '../../common/PageContainer';

import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../common/ScrollableContainer';
import DecodedButton from '../../common/DecodedButton';
import { providerStorage } from '../../../utils/provider.qs';
import { authService } from '../../../utils/auth';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class Employer extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.bloc;
    this.state = {
      ...this.bloc.subject.value,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.bloc.initialise();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  __makeUrgentcare = () => {
    this.bloc.switchToUrgentcare();
  };

  render() {
    const { initialising, switching } = this.state;

    if (initialising) {
      return (
        <PageContainer loading={false} onBack={() => {}}>
          <ScrollableQuinnContainer
            messageId={`paymentmethod.employer.loading.bubble`}
            message="We recommend you visit our Occupational Healthcare Clinic."
          >
            <Grid container direction="column" justifyContent="space-between" flex="1">
              <Loading isFullscreen={false} />
            </Grid>
          </ScrollableQuinnContainer>
        </PageContainer>
      );
    }

    if (switching) {
      return (
        <PageContainer loading={false} onBack={() => {}}>
          <ScrollableQuinnContainer
            messageId={`paymentmethod.employer.switching.bubble`}
            message="Switching to an urgentcare visit."
          >
            <Grid container direction="column" justifyContent="space-between" flex="1">
              <Loading isFullscreen={false} />
            </Grid>
          </ScrollableQuinnContainer>
        </PageContainer>
      );
    }

    return (
      <PageContainer
        loading={false}
        onBack={() => {
          this.props.history.goBack();
        }}
      >
        <ScrollableQuinnContainer
          messageId={`paymentmethod.employer.notice.bubble`}
          message="We recommend you visit our Occupational Healthcare Clinic."
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex' }}>
                <H2TextTitleContent>
                  <FormattedMarkdown
                    id={`paymentmethod.employer.notice.text`}
                    defaultMessage={`
We suggest you visit our Occupational Healthcare Clinic located at \n
3121 Carlisle Blvd. NE, ABQ.\n
Hours Monday to Friday 7am-6:30pm.\n
You can Walk-In, Make an Appointment or call (505) 228-2108.\n
If this location and hours are not convenient, please visit one of our Urgent Care clinics.
`}
                  />
                </H2TextTitleContent>
              </Box>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          <Grid xs={12} item>
            <DecodedButton
              onClick={async () => {
                await authService.logout();
                providerStorage.clearProvider();
                window.location =
                  'https://www.google.com/maps/place/3121+Carlisle+Blvd+NE,+Albuquerque,+NM+87107,+USA';
              }}
            >
              Walk-in
            </DecodedButton>
          </Grid>
          <Grid xs={12} item>
            <DecodedButton
              onClick={async () => {
                await authService.logout();
                providerStorage.clearProvider();
                window.location = 'https://dukecityoccupationalhealthcare.com/webchat/';
              }}
            >
              Appointment
            </DecodedButton>
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(withRouter(Employer));
