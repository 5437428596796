import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { H1TextStepTitle, TextStepSecondary } from '../../../shared/Typography';
import DecodedTextField from '../../../../utils/DecodedTextFIeld';
import { isValidPhoneNumber } from '../validationUtils';
import InputMask from 'react-input-mask';
import { LoginDataContext } from '../LoginDataProvider';
import { useMediaQueries } from '../../../../utils/hooks/useMediaQueries';
import { NextButton } from './NextButton';
import ScrollableContainer, {
  LoginDisconnectedPageFooter,
} from '../../../common/ScrollableContainer';
import PageContainer from '../../../common/PageContainer';
import { StyledGrid } from './StyledGrid'

const Phone = (props) => {
  const { formatMessage } = useIntl();
  const [error, setError] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const { loginData, setLoginData } = useContext(LoginDataContext);

  const mediaQuery = useMediaQueries();

  useEffect(() => {
    if (loginData.number) {
      setError(!isValidPhoneNumber(loginData.number));
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginData((prevState) => ({ ...prevState, [name]: value }));

    if (name === 'number') {
      setError(!isValidPhoneNumber(value));
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsFormDisabled(true);

    setTimeout(() => {
      if (props.index !== 6) {
        setIsFormDisabled(false);

        props.setIndex((prevIndex) => (prevIndex + 1) % 7);
      }
    }, 250);
  };

  return (
    <PageContainer>
      <form onSubmit={handleClick} style={{ display: 'contents' }}>
        <ScrollableContainer>
          <StyledGrid md={12} xl={8} container sx={{ padding: mediaQuery.isSm ? '20px' : '80px' }}>
            <Grid item>
              <FormattedMessage id="login.patient.phone" defaultMessage="Your mobile phone number">
                {(txt) => (
                  <H1TextStepTitle isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}>
                    {txt}
                  </H1TextStepTitle>
                )}
              </FormattedMessage>
            </Grid>
            <Grid item sx={{ marginY: '30px' }}>
              <FormattedMessage
                id="login.patient.phone.disclaimer"
                defaultMessage="THIS MOBILE NUMBER WILL RECEIVE A PIN CODE TO VERIFY YOUR REGISTRATION."
              >
                {(txt) => (
                  <TextStepSecondary isMobile={mediaQuery.isMobileLandscape}>
                    {txt}
                  </TextStepSecondary>
                )}
              </FormattedMessage>
            </Grid>
            <Grid
              container
              gap={2}
              sx={{
                width: '75%',
                '@media (max-width:600px)': {
                  width: '100%',
                },
                display: mediaQuery.isMobileLandscape && 'flex',
                flexDirection: mediaQuery.isMobileLandscape && 'row',
                justifyContent: mediaQuery.isMobileLandscape && 'flex-start',
                alignItems: mediaQuery.isMobileLandscape && 'flex-end',
                flexWrap: mediaQuery.isMobileLandscape && 'nowrap',
              }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <InputMask
                  mask="(999) 999-9999"
                  value={loginData.number}
                  onChange={handleInputChange}
                >
                  {(props) => (
                    <DecodedTextField
                      type="tel"
                      name="number"
                      label="Mobile Number"
                      variant="outlined"
                      error={error}
                      helperText={
                        error &&
                        formatMessage({
                          id: 'registration.field.error.patient.name.family',
                          defaultMessage: 'Invalid phone number',
                        })
                      }
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
          </StyledGrid>
        </ScrollableContainer>
        <LoginDisconnectedPageFooter>
          <NextButton label="NEXT" disabled={loginData.number === '' || error || isFormDisabled} />
        </LoginDisconnectedPageFooter>
      </form>
    </PageContainer>
  );
};

export default Phone;
