import React from 'react';
import { Button } from '@mui/material';

export const NextButton = (props) => {
  return (
      <Button
      {...props}
      fullWidth
        type="submit"
        sx={{
          borderRadius: '15px',
          background: 'linear-gradient(45deg, #2D8088, #359ea8)',
          color: 'white',
          '&': {
            color: 'white',
          },
          '&:hover': {
            color: 'white',
          },
          '&.Mui-disabled': {
            background: 'primary',
            color: 'rgba(255, 255, 255, 0.5)',
          },
        }}
      >
        {props.label}
      </Button>
  );
}