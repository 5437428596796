import React from 'react';
import { DecodedBloc } from '../../shared/DecodedComponent/bloc';
import { appointmentApi } from '../../../utils/services/appointments.api';
import { compareAsc, format, parse } from 'date-fns';
import { providerStorage } from '../../../utils/provider.qs';
import { appointmentIntervalUtil } from '../../../utils/appointment';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { dateUtil } from '../../../utils/date';

const DATE_FORMAT = 'yyyy-MM-dd';

export class Bloc extends DecodedBloc {
  constructor(options) {
    super(options);
    this.onNext = options.onNext;

    sessionStorage.removeItem('checkin');
  }

  //! __all this does is get organisations__
  initialise = async () => {
    const { isWalkin } = this.subject.value;

    appointmentApi
      .getAvailableOrganisationAnonymous(this.service())
      .then(
        (value) => {
          const organisations = value.data.items;
          this.__updateSubject({ organisations: organisations });
          if (isWalkin) {
            const walkinLocationId = this.walkinLocation();
            const organisation = organisations.find((o) => o.id === walkinLocationId);
            if (organisation?.id) {
              this.__loadWalkinAvailability(organisation).finally(() => this.__makeInitialised());
            } else {
              this.hideAvailability();
              this.__makeInitialised();
            }
          } else {
            this.__makeInitialised();
          }
        },
        (reason) => {
          this.hideAvailability();
          this.__makeInitialised();
        },
      )
      .catch((reason) => {
        this.hideAvailability();
        this.__makeInitialised();
      });
  };

  __loadWalkinAvailability = (organisation) => {
    const now = new Date();
    let tomorrow = dateUtil.nextStartOfDay(now);

    return this.loadAvailability(
      now,
      tomorrow,
      organisation.id,
      this.service(),
      undefined,
      true,
    ).then(
      (intervals) => {
        let firstWalkinSlot = appointmentIntervalUtil.findFirstAvailableSlotForDay(intervals, now);

        if (firstWalkinSlot) {
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS, {
            schedule: 'walkincheck',
            slot: firstWalkinSlot.slots[0].display,
          });

          this.__updateSubject({ firstWalkinSlot: firstWalkinSlot });
        } else {
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS, {
            schedule: 'full',
          });

          this.showWalkinUnavailable();
        }
      },
      (reason) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_ERROR, {
          reason: reason,
        });
        this.hideAvailability();
      },
    );
  };

  service = () => 'ANY-UC';

  setCheckin = () => {
    sessionStorage.setItem('checkin', 'true');
  };

  unsetCheckin = () => {
    sessionStorage.removeItem('checkin');
  };

  isWalkin = () => this.subject.value.isWalkin;

  walkinLocation = () => providerStorage.getCurrentProvider() || '';

  showWalkin = () => {
    this.__updateSubject({ showWalkinTime: false, showWalkin: true });
  };

  showWalkinTime = () => {
    this.__updateSubject({ showWalkinTime: true, showWalkin: false });
  };

  hideWalkin = () => {
    this.__updateSubject({ showWalkin: false });
  };

  showWalkinUnavailable = () => {
    this.__updateSubject({ walkinFull: true });
  };

  loadAvailability = (start, end, organisationId, service, doctor, isWalkin) => {
    return appointmentIntervalUtil.loadAvailability(
      appointmentApi,
      start,
      end,
      organisationId,
      service,
      doctor,
      isWalkin,
    );
  };

  makeReservationAvailable = () => {
    this.__updateSubject({ reservationAvailable: true });
  };
  makeSwitchAvailable = () => {
    this.__updateSubject({ switchAvailable: true });
  };

  clearProviderAndHideAvailability = () => {
    const { props } = this.subject.value;
    providerStorage.clearProviderOnly();
    props.history.replace('/login');
    this.__updateSubject({ showAvailability: false });
  };
  hideAvailability = () => {
    this.__updateSubject({ showAvailability: false });
  };

  setSelectedOrg = (org, capacity, date) => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.selectedOrg = org;
    newBooking.selectedOrgCapacity = capacity;
    newBooking.selectedDate = date || new Date();
    newBooking.availability = undefined;
    newBooking.reminderTime = undefined;

    analyticsEventLogger.log(
      AnalyticsEvent.BOOKING_APPOINTMENT_BOOKING_APPOINTMENT_ORGANISATION_LOADED_SELECT,
      { organisation: org, capacity: `${capacity}` },
    );

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });
  };

  clearSelectedOrg = () => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.selectedOrg = undefined;
    newBooking.selectedOrgCapacity = undefined;
    newBooking.selectedDate = undefined;
    newBooking.availability = undefined;
    newBooking.reminderTime = undefined;

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });
  };

  updateSelectedOrg(selectedOrg) {
    this.subject.next({ ...this.subject.value, selectedOrg });
  }
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
